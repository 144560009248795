import React from "react";
import GameBox from "../GameBox";
import ModuleIcon from "../../../assets/icons_modules/dezvoltareaGandirii.svg";

export function DezvoltareTemplate(props) {
	return (
		<GameBox
			gameBoxTopRowBkg="#06bfbf"
			gameBoxDivider="#143373"
			moduleName="Dezvoltarea gandirii"
			moduleIcon={ModuleIcon}
			gameNumber={props.gameNumber}
			gameId={props.children.props.gameId}
			color="white"
		>
			{props.children}
		</GameBox>
	);
}
