import React from "react";
import MatematicaTemplate__Mobile from "../../../components/modules/matematica/MatematicaTemplate__Mobile";
import "../../../styles/check-button__mobile.css";
import TutorialComponent from "../../../components/modules/tutorial/TutorialComponent";

export default function Tutorial__Mobile() {
	const maxNrOfScenes = 3;
	//!! De luat din dbtest
	const gameId = "61e9c166bcafd0085f1c2165";
	//! TO DO: REQUEST CATRE BACKEND CARE SA INTOARCA LEVEL PENTRU JOCUL CURENT

	return (
		<MatematicaTemplate__Mobile gameNumber={1}>
			<TutorialComponent gameId={gameId} maxNrOfScenes={maxNrOfScenes} />
		</MatematicaTemplate__Mobile>
	);
}
