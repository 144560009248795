import React from "react";
import Legal from "./Legal";

export default function TermeniCopii() {
	return (
		<Legal
			title="TERMENI ȘI CONDIȚII DE UTILIZARE (PENTRU COPII) WIKISMARTS"
			className="termeni-si-conditii"
		>
			<p>
				Bun venit la WikiSmarts! Noi suntem o companie din România care se ocupă
				de operarea acestui site și aplicațiilor mobile aferente. Am creat
				câteva reguli (aceste „Reguli”) pentru toți copiii sub 13 ani care
				utilizează WikiSmarts. Dacă ai sub 13 ani și utilizezi WikiSmarts,
				aceste Reguli ți se aplică. Dacă nu ești de acord cu Regulile, nu
				utiliza site-ul nostru și aplicațiile mobile WikiSmarts. Dacă dorești
				mai multe informații despre Regulile noastre, contactează-ne la{" "}
				<a>gdpr@ithit.ro</a> . Suntem bucuroși să îți explicăm totul. Ne dorim
				ca site-ul și aplicațiile noastre să fie un loc distractiv pentru toată
				lumea și dacă respecți aceste Reguli, ne vei ajuta cu aceasta!
			</p>
			<h2>REGULI:</h2>
			<p>
				Nu utiliza site-ul și aplicațiile WikiSmarts pentru nimic ilegal sau
				dăunător altor utilizatori. Ce este ilegal sau dăunător? Ei bine, ceva
				care este împotriva legii în orice țară sau împotriva decenței comune.
				De exemplu:
			</p>
			<ul>
				<li>
					Încărcarea de materiale sau comentarii care sunt defăimătoare,
					indecente, obscene, hărțuitoare sau violente.
				</li>
				<li>
					Împărtășirea informațiilor cuiva fără consimțământul acestuia sau alt
					motiv adecvat.
				</li>
				<li>Prefăcându-te că ești altcineva.</li>
				<li>
					Utilizarea site-ului și aplicațiilor noastre pentru a încălca
					drepturile legale ale altor persoane sau companii, cum ar fi
					încălcarea drepturilor de autor ale altora.
				</li>
				<li>
					Utilizarea site-ului nostru web pentru hacking, phishing, spam,
					răspândire viruși sau alte atacuri cibernetice sau pentru a colecta
					informații despre alți utilizatori ai site-ului și aplicațiilor
					noastre.
				</li>
				<li>
					Hărțuirea, urmărirea sau comportamentul rău în alt mod față de alți
					utilizatori.
				</li>
			</ul>
			<p>
				Lucrurile pe care le punem pe WikiSmarts sunt practic „lucrurile
				noastre”. Nu ai voie să copiezi, reutilizezi sau redistribui conținutul
				WikiSmarts fără acordul nostru. Desigur, în cazul în care conținutul
				este copiat automat de dispozitivul tău din motive tehnice, astfel încât
				să poți utiliza site-ul nostru sau aplicațiile mobile în mod normal,
				aceasta nu este o încălcare a Regulilor noastre.
			</p>
			<p>
				Utilizarea site-ului nostru și a aplicațiilor mobile este gratuită
				pentru primul joc sau nivel din fiecare modul al WikiSmarts, până când
				vrei tu. Pentru avansarea la următoarele niveluri este necesară
				achiziționarea unui abonament. Te rugăm să citești{" "}
				<a href="/prelucrarea_platilor">
					Politica privind prelucrarea plăților
				</a>{" "}
				înainte de a face orice plată pe site-ul nostru și să obții acordul
				părților tăi (sau alor îngrijitori). De asemenea, tu (sau părinții tăi)
				poți fi taxat de un furnizor de internet pentru timpul petrecut pe
				site-ul sau aplicațiile nostre. Asigură-te că verifici dacă părinții tăi
				(sau alți îngrijitori) sunt conștienți de acest lucru și sunt de acord.
				Nu suntem responsabili pentru plata acelei facturi.
			</p>
			<p>
				Nu suntem răspunzători față de tine pentru nimic din ceea ce se află pe
				conținutul WikiSmarts, cu excepția cazului în care am pus în mod activ
				pe el ceva care este ilegal și ți-a cauzat daune. A nu fi răspunzător
				înseamnă că, dacă suferi daune ca urmare a conținutului WikiSmarts, nu
				vom plăti acele daune. Deci: folosirea WikiSmarts sau a oricărui
				conținut al acestuia este decizia ta.
			</p>
			<p>
				Dacă faci ceva pe sau cu site-ul sau aplicațiile noastre care este
				împotriva Regulilor noastre, tu (sau părinții tăi sau alți îngrijitori)
				puteți fi răspunzători față de noi sau alte persoane sau părți
				implicate. Încălcarea regulilor poate însemna că trebuie să oferiți o
				sumă de bani.
			</p>
			<p>
				Dacă alte persoane sau părți depun o reclamație împotriva noastră pentru
				că ai încălcat aceste Reguli, tu (sau părinții tăi sau alți îngrijitori)
				nu ne vei ține responsabili. Aceasta înseamnă că trebuie să ne plătești
				daunele și în acest caz. Probabil cel mai bine ar fi să nu încalci
				aceste Reguli.
			</p>
			<p>
				Legile române se aplică acestor Reguli. Deci: dacă încalci aceste
				Reguli, vom folosi legea română pentru a determina care sunt
				consecințele exacte ale acțiunilor tale.
			</p>
		</Legal>
	);
}
