import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { isMobileDevice } from "../../scripts/helpers";
import "../../styles/legal.css";
import "../../styles/login-page__mobile.css";
import { TEXT, lan } from "../../scripts/text";

export default function Legal(props) {
	const backToTop = useRef(null);
	const scrollToTop = () => backToTop.current.scrollIntoView();
	const scroll = () => {
		if (window.scrollY != 0) {
			document.querySelector(".back-to-top").style.opacity = 1;
			document.querySelector(".back-to-top").style.transform = "scale(1)";
		} else {
			document.querySelector(".back-to-top").style.opacity = 0;
			document.querySelector(".back-to-top").style.transform = "scale(0)";
		}
	};
	useEffect(() => {
		document.querySelector("body").style.padding = 0;
		document.querySelector("body").style.overflowY = "visible";
		document.querySelector("body").style.overflowX = "hidden";
		document.querySelector("body").classList.add("fixed-background");
		window.addEventListener("scroll", scroll);
		return () => {
			document.querySelector("body").style.overflow = "hidden";
			document.querySelector("body").style.padding = "2% 5%";
			document.querySelector("body").classList.remove("fixed-background");
			window.removeEventListener("scroll", scroll);
		};
	}, []);
	return (
		<div
			className={`${props.className} ${isMobileDevice() ? "mobile-legal" : ""}`}
		>
			<div className="legal-title" ref={backToTop}>
				<div className="back-to-top" onClick={scrollToTop}>
					<img src={require("../../assets/login_page/bulina.svg").default} />{" "}
				</div>
				<div className="legal-navbar">
					<Link to="/">
						<img
							src={
								require("../../assets/logo_WS/logo_wiki_side_white.svg").default
							}
							style={{
								width: "20vw",
							}}
						/>
					</Link>
					<div style={{ display: "flex" }}>
						<Link to="/termeni_si_conditii" replace>
							<h4 style={{ margin: "auto 1rem", letterSpacing: "normal" }}>
								{TEXT._TERMS_AND_CONDITIONS[lan]}
							</h4>
						</Link>
						<Link to="/prelucrarea_datelor" replace>
							<h4 style={{ margin: "auto 1rem", letterSpacing: "normal" }}>
								{TEXT._DATA_PROCESSING_NAV[lan]}
							</h4>
						</Link>
						<Link to="/cookies" replace>
							<h4 style={{ margin: "auto 1rem", letterSpacing: "normal" }}>
								{TEXT._COOKIES[lan]}
							</h4>
						</Link>
						<Link to="/prelucrarea_platilor" replace>
							<h4 style={{ margin: "auto 1rem", letterSpacing: "normal" }}>
								{TEXT._PAYMENT_PROCESSING[lan]}
							</h4>
						</Link>
					</div>
				</div>

				<h1>{props.title}</h1>
			</div>
			<div className="legal-container">{props.children}</div>
			<div style={{ height: "5rem" }}></div>
		</div>
	);
}
