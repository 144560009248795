import React, { useRef } from "react";
import Legal from "./Legal";

export default function CookiesEng() {
	const section1 = useRef();
	const section2 = useRef();
	const section3 = useRef();
	const section4 = useRef();
	return (
		<Legal
			title="COOKIE PROCESSING POLICY WITHIN THE WIKISMARTS APPLICATION"
			className="cookies"
		>
			<p>
				<em>
					This cookie processing policy is effective as of 28.09.2022 and will
					be regularly updated to reflect any changes in the way we handle
					cookies or any changes in applicable laws.
				</em>
			</p>
			<h5
				onClick={() => {
					section1.current.scrollIntoView();
				}}
			>
				What are cookies?
			</h5>

			<h5
				onClick={() => {
					section2.current.scrollIntoView();
				}}
			>
				How do we use cookies?
			</h5>

			<h5
				onClick={() => {
					section3.current.scrollIntoView();
				}}
			>
				Third-party cookies.
			</h5>

			<h5
				onClick={() => {
					section4.current.scrollIntoView();
				}}
			>
				How can you avoid cookies?
			</h5>
			<h2 ref={section1}>Ce sunt cookie-urile?</h2>
			<p>
				Cookies are text files that contain small amounts of information that
				are downloaded to your computer or mobile device when you visit a
				website and allow a site to recognize your device. Cookies are stored on
				your device when you browse and use websites or other online services,
				for example, to facilitate certain functions, such as website
				navigation.
			</p>
			<p>
				The cookies managed by us are called 'first-party cookies' only, while
				third-party cookies are called 'third-party cookies,' as explained
				below.
			</p>
			<h2 ref={section2}>How do we use cookies?</h2>
			<p>
				Cookies are used to enhance your experience, aggregate statistics about
				the number of visitors to the site, and gather data on how the site is
				used. This data allows us to develop and optimize the website. Cookies
				are sometimes used to collect data considered to be personally
				identifiable information according to applicable laws. This may include
				IP addresses and IP-related data, but as a general rule, not personal
				data that can be directly attributed to you as an individual (meaning we
				will not be able to link your name/identity with your IP address).
				However, in certain cases, it may be possible for us to determine the
				identity of the individual to whom the IP address is assigned. The
				purpose is to create personalized and relevant content for you as a
				visitor.
			</p>
			<p>
				Deleting cookies can be done automatically when you close your web
				browser (session cookies). Additionally, cookies can be stored on the
				user's computer to facilitate future visits to the site (persistent
				cookies) - these will also be automatically deleted after a certain
				period of time.
			</p>
			<p>
				This table presents the types of cookies placed on the WikiSmarts
				website and details about them.
			</p>
			<table className="icons-grid legal-table">
				<tbody>
					<tr>
						<td>Name</td>
						<td>Cookie Type and Function </td>
						<td>Stored Data </td>
						<td>Storage Period</td>
					</tr>
					<tr>
						<td>wikismarts_session_id</td>
						<td>
							Cookie strict necesar pentru funcționarea website-ului;sesiune
						</td>
						<td>
							It does not contain personal data and is not used to collect
							information. It is used to maintain the session of the logged-in
							user
						</td>
						<td>Until the session is closed</td>
					</tr>
					<tr>
						<td>selectedLevel</td>
						<td>
							Cookie strict necesar pentru funcționarea website-ului;sesiune
						</td>
						<td>
							It does not contain personal data and is not used to collect
							information. It is used to store the level of a game and enable
							proper progression.
						</td>
						<td>Until the session is closed</td>
					</tr>
				</tbody>
			</table>
			<h2 ref={section3}>Third-party cookies</h2>
			<p>
				Certain third-party cookies may also be used on our website. These
				third-party cookies are primarily used to enhance the user experience,
				aggregate statistics about the number of website visitors, and gather
				data on how the website is used. However, in certain cases, they may be
				used by a third party for their own purposes.
			</p>
			<p>
				These companies use programming code to collect information about your
				interaction with our website, such as the pages you visit, the links you
				click on, and how long you are on our website. This code is active only
				when you are on our website.
			</p>
			<h2 ref={section4}>How can you avoid cookies?</h2>
			<p>
				You can adjust the settings of non-essential cookies through the cookie
				consent manager.
			</p>
			<p>
				If you do not accept the use of non-essential cookies, your browser can
				be configured to automatically reject the storage of cookies or notify
				you each time a site requests to store a cookie. Previously stored
				cookies can be deleted through your browser.
			</p>
			<p>
				Please note that deleting and blocking cookies may have an impact on
				your user experience.
			</p>
			<p>
				If you have any questions now or during your visit to our website,
				please send your inquiry to our address at 22 Tudor Vladimirescu
				Boulevard, Sector 5, Bucharest, by sending us a message at{" "}
				<a>gdpr@ithit.ro</a> or by calling (0371) 395 028.
			</p>
		</Legal>
	);
}
