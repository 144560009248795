import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	getApiConfig,
	isMobileDevice,
	logout,
	releaseDate,
} from "../scripts/helpers";
import Cookies from "js-cookie";
import config from "../config";
import jwt_decode from "jwt-decode";
import axios from "axios";
import BackButton from "./svg-uri/BackButton";
import MobileContainer from "./MobileContainer";
import DesktopContainer from "./DesktopContainer";
import { CLASSES, lan } from "../scripts/text.js";

export const Context = React.createContext();

export const languages = {
	eng: {
		key: "eng",
		text: "ENG",
		image: require("../assets/eng.svg").default,
	},
	ro: {
		key: "ro",
		text: "RO",
		image: require("../assets/ro.svg").default,
	},
};

export default function Container(props) {
	const [gamesSubInfo, setGamesSubInfo] = useState({});
	const location = useLocation();
	const navigate = useNavigate();
	function isReleased() {
		const time_future = releaseDate;
		const time_present = new Date();

		const difference_in_milliseconds =
			time_future.getTime() - time_present.getTime();

		if (Cookies.get("tester") == "true") {
			return;
		}
		if (
			difference_in_milliseconds > 0 &&
			!window.location.href.includes("countdown") &&
			!window.location.href.includes("d87b080c-c089-4a00-8bdd-33639235ebbf")
		) {
			window.open("/countdown", "_self");
			// console.log("redirect to countdown");
		}
	}

	async function handleGamesSubInfo() {
		axios
			.get(`${getApiConfig().baseUrl}/users/permissions`, {
				headers: getApiConfig().headers,
			})
			.then((response) => {
				setGamesSubInfo(response.data.permissions);
			})
			.catch((errResponse) => {
				// console.log(errResponse);
			});
	}

	const [role, setRole] = useState("player");

	function handleLanguage() {
		let localLan = localStorage.getItem("lan");
		if (!localStorage.getItem("lan") || !languages[localLan]) {
			localStorage.setItem("lan", "ro");
			// localLan = "ro";
		}
	}

	useEffect(() => {
		handleRole();
		handleGamesSubInfo();
		handleLanguage();
		// if(Cookies.get("firebase-login-loading")){
		// 	if(document.querySelector(".overlay"))
		// }
	}, []);

	function handleRole() {
		if (Cookies.get(config.cookie_token_key)) {
			try {
				let token = Cookies.get(config.cookie_token_key);
				let decoded = jwt_decode(token);
				if (decoded.data.role) setRole(decoded.data.role);
			} catch (e) {}
		}
	}

	useEffect(() => {
		isReleased();
	}, [location.pathname]);

	// useEffect(() => {
	// 	console.log('INTRA IN FIREBASE USEEFFECT');
	//
	// 	getRedirectResult(auth)
	// 		.then((result) => {
	// 			console.log('FIREBASE REDIRECT RESULT: ', result);
	// 		}).catch((error) => {
	// 		console.log('FIREBASE ERROR: ', error);
	// 	});
	//
	// }, []);
	return (
		<Context.Provider value={{ gamesSubInfo, role, setRole }}>
			{props.deviceContainer == false ? (
				<>
					{React.cloneElement(props.children, {
						...props.children.props,
					})}
				</>
			) : (
				<>
					{isMobileDevice() ? (
						<MobileContainer
							noNavbar={props.noNavbarMobile}
							correctOrientation={props.correctOrientation ?? "landscape"}
						>
							{React.cloneElement(props.children, {
								...props.children.props,
							})}
						</MobileContainer>
					) : (
						<DesktopContainer noNavbar={props.noNavbarDesktop}>
							{React.cloneElement(props.children, {
								...props.children.props,
							})}
						</DesktopContainer>
					)}
				</>
			)}
			{props.showBackButton == false ? (
				""
			) : (
				<div
					className={`back-button ${CLASSES._BACK_BUTTON[lan]}`}
					onClick={() => {
						navigate(-1);
					}}
				>
					<BackButton />
				</div>
			)}
		</Context.Provider>
	);
}
