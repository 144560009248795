import React from "react";
import Legal from "./Legal";

export default function Termeni() {
	return (
		<Legal
			title="TERMENI ȘI CONDIȚII DE UTILIZARE WIKISMARTS"
			className="termeni-si-conditii"
		>
			<p>
				Bun venit la WikiSmarts! Noi (IT HIT S.R.L., o companie stabilită în
				România – denumită în continuare , „noi”, „nostru” sau „noi”) operăm un
				site web, accesibil la{" "}
				<a href="https://www.wikismarts.com">www.wikismarts.com</a>. În acest
				document explicăm termenii de utilizare a site-ului nostru WikiSmarts.
				Site-ul web WikiSmarts poate include acces la medii virtuale, jocuri și
				alt conținut, precum și software sau aplicații descărcabile pentru
				utilizare pe computere personale, tablete, dispozitive mobile sau
				telefoane. Toți utilizatorii WikiSmarts („Vizitatorii site-ului”) sunt
				supuși următorilor termeni și condiții de utilizare (acești „Termeni de
				utilizare”).
			</p>
			<p>
				Vă rugăm să citiți cu atenție acești Termeni de utilizare înainte de a
				accesa sau utiliza orice parte a WikiSmarts. Prin accesarea sau
				utilizarea WikiSmarts, sunteți de acord că ați citit, înțeles și sunteți
				de acord să respectați acești Termeni de utilizare, astfel cum au fost
				modificați din când în când de către noi.
			</p>
			<p>
				Acești termeni s-au modificat ultima dată la 28.09.2022. Putem revizui
				și actualiza acești Termeni de utilizare oricând și, dacă facem acest
				lucru, vă vom anunța prin publicarea Termenilor de utilizare revizuiți.
				Utilizarea în continuare a WikiSmarts înseamnă că acceptați și sunteți
				de acord cu Termenii de utilizare revizuiți. Dacă nu sunteți de acord cu
				Termenii de utilizare sau sunteți nemulțumit de termenii și condițiile
				de utilizare ale WikiSmarts, singurul și exclusiv remediu este să
				întrerupeți utilizarea aplicației.
			</p>
			<h2>Vârstă și ghidul părinților</h2>
			<p>
				Dacă aveți mai puțin de 13 ani, accesați acești{" "}
				<a href="/termeni_si_conditii_copii">
					Termeni de utilizare pentru copii
				</a>
				.
			</p>
			<p>
				Suntem la fel de serioși în ceea ce privește siguranța copiilor online,
				precum și în ceea ce privește distracția și procesul lor de învățare –
				așa că am creat WikiSmarts doar pentru ei. Primim uneori întrebări
				despre serviciul nostru și le-am pus pe cele mai frecvente mai jos. Dacă
				aveți orice altă întrebare, vă rugăm să ne contactați la detaliile de
				contact de mai jos.
			</p>
			<ul>
				<li>
					Trebuie să plătesc pentru vreunul dintre jocuri? Primul joc/nivel din
					fiecare modul al WikiSmarts poate fi jucat de fiecare utilizator în
					mod gratuit, începând cu data activării contului, pe o perioadă
					nelimitată. Pentru avansarea la următoarele niveluri este necesară
					achiziționarea unui abonament. În cazul în care optați pentru
					achiziționarea unui dintre abonamentele noastre, vă rugăm să vă
					adresați{" "}
					<a href="/prelucrarea_platilor">
						Politicii privind prelucrarea plăților
					</a>
					.
				</li>
				<li>
					Poate copilul meu să converseze cu alți oameni? Nu deloc. WikiSmarts
					nu are un sistem de conectare între utilizatori sau de chat.
				</li>
				<li>
					Prelucrați datele personale? Cum păziți confidențialitatea copiilor
					online? Da, pentru activarea conturilor. Nu folosim însă cookie-uri de
					urmărire sau alte tipuri de cookie-uri, cu excepția celor necesare
					pentru a menține acest site să funcționeze corect și pentru a le
					permite jucătorilor să-și salveze progresul în joc și să continue să
					joace când revin. WikiSmarts respectă legile privind protecția datelor
					și confidențialitate. De asemenea, prelucrăm strict date personale
					necesare pentru furnizarea serviciilor și nu folosim măsuri intruzive
					de prelucrare (profilări, măsuri automate etc.). De asemenea, accesul
					la datele este strict limitat. Vă rugăm să consultați{" "}
					<a href="/prelucrarea_datelor">
						Politica privind prelucrarea datelor cu caracter personal
					</a>{" "}
					și <a href="/cookies">Politica privind prelucrarea cookie-urilor</a>{" "}
					pentru mai multe informații despre modul în care prelucrăm datele.
					Dacă sunteți părinte sau tutore legal și aveți întrebări despre
					confidențialitatea datelor copilului dvs. în cadrul WikiSmarts, ne
					puteți trimite un e-mail la <a>gdpr@ithit.ro</a>.
				</li>
			</ul>
			<h2>Datele de accesare a WikiSmarts și gestionarea contului</h2>
			<p>
				Vizitatorii site-ului care s-au înregistrat pe website sau prin una din
				aplicațiile noastre au obligația de a ține datele de înregistrare în
				siguranță. Sunteți direct răspunzător pentru confidențialitatea numelui
				de utilizator și a parolei de acces și de asemenea pentru toate
				acțiunile pe care le efectuați în contul dvs. Vă recomandăm să schimbați
				periodic parola, să nu folosiți aceeași parolă ca la email sau ca la
				alte servicii online. Nu putem verifica identitatea persoanelor care
				folosesc serviciile noastre și nu suntem răspunzători dacă numele dvs.
				de utilizator și parola sunt folosite de o altă persoană. Nu oferiți
				nimănui parola de acces și nu o introduceți pe alte site-uri sau
				aplicații în afară de WikiSmarts.
			</p>
			<p>
				Dacă ați luat la cunoștință faptul că o altă persoană folosește contul
				dvs., ar trebui sa ne anunțați de îndată prin modalitățile indicate pe
				wikismarts.com/contact sau la adresa de email <a>gdpr@ithit.ro</a>.
			</p>
			<p>
				Dacă doriți să închideți contul dvs. WikiSmarts puteți să îl ștergeți
				direct din aplicație, folosind opțiunea Dezactivare Cont.
			</p>
			<h2>Utilizarea site-ului și aplicațiilor WikiSmarts</h2>
			<p>
				Sunteți de acord să utilizați WikiSmarts numai în scopuri legale. În mod
				specific, sunteți de acord să nu faceți nici una dintre următoarele:
			</p>
			<p>
				(1) să încărcați sau să transmiteți pe WikiSmarts orice material
				defăimător, indecent, obscen, hărțuitor, violent sau în alt mod
				inacceptabil, sau orice material care este sau poate fi, protejat prin
				drepturi de autor, fără permisiunea proprietarului drepturilor de autor;
				(2) utilizați WikiSmarts pentru a încălca drepturile legale (inclusiv
				drepturile de publicitate și confidențialitate) ale altora sau pentru a
				încălca legile oricărei jurisdicții; (3) denaturați orice persoană sau
				organizație; (4) încărcați sau transmiteți prin intermediul WikiSmarts
				orice reclame sau solicitări de afaceri; (6) restricționați sau să
				inhibați utilizarea WikiSmarts de către alții; (5) încărcați sau
				transmiteți în alt mod fișiere care conțin un virus sau date corupte;
				(6) colectați informații despre alții (inclusiv adrese de e-mail) fără
				consimțământul acestora; (7) solicitați, furnizați sau schimbați orice
				informații personale, inclusiv, dar fără a se limita la nume de
				utilizator sau parole; (8) să vă angajați în orice alt comportament care
				restricționează sau inhibă utilizarea oricărei persoane a WikiSmarts sau
				care, după cum este stabilit de noi, poate dăuna WikiSmarts sau
				Vizitatorilor site-ului în orice fel. Fără a ne limita la niciuna dintre
				cele de mai sus, sunteți de asemenea de acord să respectați orice cod de
				conduită și politici aplicabile WikiSmarts sau oricărui serviciu
				disponibil în cadrul WikiSmarts.
			</p>
			<p>
				Nu puteți reproduce, sublicenția, distribui, vinde sau exploata în
				niciun scop comercial (i) orice parte a WikiSmarts sau conținutul
				acestuia, (ii) accesul la WikiSmarts sau (iii) utilizarea WikiSmarts sau
				a oricăror servicii sau materiale disponibile prin intermediul
				WikiSmarts.
			</p>
			<p>
				Ne rezervăm dreptul discreționar, dar nu ne asumăm nicio
				responsabilitate, de a (1) elimina orice material postat pe WikiSmarts
				pe care îl considerăm incompatibil cu angajamentele de mai sus; (2)
				rezilia accesul oricărui utilizator la tot sau la o parte a serviciilor
				noastre. Cu toate acestea, nu ne asumăm nicio responsabilitate pentru
				nicio acțiune sau inacțiune privind transmisiile, comunicările sau
				conținutul furnizat de terți. Ne rezervăm dreptul de a întreprinde orice
				acțiune pe care o considerăm necesară pentru a proteja siguranța
				Vizitatorilor site-ului și a publicului; cu toate acestea, nu avem nicio
				răspundere sau responsabilitate față de nicio persoană pentru efectuarea
				sau neefectuarea activităților descrise în acest paragraf.
			</p>
			<p>
				O parte din serviciile oferite vă permit să vă personalizați numele de
				utilizator și fotografia de la avatar. Postând orice conținut prin
				intermediul serviciilor noastre sau trimițând orice material către noi,
				vă asumați responsabilitatea de a avea dreptul și orice aprobări
				necesare pentru a utiliza materialul respectiv și ne cedați dreptul de a
				folosi materialul fără niciun fel de pretenție materială, plată sau
				obligație către dvs. sau oricine altcineva. De asemenea, vă obligați să
				răspundeți legal în cazul în care folosirea materialului respectiv
				încalcă drepturile unei terțe părți sau legea. De asemenea, vă obligați
				să ne compensați material pentru orice pierdere survenită ca rezultat
				direct al încălcării termenilor din acest paragraf.
			</p>
			<p>
				Nerespectarea prevederilor de mai sus poate duce la întreruperea
				accesului dvs. la serviciile WikiSmarts și vă poate expune la răspundere
				civilă și/sau penală.
			</p>
			<h2>Furnizarea serviciilor</h2>
			<p>
				Nu putem garanta ca serviciile oferite de noi vor fi întotdeauna
				disponibile, că nu vor fi întrerupte, că nu vor apărea probleme, erori
				sau omisiuni. De exemplu, pot exista perioade când serviciile nu vor fi
				disponibile din cauza unor probleme sau a unor lucrări de mentenanță
				menite să asigure funcționarea sau îmbunătățirea serviciilor. De
				asemenea putem schimba, suspenda sau întrerupe anumite servicii, fără o
				notificare prealabilă. Nu putem fi considerați răspunzători pentru nicio
				pierdere pe care o puteți suferi ca și consecință a întreruperii
				temporare sau permanente a unor servicii oferite de noi.
			</p>
			<p>
				De asemenea, nu putem fi considerați răspunzători pentru următoarele
				pierderi sau probleme care pot surveni ca urmare a folosirii serviciilor
				noastre: defectarea calculatorului sau a dispozitivului folosit la
				accesarea serviciilor noastre; pierderea de date; pierderea pe care o
				puteți suferi ca și consecință a acțiunilor unui alt utilizator al
				serviciilor noastre; pierderea care poate fi cauzată de noi ca urmare a
				nerespectării Termenilor de utilizare; orice pierdere directă sau
				indirectă pe care noi sau dvs. nu o putem anticipa atunci când începeți
				să folosiți serviciile noastre; orice pierdere survenită odată cu
				întreruperea sau suspendarea serviciilor noastre sau dacă noi nu putem
				respecta termenii noștri din cauza unor cazuri fortuite sau evenimente
				de forță majoră.
			</p>
			<h2>Încărcări pe dispozitivele dvs.</h2>
			<p>
				Vă rugăm să rețineți că, dacă utilizați aplicații descărcabile de la
				WikiSmarts, actualizările sistemelor sau firmware-ului dispozitivului
				dvs. pot face ca utilizarea aplicațiilor să fie incompatibilă. Nu
				garantăm că site-ul WikiSmarts sau orice aplicație WikiSmarts vor fi
				compatibile cu actualizările sau versiunile anterioare ale
				dispozitivelor dumneavoastră.
			</p>
			<p>
				În cazul în care descărcați WikiSmarts prin App Store sau Google Play vi
				se va cere să acceptați termenii respectivului magazin. Vă recomandăm să
				citiți termenii respectivi cu atenție.
			</p>
			<p>
				În cazul aplicațiilor descărcabile WikiSmarts, este posibil să vi se
				ofere posibilitatea de a primi notificări de tipul 'push'. Puteți să
				accepți sau să refuzați aceste notificări, precum puteți ulterior să
				întrerupeți aceste notificări accesând meniul Setări de pe dispozitivul
				dvs.
			</p>
			<h2>Taxarea de date</h2>
			<p>
				În măsura în care utilizarea de către dvs. a site-ului WikiSmarts sau a
				oricărei aplicații WikiSmarts necesită sau permite utilizarea datelor
				wireless, celulare sau acces la internet, sunteți responsabil în mod
				independent pentru securizarea serviciului de acces la date necesar. De
				exemplu, în ceea ce privește dispozitivele dvs. mobile, furnizorul
				planurilor dvs. de date vă poate percepe taxe de acces la date în
				legătură cu utilizarea de către dvs. a serviciilor WikiSmarts. Sunteți
				singurul responsabil pentru toate aceste taxe plătibile terților.
			</p>
			<h2>Drepturi de autor</h2>
			<p>
				Întregul conținut al acestui website și aplicațiilor WikiSmarts
				(inclusiv toate informațiile, textul, afișajele, imaginile și sunetul și
				orice software pus la dispoziție prin sau în legătură cu acestea și
				designul, selecția și aranjarea acestuia sunt proprietatea noastră și
				sunt protejate de legile române și internaționale privind drepturile de
				autor, mărcile comerciale, secretele comerciale și alte drepturi de
				proprietate. Sunteți autorizat să utilizați conținutul serviciilor
				noastre numai pentru uz personal legat de rolul dvs. de utilizator
				actual sau potențial al WikiSmarts. Nu puteți copia, modifica, crea
				lucrări derivate, afișa public sau executa, republica, stoca, transmite
				sau distribui orice material de pe site-ul web și aplicațiile WikiSmarts
				fără acordul nostru prealabil scris, cu excepția următoarelor situații:
				(a) pentru a stoca copii temporar în RAM, (b) pentru a stoca fișiere
				care sunt memorate automat în cache de browserul dvs. web în scopul
				îmbunătățirii afișajului și (c) pentru a tipări un număr rezonabil de
				pagini ale site-ului web sau aplicațiilor WikiSmarts; cu condiția, în
				fiecare caz, să nu modificați sau să eliminați niciun fel de notificări
				privind drepturile de autor sau alte informații de proprietate incluse
				în astfel de materiale. Nici titlul, nici drepturile de proprietate
				intelectuală asupra oricărei informații sau materiale nu vă sunt
				transferate, dar rămân în proprietatea noastră.
			</p>
			<p>
				Putem, în circumstanțe adecvate și fără nicio notificare prealabilă, să
				vă reziliem accesul la serviciile WikiSmarts dacă considerăm că sunteți
				o încălcare repetată a drepturilor de proprietate intelectuală descrise
				mai sus.
			</p>
			<h2>Despăgubirea</h2>
			<p>
				Sunteți de acord să ne despăgubiți, ca urmare a și împotriva oricăror și
				tuturor responsabilităților, cheltuielilor, daunelor și costurilor,
				inclusiv onorariile rezonabile ale avocaților, care decurg din orice
				încălcare de către dvs. a acestor Termeni de utilizare sau utilizarea de
				către dvs. a website-ului sau aplicațiilor WikiSmarts sau a oricărui
				produse, servicii sau informații obținute de pe acestea.
			</p>
			<h2>Legea aplicabilă</h2>
			<p>
				Acești Termeni de utilizare vor fi guvernați în toate privințele și
				interpretați în conformitate cu legile României. Dacă respectarea sau
				drepturile noastre conform acestor Termeni de utilizare este/sunt în
				vreun fel afectate de legile privind protecția consumatorilor din țara
				de reședință, nu ar trebui să utilizați website-ul sau aplicațiile
				WikiSmarts.
			</p>
			<p>
				Orice dispută care decurge din sau în legătură cu acești Termeni de
				utilizare sau cu utilizarea de către dvs. a website-ului sau
				aplicațiilor WikiSmarts, va fi înaintată instanței competente din
				București, România. Dacă sunteți rezident într-un stat membru al UE sau
				într-o țară în care această clauză este interzisă de legislația locală,
				această clauză nu vi se aplică și nu vă privează de protecția
				prevederilor obligatorii ale legilor privind protecția consumatorilor
				din țara dvs.
			</p>
			<h2>Diverse</h2>
			<p>
				Acești Termeni de utilizare, așa cum pot fi modificați din când în când,
				precizează complet și exclusiv acordul dintre noi și dvs. cu privire la
				website-ul și aplicațiile WikiSmarts și niciun alt termen care v-ar fi
				putut fi comunicat oral sau în orice alt mod nu are vreo forță sau
				efect.
			</p>
			<p>
				Sunteți de acord și recunoașteți că încălcarea de către dvs. a oricăror
				restricții din acești Termeni de utilizare privind utilizarea
				website-ului sau aplicațiilor WikiSmarts, a conținutului website-ului
				sau aplicațiilor WikiSmarts sau a software-ului sau a serviciilor
				disponibile pe sau prin intermediul website-ului sau aplicațiilor
				WikiSmarts, sau utilizarea sau dezvăluirea informațiilor confidențiale
				într-o manieră incompatibilă cu prevederile acestor Termeni de
				utilizare, ne poate cauza daune ireparabile pentru care remediile legale
				pot fi inadecvate.
			</p>
			<p>
				Dacă orice parte a acestor Termeni de utilizare este inaplicabilă,
				partea inaplicabilă va fi interpretată ca reflectând, cât mai aproape
				posibil, intențiile inițiale ale părților. Celelalte prevederi ale
				acestor Termeni de utilizare vor rămâne în vigoare și aplicabile.
			</p>
			<p>
				Ne puteți contacta oricând la adresa de email <a>gdpr@ithit.ro</a> sau
				la telefon (0371) 395 028.
			</p>
		</Legal>
	);
}
