import React, { useEffect, useState } from "react";
import {
	getApiConfig,
	getImageNameFromAbsolutePath,
	hideOverlay,
	showBadAnswerBanner,
	showCorrectAnswerBanner,
	shuffle,
} from "../../../scripts/helpers";
import axios from "axios";
import { tryHideOverlay } from "../../../scripts/dbTest";
import Cookies from "js-cookie";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CheckButton } from "../CheckButton";
import "../../../styles/modules/matematica/matematicaGame1.css";
import { lan } from "../../../scripts/text";

export function DezvoltareGame5Area(props) {
	const [allScenes, setAllScenes] = useState([]);
	let scenesInfo = props.scenesInfo;
	const categoryId = "639ad450ac408822e8da4f4c";
	const headers = getApiConfig().headers;
	const gameId = props.gameId;
	const [cards, setCards] = useState([]);

	function onDragEnd(result) {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const items = Array.from(cards);
		const [reorderedItem] = items.splice(source.index, 1);
		items.splice(destination.index, 0, reorderedItem);
		setCards(items);
	}

	function getAllScenes() {
		hideOverlay();
		let scenesUrl = `${
			getApiConfig().baseUrl
		}/categories/${categoryId}/games/${gameId}/scenes?randomised=true&level=${Cookies.get(
			"selectedLevel"
		)}&limit=10&nr_answers=${scenesInfo[props.nrOfScenes - 1].nrOfAnswers}`;
		axios.get(scenesUrl, { headers: headers }).then((response) => {
			let data = response.data;
			setAllScenes(data);
			getNextScene(response.data[0]);
		});
	}

	const updateImageScene = (data) => {
		let imagesUrl = `${getApiConfig().baseUrl}`;

		let cardsTemp = [];
		for (let imageIndex in data.answers) {
			cardsTemp.push({
				id: `image-${imageIndex}`,
				image: `${imagesUrl}${getImageNameFromAbsolutePath(
					data.answers[imageIndex]
				)}`,
				index: parseInt(imageIndex),
			});
		}

		shuffle(shuffle(cardsTemp));
		setCards(cardsTemp);
	};

	function getNextScene(scene) {
		tryHideOverlay();
		updateImageScene(scene);
	}

	useEffect(() => {
		if (props.nrOfScenes <= props.maxNrOfScenes && scenesInfo.length) {
			if (props.nrOfScenes == 1) {
				getAllScenes();
			} else {
				getNextScene(allScenes[props.nrOfScenes - 1]);
			}
		}
	}, [props.nrOfScenes, scenesInfo]);

	useEffect(() => {
		tryHideOverlay();
	}, [props.nrOfScenes]);

	function checkAnswer() {
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].index != i) {
				showBadAnswerBanner(
					props.setNrOfScenes,
					props.nrOfScenes,
					props.gameId
				);
				return;
			}
		}
		showCorrectAnswerBanner(props.setNrOfScenes, props.nrOfScenes, gameId);
	}

	return (
		<div
			className="game-area"
			style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
		>
			<div style={{ height: "15%", display: "flex", justifyContent: "center" }}>
				<h1 style={{ margin: "auto 0" }}>
					{scenesInfo[props.nrOfScenes - 1]
						? scenesInfo[props.nrOfScenes - 1]?.questionText?.[lan]
						: ""}
				</h1>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="cards" direction="horizontal">
					{(provided) => (
						<div
							className="image-card-container"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{cards.map(({ id, image, index }, i) => {
								return (
									<Draggable key={id} draggableId={id} index={i}>
										{(provided) => (
											<div
												className="image-card"
												id={`image-${i + 1}`}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<img src={image} alt="img" />
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<CheckButton
				onClick={checkAnswer}
				style={{ height: "20%" }}
				imageStyle={{ height: "100%" }}
			/>
		</div>
	);
}
