import Cookies from "js-cookie";
import config from "../config";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { googleLogout } from "@react-oauth/google";

export async function logout() {
	Cookies.remove(config.cookie_token_key);
	googleLogout();
	window.location.href = "/";
}

export async function logoutWithoutRedirect() {
	Cookies.remove(config.cookie_token_key);
	googleLogout();
}

export function loggedIn() {
	// console.log(Cookies.get(config.cookie_token_key));
	return false;
}

export async function handleSubscriptionCancel() {
	let response = await axios.post(
		`${getApiConfig().baseUrl}/subscriptions/cancel`,
		{},
		{ headers: getApiConfig().headers }
	);
	if (response.data?.success) {
		window.location.href = "/";
	} else {
		toast.error("A aparut o eroare. Te rugam sa incerci din nou.");
	}
}

export function handleGameLevelStatus(
	gamesSubInfo,
	gameId,
	levelToPlay,
	currentReachedLevel,
	role,
	availableLevels
) {
	let isFreeToPlay =
		gamesSubInfo[gameId] && gamesSubInfo[gameId].includes(levelToPlay);
	let isImplemented = availableLevels >= levelToPlay;
	let userIsSubscriber = role === "subscribed_player";
	let isUnlocked = levelToPlay <= currentReachedLevel;
	if (!isImplemented) {
		return "coming-soon";
	}
	if (isFreeToPlay || userIsSubscriber) {
		if (isUnlocked) {
			return "ready";
		} else {
			return "locked";
		}
	}
	if (!isFreeToPlay) {
		return "needs-subscription";
	}
}

export const releaseDate = new Date("OCTOBER 06, 2022 16:00:00");
// export const releaseDate = new Date("SEPTEMBER 22 , 2022 14:05:00");

export const plusPointsForCorrectResponse = 8;
const minusPointsForWrongResponse = 5;
export const getPassScore = (maxNrOfScenes) => {
	if (!maxNrOfScenes) {
		maxNrOfScenes = 10;
	}
	const correctPercentage = 0.8;
	const wrongPercentage = 0.2;
	return (
		correctPercentage * maxNrOfScenes * plusPointsForCorrectResponse -
		wrongPercentage * maxNrOfScenes * minusPointsForWrongResponse
	);
};
export const getOneStarScore = (maxNrOfScenes) => {
	if (!maxNrOfScenes) {
		maxNrOfScenes = 10;
	}
	const correctPercentage = 0.8;
	const wrongPercentage = 0.2;
	return (
		correctPercentage * maxNrOfScenes * plusPointsForCorrectResponse -
		wrongPercentage * maxNrOfScenes * minusPointsForWrongResponse
	);
};
export const getTwoStarScore = (maxNrOfScenes) => {
	if (!maxNrOfScenes) {
		maxNrOfScenes = 10;
	}
	const correctPercentage = 0.9;
	const wrongPercentage = 0.1;
	return (
		correctPercentage * maxNrOfScenes * plusPointsForCorrectResponse -
		wrongPercentage * maxNrOfScenes * minusPointsForWrongResponse
	);
};
export const getThreeStarScore = (maxNrOfScenes) => {
	if (!maxNrOfScenes) {
		maxNrOfScenes = 10;
	}
	const correctPercentage = 1;
	const wrongPercentage = 0;
	return correctPercentage * maxNrOfScenes * plusPointsForCorrectResponse;
};

export const getStars = (score, maxNrOfScenes) => {
	const oneStarScore = getOneStarScore(maxNrOfScenes);
	const twoStarScore = getTwoStarScore(maxNrOfScenes);
	const threeStarScore = getThreeStarScore(maxNrOfScenes);
	if (score < oneStarScore) {
		return 0;
	} else if (score < twoStarScore) {
		return 1;
	} else if (score < threeStarScore) {
		return 2;
	} else {
		return 3;
	}
};

export function permutator(inputArr) {
	let results = [];

	function permute(arr, memo) {
		var cur,
			memo = memo || [];

		for (var i = 0; i < arr.length; i++) {
			cur = arr.splice(i, 1);
			if (arr.length === 0) {
				results.push(memo.concat(cur));
			}
			permute(arr.slice(), memo.concat(cur));
			arr.splice(i, 0, cur[0]);
		}

		return results;
	}

	return permute(inputArr);
}

export async function getLevel(gameId) {
	let level;
	let passScore = getPassScore();
	await axios
		.get(
			`${
				getApiConfig().baseUrl
			}/gameScores/getNextLevel?gameId=${gameId}&passThreshold=${passScore}`,
			{
				headers: getApiConfig().headers,
			}
		)
		.then((response) => {
			let data = response.data;
			level = data;
		})
		.catch((error) => {
			// console.log(error);
		});
	if (level >= 1 && level <= 3) return level;
	else if (level <= 1) {
		return 1;
	} else {
		return 3;
	}
}

export function setGameInstructions(innerHTML) {
	document.querySelector(".game-instructions p").innerHTML = innerHTML;
}

export function getPossibleAnswers(
	correctAnswer,
	min = 1,
	max = 5,
	nrOfOptions = 4
) {
	let possibleAnswers = getXRandomValuesFromArray(
		createArrayOfIncrementingNumbers(min, max),
		nrOfOptions
	);
	if (!possibleAnswers.includes(correctAnswer)) {
		possibleAnswers[0] = correctAnswer;
	}
	return shuffle(shuffle(possibleAnswers));
}

export function getXRandomValuesWithMaxSum(sum = 10, nrOfValues = 4, min = 1) {
	let values = [];
	for (let i = 0; i < nrOfValues; i++) {
		if (sum > 0) {
			let val = getRndInteger(min, sum - min);
			if (val > sum) {
				values.push(sum);
			} else {
				values.push(val);
			}
			sum = sum - val;
		} else {
			values.push(0);
		}
	}
	return shuffle(shuffle(values));
}

export function snap(
	event,
	originalContainerClassSelector,
	droppableScreen = ""
) {
	let draggableElement = event.target;
	let droppableContainer = event.relatedTarget;
	if (droppableContainer) {
		let oldElement = document.querySelector(
			`${droppableScreen} #${droppableContainer.id} .drag-drop`
		);
		if (oldElement && draggableElement.id != oldElement.id) {
			// console.log(oldElement, draggableElement);
			document
				.querySelector(`${originalContainerClassSelector}`)
				.appendChild(oldElement);
			oldElement.setAttribute("data-x", `0px`);
			oldElement.setAttribute("data-y", `0px`);
			oldElement.classList.remove("can-drop");
			oldElement.style.transform = "translate(0px, 0px)";
		}
		if (draggableElement.id.includes("draggable")) {
			draggableElement.setAttribute("data-x", `0px`);
			draggableElement.setAttribute("data-y", `0px`);
			draggableElement.style.transform = "translate(0px, 0px)";
			droppableContainer.appendChild(draggableElement);
		}
	} else {
		draggableElement.setAttribute("data-x", `0px`);
		draggableElement.setAttribute("data-y", `0px`);
		draggableElement.style.transform = "translate(0px, 0px)";
		draggableElement.parentNode.removeChild(draggableElement);
		document
			.querySelector(`${originalContainerClassSelector}`)
			.appendChild(draggableElement);
	}
}

export function getImageDistribution(nrOfOptions, sum) {
	let currentNumber,
		rest,
		nr,
		i,
		initSum = sum,
		initNrOfOptions = nrOfOptions,
		nrit = 0;
	let arr = [],
		restArr;
	while (true) {
		nrit++;
		// console.log(nrit);
		if (nrit === 100) {
			arr = [];
			sum = initSum;
			nrOfOptions = initNrOfOptions;
			arr.push(sum);
			for (let i = 1; i < nrOfOptions; i++) {
				arr.push(0);
			}
			break;
		}
		rest = 0;
		restArr = [];
		nr = 0;
		i = 0;
		while (nr < nrOfOptions) {
			if (!arr.includes(i)) {
				rest += i;
				restArr.push(i);
				nr++;
			}
			i++;
		}

		if (rest === sum) {
			arr = arr.concat(restArr);
			break;
		}

		let nrp = 0;
		currentNumber = getRndInteger(0, sum);
		while (
			currentNumber + rest > sum ||
			arr.includes(currentNumber) ||
			restArr.includes(currentNumber)
		) {
			currentNumber = getRndInteger(0, sum);
			nrp++;
			if (nrp === 1000) {
				break;
			}
		}

		if (nrp === 1000) {
			arr = [];
			sum = initSum;
			nrOfOptions = initNrOfOptions;
			continue;
		}

		sum = sum - currentNumber;
		nrOfOptions--;
		arr.push(currentNumber);
		if (nrOfOptions === 1 && !arr.includes(sum)) {
			arr.push(sum);
			break;
		}
		if (nrOfOptions === 1 && arr.includes(sum)) {
			arr = [];
			sum = initSum;
			nrOfOptions = initNrOfOptions;
			continue;
		}
	}
	return arr;
}

export function animationsFade(
	toFadeOut,
	toFadeIn,
	displayShowing,
	displayNotShowing,
	smooth = true
) {
	if (smooth) {
		let opacity = toFadeOut.style.opacity;
		let request;

		const animationFadeOut = () => {
			opacity -= 0.04;
			if (opacity <= 0) {
				toFadeOut.style.display = displayNotShowing;
				toFadeOut.style.opacity = 0;
				toFadeIn.style.display = displayShowing;
				opacity = 0;
				// cancelAnimationFrame(request);
			} else {
				toFadeOut.style.opacity = opacity;
			}
		};

		const animationFadeIn = () => {
			opacity += 0.04;
			if (opacity >= 1) {
				toFadeIn.style.opacity = 1;
				cancelAnimationFrame(request);
			} else {
				toFadeIn.style.opacity = opacity;
			}
		};

		const rAf = () => {
			if (toFadeOut.style.opacity != 0) {
				request = requestAnimationFrame(rAf);
				animationFadeOut();
			} else {
				request = requestAnimationFrame(rAf);
				animationFadeIn();
			}
		};
		rAf();
	} else {
		toFadeOut.style.opacity = 0;
		toFadeIn.style.opacity = 1;
	}
}

export function switchBetweenScreens(
	toFadeOut,
	toFadeIn,
	delay = 2000,
	smooth = true,
	displayShowing = "block",
	displayNotShowing = "none"
) {
	toFadeOut.style.display = displayShowing;
	toFadeOut.style.opacity = "1";
	toFadeIn.style.display = displayNotShowing;
	toFadeIn.style.opacity = "0";
	setTimeout(() => {
		animationsFade(
			toFadeOut,
			toFadeIn,
			displayShowing,
			displayNotShowing,
			smooth
		);
	}, delay);
}

export function handleMouseMove(event) {
	let x = event.pageX;
	let y = event.pageY;
	let followCube = document.createElement("div");
	followCube.id = "cube";
	followCube.style.width = "12px";
	followCube.style.height = "12px";
	followCube.style.backgroundColor = "grey";
	followCube.style.borderRadius = "30px";
	followCube.style.position = "absolute";
	followCube.style.left = x + "px";
	followCube.style.top = y + "px";
	document.body.appendChild(followCube);
}

export function dragMoveListener(event) {
	var target = event.target;
	// keep the dragged position in the data-x/data-y attributes
	var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
	var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

	// translate the element
	target.style.webkitTransform = target.style.transform =
		"translate(" + x + "px, " + y + "px)";

	// update the posiion attributes
	target.setAttribute("data-x", x);
	target.setAttribute("data-y", y);
}

export function checkCorrectAnswerForDraggable(totalNumberOfDroppableItems) {
	// console.log(document.querySelectorAll(".can-drop"));
	if (
		document.querySelectorAll(".can-drop").length ===
		totalNumberOfDroppableItems
	) {
		return true;
	} else {
		return false;
	}
}

export function checkCorrectAnswerForDraggable2(totalNumberOfDroppableItems) {
	// console.log(document.querySelectorAll(".can-drop"));
	if (
		document.querySelectorAll(".can-drop").length ===
			totalNumberOfDroppableItems &&
		document.querySelectorAll(".dropped").length === totalNumberOfDroppableItems
	) {
		return true;
	} else {
		return false;
	}
}

export function getRndInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function hideAnswerFeedback() {
	document.getElementById("correct-answer").style.display = "none";
	document.getElementById("bad-answer").style.display = "none";
}

export function getApiConfig() {
	return {
		// baseUrl: "http://localhost:4000",
		baseUrl: "https://dev-api.wikismarts.com",
		// baseUrl: "https://api.wikismarts.com",
		// baseUrl: "http://192.168.1.6:3000",
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${
				Cookies.get(config.cookie_token_key) ??
				new URLSearchParams(window.location.search).get("sid")
			}`,
		},
	};
}
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}
export function isMobileApp() {
	return (
		window.navigator.userAgent.includes("Android") &&
		(window.navigator.userAgent.includes("Build") ||
			window.navigator.userAgent.includes("WikiSmartsAndroid"))
	) || (window.navigator.userAgent.includes("WikiSmartsIos"));
}

export function isMobileDevice() {
	return isMobile || isMobileApp();
}

export function isIOS() {
	let agent = window.navigator.userAgent;
	return (agent.includes("iPhone") || agent.includes("iPad")) && !agent.includes("Android");
}

export function transformAbsolutePathToRelative(absolutePath) {
	return (
		getApiConfig().baseUrl +
		absolutePath.replace("/home/KidsBackendNode/assets", "")
	);
}

export function getImageNameFromAbsolutePath(absolutePath) {
	let relativePath = absolutePath.split("/assets")[1];
	return relativePath;
}

export function rgb2hex(rgb) {
	return `#${rgb
		.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
		.slice(1)
		.map((n) => parseInt(n, 10).toString(16).padStart(2, "0"))
		.join("")}`;
}

export function shuffle(array) {
	let currentIndex = array.length,
		randomIndex;

	while (currentIndex != 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
}

export function getXRandomValuesFromArray(arr, x) {
	shuffle(arr);
	arr = arr.slice(0, x);
	return arr;
}

export function createArrayOfIncrementingNumbers(start, end, order = 1) {
	return Array(end - start + 1)
		.fill()
		.map((_, idx) => (order ? start + idx : end - idx));
}

export function showAnswer(id) {
	let answerCont = document.getElementById(id);
	if (answerCont) {
		answerCont.style.display = "block";
	}
}

export function hideAnswer(id) {
	let answerCont = document.getElementById(id);
	if (answerCont) {
		answerCont.style.display = "none";
	}
}

export function bringToFrontOverlay() {
	let overlay = document.getElementById(`overlay`);
	if (overlay) overlay.classList.add("active-overlay");
}

export function hideOverlay() {
	let overlay = document.getElementById(`overlay`);
	if (overlay) overlay.classList.remove("visible-overlay");
	if (overlay) overlay.classList.remove("active-overlay");
}

export function showOverlay() {
	let overlay = document.getElementById(`overlay`);
	if (overlay) overlay.classList.add("visible-overlay");
}

export function overlayVisible() {
	let overlay = document.getElementById(`overlay`);
	if (overlay) return [...overlay.classList].includes("active-overlay");
}

export function showCorrectAnswerBanner(
	setNrOfScenes,
	nrOfScenes,
	gameId = ""
) {
	showAnswer("correct-answer");
	bringToFrontOverlay();

	setTimeout(() => {
		showOverlay();
	}, 1700);

	setTimeout(() => {
		setNrOfScenes(nrOfScenes + 1);
		hideAnswer("correct-answer");
	}, 2000);

	updateScore(gameId, "up");
}

export function showBadAnswerBanner(setNrOfScenes, nrOfScenes, gameId = "") {
	showAnswer("bad-answer");
	bringToFrontOverlay();

	setTimeout(() => {
		showOverlay();
	}, 1700);

	setTimeout(() => {
		setNrOfScenes(nrOfScenes + 1);
		hideAnswer("bad-answer");
	}, 2000);

	updateScore(gameId, "down");
}

export function fillGrid(imageUrl, numberOfImages, grid) {
	grid.innerHTML = "";
	for (let i = 1; i <= numberOfImages; i++) {
		grid.innerHTML += `<img src = ${imageUrl}>`;
	}
}

export function createDiv(parent, classList, styles, innerHtml, id) {
	let divElement = document.createElement("div");
	if (id) {
		divElement.id = id;
	}
	for (let i = 0; i < classList.length; i++) {
		divElement.classList.add(classList[i]);
	}
	if (innerHtml) {
		divElement.innerHTML = innerHtml;
	}
	if (styles) {
		divElement.style = styles;
	}
	if (parent) {
		parent.appendChild(divElement);
	}
	return divElement;
}

export function getContainer(event, className) {
	let i = 0;
	while (![...event.path[i].classList].includes(className)) {
		i++;
	}
	let cardDiv = event.path[i];
	return cardDiv;
}

export function arrayEquals(a, b) {
	return (
		Array.isArray(a) &&
		Array.isArray(b) &&
		a.length === b.length &&
		a.every((val, index) => val === b[index])
	);
}

export function hideElement(elementId) {
	document.getElementById(elementId).style.display = "none";
}

export function updateScore(gameId, direction, maxScore = 80) {
	let existingTotalScoreCookie = parseInt(Cookies.get(`totalScore-${gameId}`));
	let scoreUpdate = document.querySelector("#score-update");
	let totalScore = document.querySelector("#total-score");
	let currentTotalScore = 0;

	if (existingTotalScoreCookie) {
		currentTotalScore = existingTotalScoreCookie;
	}

	// console.log('SE APELEAZA update score: ', currentTotalScore, direction);

	if (direction === "up") {
		// if (maxScore >= currentTotalScore + 8) {
		currentTotalScore += 8;
		// }
		// console.log('INTRA IN SCORE UP: ', currentTotalScore);
		if (scoreUpdate && totalScore) {
			scoreUpdate.innerHTML = "+8";
			scoreUpdate.classList.add("score-update-active");
			scoreUpdate.style.color = "green";
		}
	} else if (direction === "down") {
		if (currentTotalScore - 5 >= 0) {
			currentTotalScore = currentTotalScore - 5;
			if (scoreUpdate && totalScore) {
				scoreUpdate.innerHTML = "-5";
				scoreUpdate.classList.add("score-update-active");
				scoreUpdate.style.color = "red";
			}
		}
	}

	Cookies.set(`totalScore-${gameId}`, currentTotalScore);
	if (totalScore) {
		totalScore.innerHTML = currentTotalScore;
	}

	let existingGameScoreCookie = parseInt(Cookies.get(`gameScore-${gameId}`));
	let gameScore = document.querySelector("#game-score");
	let currentGameScore = 0;

	if (existingGameScoreCookie) {
		currentGameScore = existingGameScoreCookie;
	}
	if (direction === "up") {
		if (currentGameScore + 8 <= maxScore) {
			currentGameScore += 8;
		}
	} else if (direction === "down") {
		if (currentGameScore - 5 >= 0) {
			currentGameScore = currentGameScore - 5;
		}
	}

	Cookies.set(`gameScore-${gameId}`, currentGameScore);
	if (gameScore) {
		gameScore.innerHTML = currentGameScore;
	}
}

export async function getHighscore(gameId, level) {
	let response = await axios.get(
		`${getApiConfig().baseUrl}/scores?gameId=${gameId}&highscore=1${
			level ? "&level=" + level : ""
		}`,
		{ headers: getApiConfig().headers }
	);
	if (response.data.length > 0) {
		return response.data[0].score;
	}
	return 0;
}

export async function getGameHighscore(gameId, level) {
	let response = await axios.get(
		`${getApiConfig().baseUrl}/gameScores?gameId=${gameId}&highscore=1${
			level ? "&level=" + level : ""
		}`,
		{ headers: getApiConfig().headers }
	);
	if (response.data.length > 0) {
		return response.data[0].score;
	}
	return 0;
}

export async function getCurrentTotalScore(gameId, level) {
	let response = await axios.get(
		`${getApiConfig().baseUrl}/scores?gameId=${gameId}&lastscore=1${
			level ? "&level=" + level : ""
		}`,
		{ headers: getApiConfig().headers }
	);
	if (response.data.length > 0) {
		return response.data[0].score;
	}
	return 0;
}

export function getLastscore(gameId) {
	axios
		.get(`${getApiConfig().baseUrl}/scores?gameId=${gameId}&lastscore=1`, {
			headers: getApiConfig().headers,
		})
		.then((response) => {
			if (response.data.length > 0) {
				return response.data[0].score;
			}
		});
}

export function getRndColor(type) {
	if (type == "light") {
		const colors = [
			"#FBF8CC",
			"#FDE4CF",
			"#FFCFD2",
			"#F1C0E8",
			"#CFBAF0",
			"#A3C4F3",
			"#90DBF4",
			"#8EECF5",
			"#98F5E1",
			"#B9FBC0",
		];
		return colors[getRndInteger(0, colors.length - 1)];
	}
}

export async function getAllScenesWithCreate_post(
	gameId,
	setAllScenes,
	body,
	maxNrOfScenes,
	getNextScene,
	pathTemp
) {
	let scenesUrl = `${getApiConfig().baseUrl}/randomizedScenes/${gameId}`;
	let allScenesTemp = [];
	let getNextSceneIndex = 0;
	let path = pathTemp ? pathTemp : "/create";

	for (let index = 0; index < maxNrOfScenes * 2; index++) {
		let response = await axios.post(`${scenesUrl}${path}`, body, {
			headers: getApiConfig().headers,
		});
		try {
			let data = response.data;
			allScenesTemp.push(data);
			setAllScenes(allScenesTemp);
			if (index == getNextSceneIndex) {
				getNextScene(data);
			}
		} catch (error) {
			console.error(error);
			if (index === getNextSceneIndex) {
				getNextSceneIndex++;
			}
		}
	}
	// console.log(allScenesTemp);
	return allScenesTemp;
}

export async function getAllScenesCustom_get(
	setAllScenes,
	maxNrOfScenes,
	getNextScene,
	scenesUrl
) {
	let allScenesTemp = [];
	let getNextSceneIndex = 0;

	for (let index = 0; index < maxNrOfScenes * 2; index++) {
		await axios
			.get(scenesUrl, { headers: getApiConfig().headers })
			.then((response) => {
				let data = response.data;
				// console.log(data);
				allScenesTemp.push(data);
				setAllScenes(allScenesTemp);
				if (index == getNextSceneIndex) {
					getNextScene(data);
				}
			})
			.catch((error) => {
				console.error(error);
				if (index === getNextSceneIndex) {
					getNextSceneIndex++;
				}
			});
	}
	// console.log(allScenesTemp);
}
