import React, { useState, useEffect, useRef } from "react";
import imgGameHeader from "../../assets/banner_modules.svg";
// import ScoreIcon from "../../assets/cufaras/cufar_fund.svg";
import Congratulations from "./Congratulations";
import BackToTheSameLevel from "./BackToTheSameLevel";
import "../../styles/gamebox__mobile.css";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { getLevelInfo, getModuleGameInfo } from "../../scripts/dbTest.js";
import "../../styles/antd.css";
import {
	isMobileDevice,
	plusPointsForCorrectResponse,
} from "../../scripts/helpers";

import Cookies from "js-cookie";
import { getApiConfig, getPassScore } from "../../scripts/helpers";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenu from "../Navbar/BurgerMenu";
import { CustomButton } from "./CustomButton";
import "../../styles/instructions.css";
import { IMAGES, TEXT, lan } from "../../scripts/text";

export default function GameBox(props) {
	const gameboxRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();
	const maxNrOfScenes = props.children.props.maxNrOfScenes;
	const passScore = getPassScore(maxNrOfScenes);
	const [scoreIcon, setScoreIcon] = useState("cufar_fund.svg");
	const [gameAreaVizible, setGameAreaVizible] = useState(true);
	const [nrOfScenes, setNrOfScenes] = useState(1);
	const [highscore, setHighscore] = useState(0);
	const [initialScoreisFound, setInitialScoreIsFound] = useState(false);
	const [gameScore, setGameScore] = useState(-1);
	const [level, setLevel] = useState(-1);
	const [scenesInfo, setScenesInfo] = useState([]);

	const antIcon = (
		<Loading3QuartersOutlined style={{ fontSize: "1rem" }} spin />
	);

	let gameNumber = props.gameNumber;

	let gameInfo = getModuleGameInfo(props.moduleName).gamesInfo[gameNumber - 1];
	let { moduleName, color } = getModuleGameInfo(props.moduleName);
	function toggleVisibleInstructions() {
		if (document.querySelector(".game-instructions"))
			document
				.querySelector(".game-instructions")
				.classList.toggle("game-instructions-visible");
	}

	function showQuestionMark() {
		if (document.querySelector(".game-instructions-question-mark"))
			document.querySelector(
				".game-instructions-question-mark"
			).style.animation = "pulse-in-1 1s ease";
	}

	function handleLevel() {
		if (Cookies.get("selectedLevel")) {
			setLevel(Cookies.get("selectedLevel"));
		} else {
			let currentPath = location.pathname;
			let game = currentPath.split("/");
			game = game[game.length - 1];
			navigate(currentPath.replace(game, "explorer"));
		}
	}

	function saveScore(level) {
		console.log(level);
		if (Cookies.get(`totalScore-${props.gameId}`)) {
			axios
				.post(
					`${getApiConfig().baseUrl}/scores/`,
					{
						game: props.gameId,
						score: Cookies.get(`totalScore-${props.gameId}`),
						level: level,
					},
					{ headers: getApiConfig().headers }
				)
				.then((response) => {
					// console.log(response.data);
					// console.log("Score saved");
					Cookies.remove(`totalScore-${props.gameId}`);
				});
			axios
				.post(
					`${getApiConfig().baseUrl}/gameScores/`,
					{
						game: props.gameId,
						score: Cookies.get(`gameScore-${props.gameId}`),
						level: level,
					},
					{ headers: getApiConfig().headers }
				)
				.then((response) => {
					Cookies.remove(`gameScore-${props.gameId}`);
					if (response.data.score >= 0) setGameScore(response.data.score);
				});
		}
	}

	async function getAndSetScore() {
		await axios
			.get(
				`${getApiConfig().baseUrl}/scores?gameId=${
					props.gameId
				}&lastscore=1&level=${level}`,
				{
					headers: getApiConfig().headers,
				}
			)
			.then((response) => {
				if (response.data.length > 0) {
					Cookies.set(`totalScore-${props.gameId}`, response.data[0].score);
				}
				setInitialScoreIsFound(true);
			});
	}

	useEffect(() => {
		if (nrOfScenes === 1 && level >= 1) {
			getAndSetScore();
			Cookies.set(`gameScore-${props.gameId}`, 0);
		}
		if (nrOfScenes > props.children.props.maxNrOfScenes) {
			saveScore(level);
		}

		let currentScore = Cookies.get(`gameScore-${props.gameId}`);

		if (currentScore > maxNrOfScenes * plusPointsForCorrectResponse * 0.8) {
			setScoreIcon("cufar_stelute_5.svg");
		} else if (
			currentScore >
			maxNrOfScenes * plusPointsForCorrectResponse * 0.6
		) {
			setScoreIcon("cufar_stelute_4.svg");
		} else if (
			currentScore >
			maxNrOfScenes * plusPointsForCorrectResponse * 0.4
		) {
			setScoreIcon("cufar_stelute_3.svg");
		} else if (
			currentScore >
			maxNrOfScenes * plusPointsForCorrectResponse * 0.2
		) {
			setScoreIcon("cufar_stelute_2.svg");
		} else if (
			currentScore >
			maxNrOfScenes * plusPointsForCorrectResponse * 0.1
		) {
			setScoreIcon("cufar_stelute_1.svg");
		} else {
			setScoreIcon("cufar_fund.svg");
		}
	}, [nrOfScenes, level]);

	useEffect(() => {
		showQuestionMark();
		handleLevel();
		let scenesInfoTemp = [];
		for (let i = 0; i < maxNrOfScenes; i++) {
			scenesInfoTemp.push(
				getLevelInfo(props.gameId, Cookies.get("selectedLevel"))
			);
		}
		setScenesInfo(scenesInfoTemp);

		if (isMobileDevice()) {
			document
				.querySelector(".mobile-landscape-container")
				.classList.add("mobile-landscape-container-ingame");
		}

		return () => {
			saveScore(Cookies.get("selectedLevel"));

			if (isMobileDevice()) {
				document
					.querySelector(".mobile-landscape-container")
					.classList.remove("mobile-landscape-container-ingame");
			}
		};
	}, []);

	useEffect(() => {
		if (level > 0 && scenesInfo.length && nrOfScenes === 1) {
			// console.log("gamebox");
			let instructions = "";
			if (scenesInfo[nrOfScenes - 1].instructions) {
				instructions = scenesInfo[nrOfScenes - 1].instructions[lan];
			} else if (scenesInfo[nrOfScenes - 1].gameLevelDescription) {
				instructions = scenesInfo[nrOfScenes - 1].gameLevelDescription[lan];
			} else {
				instructions = gameInfo.gameDescription[lan];
			}
			if (document.querySelector(".game-instructions p"))
				document.querySelector(".game-instructions p").innerHTML = instructions;
		}
	}, [nrOfScenes, level]);
	return (
		<div
			className={
				isMobileDevice()
					? "game-box-container game-box-container-mobile"
					: "container"
			}
			style={{ position: "relative" }}
		>
			{nrOfScenes > props.children.props.maxNrOfScenes &&
				gameScore !== -1 &&
				(gameScore >= passScore ? (
					<>
						{isMobileDevice() ? (
							<img
								src={require("../../assets/background.svg").default}
								className="background-image-circles"
								style={{
									animation: "container-animation 5s 0.3s both",
								}}
							/>
						) : (
							""
						)}
						<Congratulations
							gameBoxTopRowBkg={props.gameBoxTopRowBkg}
							gameBoxDivider={props.gameBoxDivider}
							moduleName={props.moduleName}
							moduleIcon={props.moduleIcon}
							setGameAreaVizible={setGameAreaVizible}
							gameId={props.gameId}
							color={color}
							level={level}
							gameInfo={gameInfo}
							gameNumber={gameNumber}
							gameTitle={gameInfo.gameTitle}
							gameDescription={gameInfo.gameDescription}
						/>
					</>
				) : (
					<>
						{isMobileDevice() ? (
							<img
								src={require("../../assets/background.svg").default}
								className="background-image-circles"
								style={{
									animation: "container-animation 5s 0.3s both",
								}}
							/>
						) : (
							""
						)}
						<BackToTheSameLevel
							gameBoxTopRowBkg={props.gameBoxTopRowBkg}
							gameBoxDivider={props.gameBoxDivider}
							moduleName={props.moduleName}
							moduleIcon={props.moduleIcon}
							setGameAreaVizible={setGameAreaVizible}
							color={color}
							level={level}
							gameInfo={gameInfo}
						/>
					</>
				))}
			{gameAreaVizible && (
				<div
					className="game-box"
					style={{
						border: `3px solid ${props.gameBoxDivider}`,
					}}
				>
					<div
						className="game-instructions"
						style={{
							backgroundColor: props.gameBoxTopRowBkg,
							border: "4px solid " + props.gameBoxDivider,

							color: color,
						}}
					>
						<img
							src={require("../../assets/mascot/wiki_cerinta.png").default}
						/>
						<p style={{ color: color }}></p>
					</div>
					<div className="row game-box-top-row">
						<div className="col-md-12">
							<div className="answer-status-banner" id="correct-answer">
								<img src={IMAGES._WELL_DONE[lan]} alt="correct answer" />
							</div>

							<div className="answer-status-banner" id="bad-answer">
								<img src={IMAGES._OOPS[lan]} alt="bad answer" />
							</div>

							<div
								className="game-box-top"
								style={{
									backgroundColor: props.gameBoxTopRowBkg,
									position: "relative",
								}}
							>
								<div
									className="game-heading row"
									style={{
										height: "100%",
									}}
								>
									<div
										className={isMobileDevice() ? "col-6" : "col-8"}
										style={{
											display: "flex",
											alignItems: "center",
											maxHeight: "100%",
											margin: isMobileDevice() ? "0" : "",
										}}
									>
										{!isMobileDevice() ? (
											<img
												src={props.moduleIcon}
												style={{ maxHeight: "80%" }}
												alt=""
											></img>
										) : (
											""
										)}

										<h2
											style={{
												margin: "0",
												padding: "0 1rem",
												color: color,
												borderRight: "2px solid white",
											}}
										>
											{!isMobileDevice() ? `${moduleName[lan]} - ` : ""}{" "}
											{gameInfo.gameTitle[lan]}
										</h2>
										<h2
											style={{
												margin: "0",
												padding: "0 1rem",
												color: color,
												display: "flex",
											}}
										>
											{TEXT._LEVEL[lan]}:
											<span style={{ marginLeft: "0.5rem" }}>
												{level !== -1 ? level : <Spin indicator={antIcon} />}
											</span>
										</h2>
									</div>
									<div
										className="col-4"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "right",
											maxHeight: "100%",
											margin: 0,
										}}
									>
										<img
											src={require(`../../assets/cufaras/${scoreIcon}`).default}
											style={{ maxHeight: "60%" }}
											alt=""
										></img>
										{/* <h2 style={{ padding: "0 4%", color: color }}>
											Scor planșă:{" "}
											<span id="game-score">
												{Cookies.get(`gameScore-${props.gameId}`) ?? 0}
											</span>
										</h2> */}
										<h2 style={{ padding: "0 4%", color: color, margin: 0 }}>
											{TEXT._TOTAL_SCORE[lan]}:{" "}
											{initialScoreisFound ? (
												<span id="total-score">
													{Cookies.get(`totalScore-${props.gameId}`) ?? 0}
												</span>
											) : (
												""
											)}
										</h2>
										<span
											id="score-update"
											onAnimationEnd={(e) => {
												e.currentTarget.classList.remove("score-update-active");
											}}
										></span>
										<div
											className="game-instructions-question-mark instructions-button"
											style={{
												backgroundColor: props.gameBoxTopRowBkg,
												border: "3px solid " + props.gameBoxDivider,
											}}
											onClick={toggleVisibleInstructions}
										>
											<h1 style={{ color: color }}>?</h1>
										</div>
									</div>
								</div>
								{isMobileDevice() ? <BurgerMenu color={props.color} /> : ""}
								<img
									src={imgGameHeader}
									style={{
										position: "absolute",
										maxWidth: "none",
										top: 0,
										right: 0,
									}}
									alt=""
								></img>
							</div>
							<div
								className="game-box-divider"
								style={{ backgroundColor: props.gameBoxDivider }}
							></div>
							<div
								className="game-box-progress"
								style={{
									color: props.gameBoxDivider,
								}}
							>
								<div
									className="progress-done"
									style={{
										color: props.gameBoxTopRowBkg,
										background: props.gameBoxDivider,
										// background: `linear-gradient(0, ${props.gameBoxDivider},  ${props.gameBoxTopRowBkg})`,
										width: (100 * (nrOfScenes - 1)) / maxNrOfScenes + "%",
									}}
								></div>
								<div
									className="progress-not-done"
									style={{
										background: props.gameBoxTopRowBkg,
										// background: `linear-gradient(0, ${props.gameBoxDivider},  ${props.gameBoxTopRowBkg})`,
									}}
								></div>
							</div>
						</div>
					</div>
					<div id="overlay" className="visible-overlay active-overlay">
						{/* <LoadingBubbles /> */}
					</div>
					{level !== -1 && scenesInfo.length
						? React.cloneElement(props.children, {
								...props.children.props,
								nrOfScenes: nrOfScenes,
								setNrOfScenes: setNrOfScenes,
								level: level,
								scenesInfo: scenesInfo,
								gameDescription: gameInfo.gameDescription,
						  })
						: ""}
				</div>
			)}
		</div>
	);
}
