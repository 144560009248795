import React from "react";
import MatematicaTemplate from "../../../components/modules/matematica/MatematicaTemplate";
import TutorialComponent from "../../../components/modules/tutorial/TutorialComponent";

export default function Tutorial(props) {
	const maxNrOfScenes = 3;
	//!! De luat din dbtest
	const gameId = "61e9c166bcafd0085f1c2165";
	//! TO DO: REQUEST CATRE BACKEND CARE SA INTOARCA LEVEL PENTRU JOCUL CURENT

	return (
		<MatematicaTemplate gameNumber={1}>
			<TutorialComponent gameId={gameId} maxNrOfScenes={maxNrOfScenes} />
		</MatematicaTemplate>
	);
}
