import { getRndInteger, hideOverlay, overlayVisible } from "./helpers";
import { TEXT, lan } from "./text";

export function getModuleGameInfo(moduleName) {
	if (moduleName === "Atentie") {
		return {
			active: true,
			moduleName: {
				ro: "Atenție",
				eng: "Focus",
			},
			moduleIcon: require("../assets/icons_modules/atentie.svg").default,
			description: {
				ro: "Atenția este un mecanism de focalizare, orientare și fixare a activității psihice asupra unuia sau a mai multor obiecte, sarcini, întrebări, etc.",
				eng: "Attention is a mechanism of focusing, orienting, and fixing psychological activity on one or more objects, tasks, questions, etc.",
			},
			backgroundImage:
				require("../assets/games_icons/atentie/atentie_games_view.svg").default,
			backgroundColor: "#3DDC80",
			borderColor: "#32A47E",
			color: "white",
			gamesRoute: "games/atentie",
			gamesInfo: [
				{
					gameId: "5fb9d308fae22d423cdec56c",
					gameDescription: {
						ro: "Privește cu atenție și selectează obiectul identic.",
						eng: "Look carefully and select the identical object.",
					},
					gameDescriptionMore: {
						ro: "În cadrul acestui joc copiii își pot dezvolta mecanismele de focalizare, fixare și concentrare asupra unui obiect. Copilului i se vor prezenta mai multe imagini. Imaginea din cadran va reprezenta modelul, iar în partea de jos vor fi prezentate alte imagini printre care și cea din cadran. Sarcina copilului este de a selecta imaginea identică.",
						eng: "In this game, children can develop their mechanisms of focusing, fixation, and concentration on an object. Several images will be presented to the child, with the image in the center representing the model, and other images displayed at the bottom, including the one in the center. The child's task is to select the identical image.",
					},
					gameIcon: {
						ro: require("../assets/games_icons/atentie/joc_identic.svg")
							.default,
						eng: require("../assets/games_icons/atentie/joc_identic.svg")
							.default,
					},
					gameTitle: {
						ro: "Joc identic",
						eng: "Identical game",
					},
					route: "/games/atentie/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								numberOfImages: 3,
								uniqueImages: true,
							},
							time: 10,
							levelIcon:
								require("../assets/games_icons/atentie/joc_identic_nivele/joc_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								numberOfImages: 4,
								uniqueImages: true,
							},
							time: 10,
							levelIcon:
								require("../assets/games_icons/atentie/joc_identic_nivele/joc_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								numberOfImages: 4,
								uniqueImages: true,
							},
							time: 5,
							levelIcon:
								require("../assets/games_icons/atentie/joc_identic_nivele/joc_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a91f6fa4d6d024f2f80e7f",
					gameDescription: {
						ro: "Privește cu atenție copiii din vagoane și selectează conform cerinței.",
						eng: "Look carefully at the children in the carriages and select according to the requirement.",
					},
					gameDescriptionMore: {
						ro: "În cadrul acestui joc copiii își pot dezvolta mecanismele de focalizare, fixare și concentrare asupra unui obiect sau sarcini. De asemenea, atenția presupune dinamicitate în timp, iar acest joc implică și această caracteristică a atenției. Copilului i se va arăta un tren în mișcare cu mai multe vagoane în care se vor afla copii cu diverse caracteristici. Sarcina jocului este de a selecta copiii în funcție de cerința prezentată în chenar.",
						eng: "In this game, children can develop their mechanisms of focusing, fixation, and concentration on an object or task. Additionally, attention involves dynamism over time, and this game also involves this characteristic of attention. The child will be shown a moving train with several carriages containing children with various characteristics. The task of the game is to select the children according to the requirement presented in the frame.",
					},
					gameIcon: require("../assets/games_icons/atentie/trenuletul.svg")
						.default,
					gameTitle: {
						ro: "Trenulețul",
						eng: "The little train",
					},
					route: "/games/atentie/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								uniqueImages: true,
							},
							time: 20,
							maxNrOfHats: 2,
							typeOfAnswer: "with hat",
							instructions: {
								ro: "Selectează copiii care au capul acoperit.",
								eng: "Select the children who have their heads covered.",
							},
							levelIcon:
								require("../assets/games_icons/atentie/trenulet_nivele/trenuletul_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								uniqueImages: true,
							},
							time: 15,
							maxNrOfHats: 3,
							typeOfAnswer: "with hat",
							instructions: {
								ro: "Selectează copiii care au capul acoperit.",
								eng: "Select the children who have their heads covered.",
							},
							levelIcon:
								require("../assets/games_icons/atentie/trenulet_nivele/trenuletul_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								uniqueImages: true,
							},
							time: 10,
							maxNrOfHats: 3,
							typeOfAnswer: "without hat",
							instructions: {
								ro: "Selectează copiii care nu au capul acoperit.",
								eng: "Select the children who do not have their heads covered.",
							},
							levelIcon:
								require("../assets/games_icons/atentie/trenulet_nivele/trenuletul_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "5fb9d33dfae22d423cdec56f",
					gameDescription: {
						ro: "Citește cu atenție cerința și alege culoarea conform acesteia.",
						eng: "Read the requirement carefully and choose the color according to it.",
					},
					gameDescriptionMore: {
						ro: "În cadrul acestui joc copiii își pot dezvolta mecanismele de focalizare, fixare și concentrare asupra unui obiect sau sarcini. În fața copilului vor apărea patru chenare colorate. Chenarul central va avea scrisă și o culoare, diferită de cea a chenarului. Copilul va avea sarcina de a citi cu atenție cerința și de a alege conform acesteia, fie culoarea aflată în chenarul central, fie culoarea corespunzătoare textului aflat în chenarul central.",
						eng: "In this game, children can develop their mechanisms of focusing, fixation, and concentration on an object or task. Four colored boxes will appear in front of the child. The central box will have a color written on it, different from the color of the box. The child will be tasked with reading the requirement carefully and choosing, according to it, either the color in the central box or the color corresponding to the text in the central box.",
					},
					gameDescription1: "Alege culoarea pe care o vezi.",
					gameDescription2: "Alege culoarea corespunzătoare textului citit.",
					gameIcon: require("../assets/games_icons/atentie/culori.svg").default,
					gameTitle: {
						ro: "Culorile",
						eng: "Colors",
					},
					route: "/games/atentie/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							questionText: {
								ro: "Alege culoarea văzută.",
								eng: "Choose the color that you see.",
							},
							answerType: "color",
							body: { answerType: "color", colorsNeeded: 3 },
							levelIcon: {
								ro: require("../assets/games_icons/atentie/culori_nivele/culori_1.svg")
									.default,
								eng: require("../assets/games_icons/atentie/culori_nivele/colors_1.svg")
									.default,
							},
						},
						2: {
							questionText: {
								ro: "Alege culoarea citită.",
								eng: "Choose the color you read.",
							},
							answerType: "text",
							body: { answerType: "text", colorsNeeded: 3 },
							levelIcon: {
								ro: require("../assets/games_icons/atentie/culori_nivele/culori_2.svg")
									.default,
								eng: require("../assets/games_icons/atentie/culori_nivele/colors_2.svg")
									.default,
							},
						},
						3: [
							{
								questionText: {
									ro: "Alege culoarea văzută.",
									eng: "Choose the color you see.",
								},
								answerType: "color",
								body: { answerType: "color", colorsNeeded: 3 },
								levelIcon: {
									ro: require("../assets/games_icons/atentie/culori_nivele/culori_3.svg")
										.default,
									eng: require("../assets/games_icons/atentie/culori_nivele/colors_3.svg")
										.default,
								},
							},
							{
								questionText: {
									ro: "Alege culoarea citită.",
									eng: "Choose the color you read.",
								},
								answerType: "text",
								body: { answerType: "text", colorsNeeded: 3 },
								levelIcon:
									require("../assets/games_icons/atentie/culori_nivele/culori_3.svg")
										.default,
							},
						][getRndInteger(0, 1)],
					},
				},
				{
					gameId: "61a91f86a4d6d024f2f80e80",
					gameDescription: {
						ro: "Numără de câte ori apare imaginea de mai jos în chenarul alăturat și selectează varianta corectă.",
						eng: "Count how many times the image below appears in the adjacent box and select the correct option.",
					},
					gameDescriptionMore: {
						ro: "În cadrul acestui joc, copiii își pot dezvolta mecanismele de focalizare, fixare și concentrare asupra unui obiect sau sarcini. În dreapta ecranului va fi prezentat un chenar cu diferite obiecte. În partea stângă va fi indicat acel obiect pe care copilul va trebui să îl numere în chenarul din dreapta. Sarcina copilului este de a selecta rezultatul pe care acesta îl consideră corect.",
						eng: "In this game, children can develop their mechanisms of focusing, fixation, and concentration on an object or task. On the right side of the screen, a box with different objects will be presented. On the left side, the object that the child needs to count in the box on the right will be indicated. The task of the child is to select the result that they consider to be correct.",
					},
					gameIcon:
						require("../assets/games_icons/atentie/cate_obiecte_sunt.svg")
							.default,
					gameTitle: {
						ro: "Câte obiecte sunt?",
						eng: "How many objects are there?",
					},
					route: "/games/atentie/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							nrOfOptions: 2,
							nrOfImages: 10,
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								multiplyDistribution: true,
								numberOfImages: 2,
								numberOfAnswers: 1,
							},
							levelIcon:
								require("../assets/games_icons/atentie/cate_obiecte_nivele/cate_obiecte_1.svg")
									.default,
						},
						2: {
							nrOfOptions: 3,
							nrOfImages: 10,
							time: 10,
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								multiplyDistribution: true,
								numberOfImages: 3,
								numberOfAnswers: 1,
							},
							levelIcon:
								require("../assets/games_icons/atentie/cate_obiecte_nivele/cate_obiecte_2.svg")
									.default,
						},
						3: {
							nrOfOptions: 3,
							nrOfImages: 10,
							time: 30,
							body: {
								separateSubcategories: false,
								uniqueImages: true,
								uniqueSubcategories: false,
								needAnswer: false,
								needSeparateAnswer: false,
								level: 3,
								multiplyDistribution: true,
								numberOfImages: 3,
								numberOfAnswers: 1,
							},
							levelIcon:
								require("../assets/games_icons/atentie/cate_obiecte_nivele/cate_obiecte_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a91f95a4d6d024f2f80e81",
					gameDescription: {
						ro: "Privește cu atenție întregul chenar și alege exact modelul pe care îl vezi.",
						eng: "Look carefully at the entire box and choose the exact pattern that you see.",
					},
					gameDescriptionMore: {
						ro: "În cadrul acestui joc copiii își pot dezvolta mecanismele de focalizare, fixare și concentrare asupra unui obiect sau sarcini. În partea stângă a ecranului, Wiki prezintă un model format din patru obiecte alăturate. În partea dreaptă a ecranului va fi afișat un chenar cu mai multe obiecte. Sarcina copilului este să selecteze exact modelul prezentat de Wiki.",
						eng: "In this game, children can develop their mechanisms of focus, fixation, and concentration on an object or task. On the left side of the screen, Wiki presents a model consisting of four objects next to each other. On the right side of the screen, a frame with multiple objects will be displayed. The child's task is to select the exact model presented by Wiki.",
					},
					gameIcon: require("../assets/games_icons/atentie/gaseste_model.svg")
						.default,
					gameTitle: {
						ro: "Găsește modelul",
						eng: "Find the pattern",
					},
					route: "/games/atentie/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								numberOfImages: 25,
								numberOfAnswers: 1,
							},
							time: null,
							rows: 5,
							cols: 5,
							matrixConfig: [
								[1, 1],
								[1, 1],
							],
							answerRows: 2,
							answerCols: 2,
							levelIcon:
								require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								numberOfImages: 25,
								numberOfAnswers: 1,
							},
							rows: 5,
							cols: 5,
							matrixConfig: [
								[1, 1, 1],
								[1, 1, 1],
								[1, 1, 1],
							],
							answerRows: 3,
							answerCols: 3,
							time: null,
							levelIcon:
								require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_2.svg")
									.default,
						},
						3: [
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[1, 1, 1],
									[0, 1, 0],
									[0, 1, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 0],
									[0, 1, 0],
									[1, 1, 1],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 0],
									[1, 1, 1],
									[0, 1, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 0],
									[0, 1, 0],
									[0, 1, 1],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 1],
									[0, 1, 0],
									[0, 1, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 0],
									[0, 1, 1],
									[0, 1, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 0, 0],
									[1, 1, 1],
									[0, 1, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
							{
								body: {
									separateSubcategories: true,
									uniqueImages: false,
									numberOfImages: 25,
									numberOfAnswers: 1,
								},

								rows: 5,
								cols: 5,
								matrixConfig: [
									[0, 1, 0],
									[1, 1, 1],
									[0, 0, 0],
								],
								answerRows: 3,
								answerCols: 3,
								levelIcon:
									require("../assets/games_icons/atentie/gaseste_model_nivele/gaseste_model_3.svg")
										.default,
							},
						][getRndInteger(0, 7)],
					},
				}, // {
				// 	gameId: "61a91f95a4d6d024f2f80e82", // RECHECK
				// 	gameDescription: "Găsește drumul corect și unește cele 2 obiecte.",
				// 	gameDescriptionMore: "",
				// 	gameIcon: require("../assets/games_icons/atentie/labirint.svg")
				// 		.default,
				// 	gameTitle: "Labirint",
				// 	route: "/games/atentie/game6",
				// 	level: 1,
				// 	score: 101,
				// },
			],
		};
	} else if (moduleName === "Limbaj") {
		return {
			active: lan == "ro",
			moduleName: {
				ro: "Limbaj",
				eng: "Language",
			},
			moduleIcon: require("../assets/icons_modules/limbaj.svg").default,
			description: {
				ro: "Limbajul are un caracter social, dar pe lângă acesta limbajul este și un instrument al gândirii, obiectivizării și exteriorizării ideilor.",
				eng: "Language has a social character, but besides this, language is also a tool of thinking, objectification, and externalization of ideas.",
			},
			backgroundImage:
				require("../assets/games_icons/limbaj/limbaj_games_view.svg").default,
			backgroundColor: "#a788ff",
			borderColor: "#2f00b3",
			color: "white",
			gamesRoute: "games/limbaj",
			gamesInfo: [
				{
					gameId: "60a6a7f7a87cac8e40ae5347",
					gameDescription: {
						ro: "Selectează imaginea care rimează cu obiectul prezentat.",
						eng: "Select the image that rhymes with the presented object.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la îmbunătățirea limbajului, atenției și concentrării. I se prezintă copilului mai multe imagini. În partea de sus va vizualiza un obiect iar în partea de jos va vedea alte imagini. Copilul va avea de selectat care dintre cele imaginile de jos rimează cu obiectul prezentat în partea de sus. ",
						eng: "This game helps improve language skills, attention, and concentration. The child is presented with several images. In the upper part, they will see an object, while in the lower part, they will see other images. The child will have to select which of the images at the bottom rhymes with the object presented at the top.",
					},
					gameIcon: require("../assets/games_icons/limbaj/rime.svg").default,
					gameTitle: {
						ro: "Rime",
						eng: "Rhymes",
					},
					route: "/games/limbaj/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 6,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							options: 3,
							levelIcon:
								require("../assets/games_icons/limbaj/rime_nivele/rime_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 8,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							options: 4,
							levelIcon:
								require("../assets/games_icons/limbaj/rime_nivele/rime_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 10,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							options: 5,
							levelIcon:
								require("../assets/games_icons/limbaj/rime_nivele/rime_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61fa7b79927ed384eb3838ca",
					gameDescription: {
						ro: "Alege cele 2 imagini care rimează",
						eng: "Choose the 2 images that rhyme.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea vocabularului, îmbunătățirii atenției și concentrării. Sarcina copilului este de a selecta, pe rând, perechile de obiecte care rimează.",
						eng: "This game helps to develop vocabulary and improve attention and concentration. The child's task is to select, one by one, the pairs of objects that rhyme.",
					},
					gameIcon:
						require("../assets/games_icons/limbaj/perechi_de_rime_2.svg")
							.default,
					gameTitle: {
						ro: "Perechi de rime",
						eng: "Pairs of rhymes",
					},
					route: "/games/limbaj/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 4,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							rows: 2,
							columns: 2,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 6,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							rows: 2,
							columns: 3,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 8,
								uniqueSubcategories: true,
								uniqueImages: true,
								pairFromSubcategory: true,
							},
							rows: 2,
							columns: 4,
						},
					},
				},
				{
					gameId: "61fa7cf1927ed384eb3838cb",
					gameDescription: {
						ro: "Selectează perechile de imagini care au același sunet inițial.",
						eng: "Select the pairs of images that have the same initial sound.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la formarea deprinderilor de pronunție. De asemenea, dezvoltă atenția și concentrarea. În fața copilului, în cadrul primului nivel, vor fi prezentate patru imagini diferite. Copilul va avea de selectat pe rând, câte două imagini care au același sunet inițial.",
						eng: "This game helps to develop pronunciation skills. It also improves attention and concentration. In front of the child, in the first level, four different images will be presented. The child will have to select, in turn, two images that have the same initial sound.",
					},
					gameIcon: require("../assets/games_icons/limbaj/sunet_initial.svg")
						.default,
					gameTitle: {
						ro: "Sunetul inițial",
						eng: "Initial sound",
					},
					route: "/games/limbaj/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 4,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 2,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 6,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 3,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 8,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 4,
						},
					},
				},
				{
					gameId: "61fa7d04927ed384eb3838cc",
					gameDescription: {
						ro: "Selectează perechile de imagini care au același sunet final.",
						eng: "Select the pairs of images that have the same final sound.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la formarea deprinderilor de pronunție. De asemenea dezvoltă atenția, concentrarea și familiarizează copiii cu sunetele. În fața copilului, în cadrul primului nivel, vor fi prezentate patru imagini diferite. Copilul va avea de selectat pe rând, câte două imagini care au același sunet final.",
						eng: "This game helps to develop pronunciation skills. Additionally, it enhances attention and concentration, and familiarizes children with sounds. In front of the child, in the first level, four different images will be presented. The child will have to select, one by one, two images that have the same final sound.",
					},
					gameIcon: require("../assets/games_icons/limbaj/sunet_final.svg")
						.default,
					gameTitle: {
						ro: "Sunetul final",
						eng: "Final sound",
					},
					route: "/games/limbaj/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 4,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 2,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 6,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 3,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 8,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 4,
						},
					},
				},
				{
					gameId: "61fa7d41927ed384eb3838cd",
					gameDescription: {
						ro: "Alege cifra care reprezintă câte silabe are imaginea prezentă.",
						eng: "Choose the number that represents how many syllables the presented image has.",
					},
					gameDescriptionMore: {
						ro: "Împărțirea cuvântului în silabe reprezintă o etapă în învățarea cititului. În fața copilului va fi prezentată o imagine în partea de sus a ecranului, iar în partea de jos vor fi trei cifre. După ce copilul va despărți cuvântul în silabe, va selecta cifra pe care o consideră a fi corectă.",
						eng: "Dividing the word into syllables is an important step in learning to read. In front of the child, an image will be presented at the top of the screen, and at the bottom there will be three numbers. After the child divides the word into syllables, they will select the number they believe to be correct.",
					},
					gameIcon: require("../assets/games_icons/limbaj/silabe.svg").default,
					gameTitle: {
						ro: "Silabe",
						eng: "Syllables",
					},
					route: "/games/limbaj/game5",
					availableLevels: 3,
				},
			],
		};
	} else if (moduleName === "Emotii") {
		return {
			active: true,
			moduleName: {
				ro: "Emoții",
				eng: "Emotions",
			},
			moduleIcon: require("../assets/icons_modules/emotii.svg").default,
			description: {
				ro: "Emoțiile sunt prezente în viața noastră și ne influențează relațiile, performanțele, felul în care gândim, luăm decizii. Poate fi dificil pentru cei mici să își identifice emoțiile, de aceea au nevoie de exercițiu pentru a se putea exprima. Exerciții de genul: identificarea emoțiilor celor din jur, idenficarea și verbalizarea propriilor emoții, modalități de gestionare a acestora îi pot ajuta să se dezvolte pe plan emoțional.",
				eng: "Emotions are present in our lives and influence our relationships, performance, way of thinking, and decision making. It can be difficult for children to identify their emotions, which is why they need practice to be able to express themselves. Exercises such as identifying the emotions of those around them, identifying and verbalizing their own emotions, and ways to manage them can help them develop emotionally.",
			},
			backgroundImage:
				require("../assets/games_icons/emotii/emotii_games_view.svg").default,
			backgroundColor: "#fe5437",
			borderColor: "#a8270a",
			color: "white",
			gamesRoute: "games/emotii",
			gamesInfo: [
				{
					gameId: "61a91b55a4d6d024f2f80e7b",
					gameDescription: {
						ro: "Alege personajul care afișează emoția prezentată.",
						eng: "Choose the character that displays the presented emotion.",
					},
					gameDescriptionMore: {
						ro: "În fișa de joc vor fi prezente diferite emoții. În partea de sus se află o emoție de bază, prezentă și în cele patru emoții din partea de jos. Sarcina copilului este aceea de a selecta emoția care seamănă cu emoția de bază.",
						eng: "The game frame will display different emotions. At the top is a basic emotion, also present in the four emotions at the bottom. The child's task is to select the emotion that resembles the basic emotion.",
					},
					gameIcon: require("../assets/games_icons/emotii/potrivire.svg")
						.default,
					gameTitle: {
						ro: "Potrivire",
						eng: "Matching",
					},
					route: "/games/emotii/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: true,
								numberOfImages: 4,
							},
							time: 10,
							gameLevelDescription: {
								ro: "Alege personajul care afișează emoția prezentată.",
								eng: "Choose the character that displays the presented emotion.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/potrivire_nivele/potrivire_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 4,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 2,
							gameLevelDescription: {
								ro: "Selectează pe rând caracterele care prezintă aceeași emoție.",
								eng: "Select the characters one by one who display the same emotion.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/potrivire_nivele/potrivire_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 6,
								pairFromSubcategory: true,
								uniqueSubcategories: true,
							},
							rows: 2,
							columns: 3,
							gameLevelDescription: {
								ro: "Selectează pe rând caracterele care prezintă aceeași emoție.",
								eng: "Select the characters one by one who display the same emotion.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/potrivire_nivele/potrivire_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a91b65a4d6d024f2f80e7c",
					gameDescription: {
						ro: "Privește cu atenție perechile de emoții. Memorează-le și descoperă unde au fost poziționate.",
						eng: "Carefully look at the pairs of emotions. Memorize them and discover where they were positioned.",
					},
					gameDescriptionMore: {
						ro: "Copilul privește timp de câteva secunde chenarul cu perechile de emoții. Cardurile cu emoții se vor întoarce, sarcina copilului fiind de a-și reaminti locul în care acestea erau poziționate. Dacă descoperă perechea, cardurile vor rămâne la vedere, altfel, acestea se vor întoarce, lăsându-i copilului posibilitatea de a încerca din nou.",
						eng: "The child looks at the frame with pairs of emotions for a few seconds. The emotion cards will be turned over, and the child's task is to remember where they were positioned. If they discover a match, the cards will remain visible, otherwise, they will be turned over, allowing the child to try again.",
					},
					gameIcon: require("../assets/games_icons/emotii/memo_emotii.svg")
						.default,
					gameTitle: {
						ro: "Memo-Emoții",
						eng: "Memo-Emotions",
					},
					route: "/games/emotii/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							memoTime: 2000,
							body: {
								uniqueSubcategories: true,
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: false,
								numberOfImages: 4,
								needDuplications: true,
							},
							columns: 2,
							rows: 2,
						},
						2: {
							memoTime: 5000,
							body: {
								uniqueSubcategories: false,
								separateSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: true,
								numberOfImages: 6,
								needDuplications: true,
							},
							columns: 3,
							rows: 2,
						},
						3: {
							memoTime: 5000,
							body: {
								uniqueSubcategories: false,
								separateSubcategories: true,
								uniqueImages: true,
								numberOfImages: 8,
								needDuplications: true,
							},
							columns: 4,
							rows: 2,
						},
					},
				},
				{
					gameId: "61a91b72a4d6d024f2f80e7d",
					gameDescription: {
						ro: "Selectează caracterele care exprimă emoția indicată. ",
						eng: "Select the characters that express the indicated emotion.",
					},
					gameDescriptionMore: {
						ro: "Copilului i se va arăta un tren în mișcare cu mai multe vagoane în care se vor afla caractere cu diverse emoții. Sarcina copilului este de a selecta caracterele în funcție de emoția indicată în cerința din chenar.",
						eng: "The child will be shown a moving train with several wagons containing characters with various emotions. The child's task is to select the characters based on the emotion indicated in the prompt in the box.",
					},
					gameDescription1: "Selectează caracterele care sunt fericite.",
					gameDescription2: "Selectează caracterele care sunt furioase.",
					gameDescription3: "Selectează caracterele care sunt triste.",
					gameDescription4: "Selectează caracterele care sunt speriate.",
					gameIcon:
						require("../assets/games_icons/emotii/atentie_la_emotii.svg")
							.default,
					gameTitle: {
						ro: "Atenție la emoții",
						eng: "Attention to emotions.",
					},
					route: "/games/emotii/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								filterSubcategories: "furios,fericit",
								uniqueImages: true,
							},
							levelIcon:
								require("../assets/games_icons/emotii/atentie_nivele/atentie_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								filterSubcategories: "trist,speriat",
								uniqueImages: true,
							},
							levelIcon:
								require("../assets/games_icons/emotii/atentie_nivele/atentie_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: true,
								numberOfImages: 5,
								filterSubcategories: "trist,fericit,furios,speriat",
								uniqueImages: true,
							},
							levelIcon:
								require("../assets/games_icons/emotii/atentie_nivele/atentie_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a91b7ea4d6d024f2f80e7e",
					gameDescription: {
						ro: "Realizează imaginea completă.",
						eng: "Assemble the image.",
					},
					gameDescriptionMore: {
						ro: "Jocul de puzzle dezvoltă coordonarea și orientarea în spațiu, a atenției, a răbdării. De asemenea ajută la antrenarea capacității de a vedea o sarcina din mai multe unghiuri. Copilului i se vor afișa piesele unui puzzle reprezentând un caracter cu o anumită emoție. Sarcina copilului este de a uni piesele puse la dispoziție pentru a forma un întreg.",
						eng: "Playing puzzles develops coordination and spatial orientation, attention, and patience. It also helps to train the ability to see a task from multiple angles. The child will be presented with puzzle pieces representing a character with a certain emotion. The child's task is to connect the pieces provided to form a whole.",
					},
					gameIcon:
						require("../assets/games_icons/emotii/puzzle_cu_emotii_2.svg")
							.default,
					gameTitle: {
						ro: "Puzzle cu emoții",
						eng: "Puzzle with emotions",
					},
					route: "/games/emotii/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: true,
								numberOfImages: 2,
								level: 1,
							},
							rows: 2,
							cols: 2,
							fixed: [],
							instructions: {
								ro: "Finalizează puzzle-ul, iar apoi alege varianta care exprimă emoția personajului creat.",
								eng: "Complete the puzzle, and then choose the option that expresses the emotion of the character created.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/puzzle_nivele/puzzle_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: true,
								numberOfImages: 1,
								level: 2,
							},
							instructions: {
								ro: "Construiește personajul folosind trăsăturile corespunzătoare cerinței.",
								eng: "Build the character using the appropriate features for the given task.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/puzzle_nivele/puzzle_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueSubcategories: true,
								uniqueImages: true,
								needSeparateAnswer: true,
								numberOfImages: 1,
								level: 3,
							},
							rows: 3,
							cols: 3,
							fixed: [],
							instructions: {
								ro: "Construiește personajul folosind trăsăturile corespunzătoare cerinței.",
								eng: "Build the character using the features corresponding to the requirement.",
							},
							levelIcon:
								require("../assets/games_icons/emotii/puzzle_nivele/puzzle_3.svg")
									.default,
						},
					},
				},
			],
		};
	} else if (moduleName === "Matematica") {
		return {
			active: true,
			moduleName: {
				ro: "Matematică",
				eng: "Mathematics",
			},
			moduleIcon: require("../assets/icons_modules/matematica.svg").default,
			description: {
				ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme.",
				eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking contributes to the development of qualities such as: creativity, critical thinking, spatial visualization, and problem-solving ability.",
			},
			backgroundImage:
				require("../assets/games_icons/matematica/matematica_games_view.svg")
					.default,
			backgroundColor: "#F2E2CE",
			borderColor: "#3D2C40",
			color: "#132459",
			gamesRoute: "games/matematica",
			gamesInfo: [
				{
					gameId: "61e9c166bcafd0085f1c2165",
					gameDescription: {
						ro: "Aranjează obiectele prezentate în ordinea indicată.",
						eng: "Arrange the objects presented in the indicated order.",
					},
					gameDescriptionMore: {
						ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme, dezvoltarea capacităților de analiză și sinteză. De asemenea jocurile de matematică ajută la antrenarea atenției, concentrării și memoriei. Copilului i se va prezenta un obiect de mai multe dimensiuni. Sarcina jocului este de a așeza obiectele în ordinea indicată.",
						eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking contributes to the development of qualities such as creativity, critical thinking, spatial visualization, problem-solving ability, as well as analytical and synthetic skills. Additionally, math games help train attention, concentration, and memory. The child will be presented with a multi-dimensional object. The task of the game is to arrange the objects in the indicated order.",
					},
					gameDescription1: "Ordonează de la cel mai mic la cel mai mare.",
					gameDescription2: "Ordonează de la cel mai mare la cel mai mic.",
					gameIcon: require("../assets/games_icons/matematica/ordonare.svg")
						.default,
					gameTitle: {
						ro: "Ordonare",
						eng: "Order",
					},
					route: "/games/matematica/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 1,
								numberOfAnswers: 1,
							},
							columns: 3,
							order: 1,
							levelIcon:
								require("../assets/games_icons/matematica/ordonare_nivele/ordonare_1.svg")
									.default,
							gameLevelDescriptionOptions: {
								0: {
									ro: "Aranjează imaginile de la mare la mic.",
									eng: "Arrange the images from large to small.",
								},
								1: {
									ro: "Aranjează imaginile de la mic la mare.",
									eng: "Arrange the images from small to large.",
								},
							},
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 1,
								numberOfAnswers: 1,
							},
							columns: 3,
							order: 0,
							levelIcon:
								require("../assets/games_icons/matematica/ordonare_nivele/ordonare_2.svg")
									.default,
							gameLevelDescriptionOptions: {
								0: {
									ro: "Aranjează imaginile de la mare la mic.",
									eng: "Arrange the images from large to small.",
								},
								1: {
									ro: "Aranjează imaginile de la mic la mare.",
									eng: "Arrange the images from small to large.",
								},
							},
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 1,
								numberOfAnswers: 1,
							},
							columns: 4,
							order: getRndInteger(0, 1),
							levelIcon:
								require("../assets/games_icons/matematica/ordonare_nivele/ordonare_3.svg")
									.default,
							gameLevelDescriptionOptions: {
								0: {
									ro: "Aranjează imaginile de la mare la mic.",
									eng: "Arrange the images from large to small.",
								},
								1: {
									ro: "Aranjează imaginile de la mic la mare.",
									eng: "Arrange the images from small to large.",
								},
							},
						},
					},
				},
				{
					gameId: "61e9c1acbcafd0085f1c216b",
					gameDescription: {
						ro: "Potrivește cifra cu mulțimea de elemente potrivite.",
						eng: "Match the number with the corresponding set of elements.",
					},
					gameDescriptionMore: {
						ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme, dezvoltarea capacităților de analiză și sinteză. De asemenea jocurile de matematică ajută la antrenarea atenției, concentrării și memoriei. În partea de sus a fișei de joc vor fi afișate cifre diferite, iar în partea de jos vor fi mulțimi cu un număr de elemente care corespund cifrelor de sus. Sarcina copilului este de a selecta cifra care corespunde fiecărei mulțimi.",
						eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking contributes to the development of qualities such as creativity, critical thinking, spatial visualization, problem-solving ability, as well as analytical and synthetic skills. Additionally, math games help train attention, concentration, and memory. At the top of the game sheet, different numbers will be displayed, and at the bottom, there will be sets with a number of elements corresponding to the numbers at the top. The child's task is to select the number that corresponds to each set.",
					},
					gameIcon: require("../assets/games_icons/matematica/potriveste.svg")
						.default,
					gameTitle: {
						ro: "Cifră cantitate",
						eng: "Number quantity",
					},
					route: "/games/matematica/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 3,
							},
							numberOfImageCards: 3,
							maxNumberOfImagesOnCard: 5,
							levelIcon:
								require("../assets/games_icons/matematica/cifra_nivele/cifra_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 3,
							},
							numberOfImageCards: 3,
							maxNumberOfImagesOnCard: 10,
							levelIcon:
								require("../assets/games_icons/matematica/cifra_nivele/cifra_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 3,
							},
							numberOfImageCards: 3,
							maxNumberOfImagesOnCard: 10,
							levelIcon:
								require("../assets/games_icons/matematica/cifra_nivele/cifra_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61e9c1b9bcafd0085f1c216c",
					gameDescription: {
						ro: "Alege mulțimea corespunzător cerinței indicate.",
						eng: "Choose the set that corresponds to the given requirement.",
					},
					gameDescriptionMore: {
						ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme, dezvoltarea capacităților de analiză și sinteză. De asemenea jocurile de matematică ajută la antrenarea atenției, concentrării și memoriei. Copilului i se vor arăta două mulțimi cu elemente diferite. Sarcina lor este aceea de a selecta mulțimea corespunzător cerinței indicate.",
						eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking helps develop qualities such as creativity, critical thinking, spatial visualization, problem-solving ability, analytical and synthetic abilities. Additionally, math games help train attention, concentration, and memory. The child will be shown two sets with different numbers of elements. Their task is to select the set that corresponds to the given requirement.",
					},
					gameIcon: require("../assets/games_icons/matematica/multimi.svg")
						.default,
					gameTitle: {
						ro: "Mulțimi",
						eng: "Groups",
					},
					route: "/games/matematica/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needAnswer: false,
								needSeparateAnswer: false,
								needDuplications: true,
								numberOfImages: 2,
							},
							numberOfImageCards: 2,
							minMax: "min",
							maxNumberOfImagesOnCard: 5,
							gameLevelDescriptionOptions: {
								min: {
									ro: "Alege mulțimea cu mai puține elemente",
									eng: "Choose the set with fewer elements.",
								},
								max: {
									ro: "Alege mulțimea cu mai multe elemente",
									eng: "Choose the set with more elements.",
								},
							},
							levelIcon:
								require("../assets/games_icons/matematica/multimi_nivele/multimi_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needAnswer: false,
								needSeparateAnswer: false,
								needDuplications: true,
								numberOfImages: 2,
							},
							numberOfImageCards: 2,
							minMax: "max",
							maxNumberOfImagesOnCard: 5,
							levelIcon:
								require("../assets/games_icons/matematica/multimi_nivele/multimi_2.svg")
									.default,
							gameLevelDescriptionOptions: {
								min: {
									ro: "Alege mulțimea cu mai puține elemente",
									eng: "Choose the set with fewer elements.",
								},
								max: {
									ro: "Alege mulțimea cu mai multe elemente",
									eng: "Choose the set with more elements.",
								},
							},
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needAnswer: false,
								needSeparateAnswer: false,
								needDuplications: true,
								numberOfImages: 2,
							},
							numberOfImageCards: 2,
							minMax: ["min", "max"][getRndInteger(0, 1)],
							maxNumberOfImagesOnCard: 10,
							levelIcon:
								require("../assets/games_icons/matematica/multimi_nivele/multimi_3.svg")
									.default,
							gameLevelDescriptionOptions: {
								min: {
									ro: "Alege mulțimea cu mai puține elemente",
									eng: "Choose the set with fewer elements.",
								},
								max: {
									ro: "Alege mulțimea cu mai multe elemente",
									eng: "Choose the set with more elements.",
								},
							},
						},
					},
				},
				{
					gameId: "61e9c1c6bcafd0085f1c216d",
					gameDescription: {
						ro: "Calculează și alege rezultatul corect.",
						eng: "Calculate and choose the correct result",
					},
					gameDescriptionMore: {
						ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme, dezvoltarea capacităților de analiză și sinteză. De asemenea jocurile de matematică ajută la antrenarea atenției, concentrării și memoriei. Pe fișa de joc se vor derula diferite calcule. În partea de jos vor fi afișate diferite variante de răspuns. Sarcina copilului este de a alege acel număr care indică rezultatul corect pentru fiecare dintre calcule.",
						eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking helps develop qualities such as creativity, critical thinking, spatial awareness, problem-solving ability, and the development of analytical and synthesis skills. Mathematics games also help train attention, concentration, and memory. The game sheet will feature different calculations. Various answer options will be displayed at the bottom. The child's task is to choose the number that indicates the correct answer for each of the calculations.",
					},
					gameIcon: require("../assets/games_icons/matematica/calcule.svg")
						.default,
					gameTitle: {
						ro: "Calcule",
						eng: "Calculation",
					},
					route: "/games/matematica/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							maxValue: 6,
							type: "sum",
							numberOfEquations: 10,
							numberOfPossibleAnswers: 5,
							numberOfOperands: 2,
							levelIcon:
								require("../assets/games_icons/matematica/calcule_nivele/calcule_1.svg")
									.default,
						},
						2: {
							maxValue: 10,
							type: "sum",
							numberOfEquations: 10,
							numberOfPossibleAnswers: 5,
							numberOfOperands: 2,
							levelIcon:
								require("../assets/games_icons/matematica/calcule_nivele/calcule_2.svg")
									.default,
						},
						3: {
							maxValue: 10,
							type: "dif",
							numberOfEquations: 10,
							numberOfPossibleAnswers: 5,
							numberOfOperands: 2,
							levelIcon:
								require("../assets/games_icons/matematica/calcule_nivele/calcule_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61e9c1d1bcafd0085f1c216e",
					gameDescription: {
						ro: "Privește imaginile, calculează și alege rezultatul corect.",
						eng: "Look at the images, calculate and choose the correct result.",
					},
					gameDescriptionMore: {
						ro: "Matematica ne ajută să aducem ordine și claritate în gândire. Gândirea matematică ajută la dezvoltarea calităților precum: creativitate, gândire critică, vedere în spațiu, abilitatea de a rezolva probleme, dezvoltarea capacităților de analiză și sinteză. De asemenea jocurile de matematică ajută la antrenarea atenției, concentrării și memoriei. Copilului i se vor arăta diverse operații cu elemente și o tastatura în partea de jos a fișei. Sarcina copilului este de a completa numărul corespunzător elementelor, iar apoi de a rezolva calculul obținut, folosindu-se de tastatură. ",
						eng: "Mathematics helps us bring order and clarity to our thinking. Mathematical thinking helps develop qualities such as creativity, critical thinking, spatial visualization, problem-solving skills, and the ability to analyze and synthesize. Additionally, math games help train attention, concentration, and memory. The child will be shown various operations with elements and a keyboard at the bottom of the sheet. The child's task is to complete the number corresponding to the elements and then solve the resulting calculation using the keyboard.",
					},
					gameIcon:
						require("../assets/games_icons/matematica/probleme_ilustrate.svg")
							.default,
					gameTitle: {
						ro: "Probleme ilustrate",
						eng: "Illustrated problems",
					},
					route: "/games/matematica/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							numberOfOperands: 2,
							maxValue: 6,
							type: "sum",
							levelIcon:
								require("../assets/games_icons/matematica/probleme_nivele/probleme_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							numberOfOperands: 2,
							maxValue: 10,
							type: "sum",
							levelIcon:
								require("../assets/games_icons/matematica/probleme_nivele/probleme_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: true,
								uniqueImages: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							numberOfOperands: 2,
							maxValue: 10,
							type: "dif",
							levelIcon:
								require("../assets/games_icons/matematica/probleme_nivele/probleme_3.svg")
									.default,
						},
					},
				},
			],
		};
	} else if (moduleName === "Memorie vizuala") {
		return {
			active: true,
			moduleName: {
				ro: "Memorie vizuală",
				eng: "Visual memory",
			},
			moduleIcon: require("../assets/icons_modules/memorieVizuala.svg").default,
			description: {
				ro: "Memoria reprezintă capacitatea de a reactiva, parțial sau total evenimentele din trecut. Cele trei procese ale memoriei sunt: memorarea, stocarea și reactualizarea. Acest mecanism sudează elementele anterioare de cele care vor urma, împingând mai departe spre cunoaștere. ",
				eng: "Memory represents the ability to partially or fully reactivate past events. The three processes of memory are encoding, storage, and retrieval. This mechanism integrates previous elements with those to come, pushing further towards knowledge.",
			},
			backgroundImage:
				require("../assets/games_icons/memorie_vizuala/memorie_vizuala_games_view.svg")
					.default,
			backgroundColor: "#6a66e3",
			borderColor: "#133170",
			color: "white",
			gamesRoute: "games/memorie_vizuala",
			gamesInfo: [
				{
					gameId: "6224ab2d18b22ec225816ad6",
					gameDescription: {
						ro: "Privește imaginile cu atenție, memorează-le și apoi alege obiectele pe care ți le amintești.",
						eng: "Observe the images carefully, memorize them, and then choose the objects that you remember.",
					},
					gameDescriptionMore: {
						ro: "Acest tip de joc dezvoltă capacitatea copilului de a înmagazina și de a-și reaminti ceea ce a perceput cu ajutorul simțului vizual. Copilul va privi o secvență cu 3 imagini. După câteva secunde, i se va prezenta o secvență cu 5 imagini printre care se vor regăsi și cele inițiale. Sarcina copilului este sa aleagă cele 3 imagini prezentate inițial.",
						eng: "This type of game develops a child's ability to store and recall what they have perceived through visual sense. The child will look at a sequence of 3 images. After a few seconds, they will be presented with a sequence of 5 images, among which the initial 3 will be included. The child's task is to choose the 3 images presented initially.",
					},
					gameIcon:
						require("../assets/games_icons/memorie_vizuala/ce_amintesti.svg")
							.default,
					gameTitle: {
						ro: "Ce îți amintești?",
						eng: "Remember?",
					},
					route: "/games/memorie_vizuala/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								numberOfAnswers: 2,
								numberOfImages: 5,
								separateSubcategories: true,
								uniqueImages: true,
								uniqueSubcategories: true,
								level: 1,
							},
							time: 10,
							cooldown: 5,
							nrOfAnswers: 2,
							numberOfImages: 5,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/ce_amintesti_nivele/ce_amintesti_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								numberOfAnswers: 3,
								numberOfImages: 5,
								separateSubcategories: true,
								uniqueImages: true,
								uniqueSubcategories: true,
								level: 2,
							},
							time: 10,
							cooldown: 5,
							nrOfAnswers: 3,
							numberOfImages: 5,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/ce_amintesti_nivele/ce_amintesti_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								numberOfAnswers: 4,
								numberOfImages: 6,
								separateSubcategories: true,
								uniqueImages: true,
								uniqueSubcategories: true,
								level: 3,
							},
							time: 10,
							cooldown: 5,
							nrOfAnswers: 4,
							numberOfImages: 6,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/ce_amintesti_nivele/ce_amintesti_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a7ea62a4d6d024f2f80e76",
					gameDescription: {
						ro: "Alege obiectul pe care l-ai văzut inițial.",
						eng: "Choose the object you saw initially.",
					},
					gameDescriptionMore: {
						ro: "Acest tip de joc dezvoltă capacitatea copilului de a înmagazina și de a-și reaminti ceea ce a perceput cu ajutorul simțului vizual. Timp de câteva secunde copilul va privi imaginea unui obiect. Apoi, imaginea dispare și i se prezintă copilului 3 variante posibile. Sarcina copilului este aceea de a alege partea din obiect care completează corect imaginea văzută inițial.",
						eng: "This type of game develops a child's ability to store and recall what they have perceived through visual sense. For a few seconds, the child will look at an image of an object. Then, the image disappears and the child is presented with 3 possible variations. The child's task is to choose the part of the object that correctly completes the initially seen image.",
					},
					gameIcon:
						require("../assets/games_icons/memorie_vizuala/desen_incomplet.svg")
							.default,
					gameTitle: {
						ro: "Desen incomplet",
						eng: "Incomplete drawing",
					},
					route: "/games/memorie_vizuala/game2",
					availableLevels: 3,

					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/des_incomplet_nivele/des_incomplet_1.svg")
									.default,
						},
						2: {
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/des_incomplet_nivele/des_incomplet_2.svg")
									.default,
						},
						3: {
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/des_incomplet_nivele/des_incomplet_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a7ea8ea4d6d024f2f80e77",
					gameDescription: {
						ro: "Privește locul în care sunt așezate mingile, memorează-le și apoi așază-le în spații așa cum îți amintești.",
						eng: "Look at the arrangement of the balls, memorize them, and then place them in the spaces as you remember.",
					},
					gameDescriptionMore: {
						ro: "Acest tip de joc dezvoltă atenția, concentrarea și capacitatea copilului de a înmagazina și de a-și reaminti ceea ce a perceput și poziția obiectelor prin intermediul simțului vizual. Timp de câteva secunde copilul va privi mingiile așezate în chenar. Copilul va avea sarcina de a așeza mingiile în pozițiile pe care și le amintește.",
						eng: "This type of game develops a child's attention, concentration, and ability to store and recall what they have perceived and the position of objects through visual sense. For a few seconds, the child will look at the balls placed in a frame. The child will have the task of placing the balls in the positions they remember.",
					},
					gameIcon:
						require("../assets/games_icons/memorie_vizuala/chenarul.svg")
							.default,
					gameTitle: {
						ro: "Chenarul",
						eng: "Frame",
					},
					route: "/games/memorie_vizuala/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								colorsNeeded: 2,
							},
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/chenar_nivele/chenar_1.svg")
									.default,
						},
						2: {
							body: {
								colorsNeeded: 3,
							},
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/chenar_nivele/chenar_2.svg")
									.default,
						},
						3: {
							body: {
								colorsNeeded: 4,
							},
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/chenar_nivele/chenar_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a7eaaea4d6d024f2f80e78",
					gameDescription: {
						ro: "Privește cu atenție perechile de obiecte. Memorează-le și descoperă unde au fost poziționate.",
						eng: "Carefully look at the pairs of objects. Memorize them and discover where they were positioned.",
					},
					gameDescriptionMore: {
						ro: "Acest tip de joc dezvoltă atenția, concentrarea și capacitatea copilului de a înmagazina și de a-și reaminti ceea ce a perceput și poziția obiectelor prin intermediul simțului vizual. Copilul privește timp de câteva secunde perechile. Obiectele se vor întoarce, sarcina copilului fiind de a-și reaminti locul în care acestea erau poziționate. Dacă descoperă perechea, obiectele vor rămâne la vedere, altfel, acestea se vor întoarce, lăsându-i copilului posibilitatea de a încerca din nou.",
						eng: "This type of game develops a child's attention, concentration, and ability to store and recall what they perceive through visual senses. The child will look at the pairs of objects for a few seconds. The objects will then be turned over and the child's task is to remember where they were positioned. If they find a pair, the objects will remain visible. Otherwise, they will be turned back over, allowing the child to try again.",
					},
					gameIcon: require("../assets/games_icons/memorie_vizuala/memo.svg")
						.default,
					gameTitle: {
						ro: "Memo",
						eng: "Memo",
					},
					route: "/games/memorie_vizuala/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							questionText: {
								ro: "Memorează perechile de obiecte apoi descoperă unde au fost poziționate",
								eng: "Memorize the pairs of objects and then discover where they have been positioned.",
							},
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 4,
								numberOfAnswers: 1,
							},
							columns: 2,
							rows: 2,
							memoTime: 2000,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/memo_nivele/memo_1.svg")
									.default,
						},
						2: {
							questionText: {
								ro: "Memorează perechile de obiecte apoi descoperă unde au fost poziționate",
								eng: "Memorize the pairs of objects and then discover where they have been positioned.",
							},
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 6,
								numberOfAnswers: 1,
							},
							columns: 3,
							rows: 2,
							memoTime: 5000,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/memo_nivele/memo_2.svg")
									.default,
						},
						3: {
							questionText: {
								ro: "Memorează perechile de obiecte apoi descoperă unde au fost poziționate",
								eng: "Memorize the pairs of objects and then discover where they have been positioned.",
							},
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								needDuplications: true,
								numberOfImages: 8,
								numberOfAnswers: 1,
							},
							columns: 4,
							rows: 2,
							memoTime: 5000,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/memo_nivele/memo_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a7eae8a4d6d024f2f80e79",
					gameDescription: {
						ro: "Privește cu atenție șirul de obiecte, memorează-l și apoi alege-l pe acela pe care ți-l amintești.",
						eng: "Carefully look at the sequence of objects, memorize it, and then choose the one you remember.",
					},
					gameDescriptionMore: {
						ro: "Acest tip de joc dezvoltă atenția, concentrarea și capacitatea copilului de a înmagazina și de a-și reaminti ceea ce a perceput și secvența de obiecte prin intermediul simțului vizual. Copilul privește câteva secunde secvența de obiecte ordonate. După aceea, îi vor fi prezentate 3 secvențe, în fiecare dintre acestea obiectele fiind ordonate diferit, printre care și cea privită inițial. Sarcina lui este de a alege secvența pe care și-o amintește.",
						eng: "This type of game develops the child's attention, concentration, and ability to store and recall what they have perceived and the sequence of objects through the sense of sight. The child looks at a sequence of ordered objects for a few seconds. After that, they will be presented with 3 sequences, each with the objects ordered differently, including the one initially viewed. Their task is to choose the sequence they remember.",
					},
					gameIcon: require("../assets/games_icons/memorie_vizuala/sirul.svg")
						.default,
					gameTitle: {
						ro: "Șirul",
						eng: "Sequence",
					},
					route: "/games/memorie_vizuala/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 2,
								numberOfAnswers: 1,
							},
							imagesPerCard: 2,
							choices: 2,
							time: 10,
							cooldown: 5,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/sirul_nivele/sirul_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 3,
								numberOfAnswers: 1,
							},
							imagesPerCard: 3,
							choices: 3,
							time: 10,
							cooldown: 5,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/sirul_nivele/sirul_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: true,
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 4,
								numberOfAnswers: 1,
							},
							imagesPerCard: 4,
							choices: 3,
							time: 10,
							cooldown: 5,
							levelIcon:
								require("../assets/games_icons/memorie_vizuala/sirul_nivele/sirul_3.svg")
									.default,
						},
					},
				},
			],
		};
	} else if (moduleName === "Perceptie") {
		return {
			active: true,
			moduleName: {
				ro: "Percepție Vizuală",
				eng: "Visual perception",
			},
			moduleIcon: require("../assets/icons_modules/perceptieVizuala.svg")
				.default,
			description: {
				ro: "Percepția vizuală reprezintă o formă superioară a cunoașterii senzoriale care asigură conștiința unității și a întregului unui obiect. Percepția vizuală este un proces complex ce include mai multe caracteristici care ajută la reproducerea unui obiect.",
				eng: "Visual perception is a higher form of sensory knowledge that ensures awareness of the unity and entirety of an object. Visual perception is a complex process that includes several characteristics that help reproduce an object.",
			},
			backgroundImage:
				require("../assets/games_icons/perceptie/perceptie_games_view.svg")
					.default,
			backgroundColor: "#38daff",
			borderColor: "#177dda",
			color: "white",
			gamesRoute: "games/perceptie",
			gamesInfo: [
				{
					gameId: "5fb96f81b8c3ee1028beba9e",
					gameDescription: {
						ro: "Alege forma care seamănă cu modelul prezentat.",
						eng: "Choose the shape that resembles the presented pattern.",
					},
					gameDescriptionMore: {
						ro: "O componentă a percepției vizuale o reprezintă închiderea vizuală. Prin acest joc copiii își pot dezvolta abilitățile de a vizualiza, recunoaște și forma un întreg, când există informații incomplete. Copilului i se va arata o fișă cu o imagine incompletă în partea de sus și 3 imagini complete în partea de jos. Sarcina copilului este de a alege imaginea care reprezintă întregul celei de mai sus.",
						eng: "Visual closure is a component of visual perception. Through this game, children can develop their ability to visualize, recognize, and form a whole when there is incomplete information. The child will be shown a sheet with an incomplete image at the top and 3 complete images at the bottom. The child's task is to choose the image that represents the whole of the one above.",
					},
					gameIcon: require("../assets/games_icons/perceptie/Ghici.svg")
						.default,
					gameTitle: {
						ro: "Ghici ce este",
						eng: "Guess What It Is",
					},
					route: "/games/perceptie/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							nrOfAnswers: 3,
							instructions: {
								ro: "Alege forma care seamănă cu modelul prezentat.",
								eng: "Choose the shape that resembles the presented pattern.",
							},
							levelIcon:
								require("../assets/games_icons/perceptie/ghici_nivele/Ghici_1.svg")
									.default,
						},
						2: {
							nrOfAnswers: 4,
							instructions: {
								ro: "Alege forma care seamănă cu modelul prezentat.",
								eng: "Choose the shape that resembles the presented pattern.",
							},
							levelIcon:
								require("../assets/games_icons/perceptie/ghici_nivele/Ghici_2.svg")
									.default,
						},
						3: {
							nrOfAnswers: 3,
							instructions: {
								ro: "Alege imaginea ascunsă.",
								eng: "Choose the hidden image.",
							},
							levelIcon:
								require("../assets/games_icons/perceptie/ghici_nivele/Ghici_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a76395a4d6d024f2f80e72",
					gameDescription: {
						ro: "Potrivește obiectul cu umbra lui.",
						eng: "Match the object with its shadow.",
					},
					gameDescriptionMore: {
						ro: "A discrimina vizual obiecte reprezintă o altă componentă importantă a percepției vizuale. Acest joc ajută la dezvoltarea abilităților de identificare și diferențiere a elementelor din mediul înconjurător. Sarcina copilului este de a potrivi fiecare imagine cu umbra corespunzătoare.",
						eng: "Visual discrimination of objects is another important component of visual perception. This game helps develop skills in identifying and differentiating elements in the environment. The task of the child is to match each image with its corresponding shadow.",
					},
					gameIcon: require("../assets/games_icons/perceptie/umbre.svg")
						.default,
					gameTitle: {
						ro: "Umbre",
						eng: "Shadows",
					},
					route: "/games/perceptie/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								uniqueSubcategories: true,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 3,
							},
							numberOfImages: 3,
							levelIcon:
								require("../assets/games_icons/perceptie/umbre_nivele/umbre_1.svg")
									.default,
						},
						2: {
							body: {
								separateSubcategories: true,
								uniqueImages: true,
								uniqueSubcategories: true,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 4,
							},
							numberOfImages: 4,
							levelIcon:
								require("../assets/games_icons/perceptie/umbre_nivele/umbre_2.svg")
									.default,
						},
						3: {
							body: {
								separateSubcategories: false,
								uniqueImages: true,
								uniqueSubcategories: false,
								needAnswer: false,
								needSeparateAnswer: false,
								numberOfImages: 4,
								level: 3,
							},
							numberOfImages: 4,
							levelIcon:
								require("../assets/games_icons/perceptie/umbre_nivele/umbre_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a763c5a4d6d024f2f80e73",
					gameDescription: {
						ro: "Unește corect două jumătăți pentru a forma un întreg.",
						eng: "Correctly join two halves to form a whole.",
					},
					gameDescriptionMore: {
						ro: "O componentă a percepției vizuale o reprezintă închiderea vizuala. Prin acest joc copiii își pot dezvolta abilitățile de a vizualiza, recunoaște și forma un întreg, când există informații incomplete. Pe fișa de joc se vor afla obiecte înjumătățite, copilul având sarcina de a alătura corect două jumătăți pentru a obține un întreg.",
						eng: "Visual closure is a component of visual perception. Through this game, children can develop their abilities to visualize, recognize and form a whole, when there is incomplete information. The game sheet will contain objects cut in half, and the child's task is to correctly join two halves to form a whole.",
					},
					gameIcon: require("../assets/games_icons/perceptie/jumatati.svg")
						.default,
					gameTitle: {
						ro: "Jumătăți",
						eng: "Halves",
					},
					route: "/games/perceptie/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								splitImage: true,
								splitImageRows: 2,
								splitImageCols: 2,
								numberOfImages: 1,
								uniqueImages: true,
								level: 1,
							},
							levelIcon:
								require("../assets/games_icons/perceptie/jumatati_nivele/jumatati_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								splitImage: true,
								splitImageRows: 2,
								splitImageCols: 2,
								numberOfImages: 2,
								uniqueImages: true,
								level: 2,
							},
							levelIcon:
								require("../assets/games_icons/perceptie/jumatati_nivele/jumatati_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								separateSubcategories: false,
								splitImage: true,
								splitImageRows: 2,
								splitImageCols: 2,
								numberOfImages: 3,
								uniqueImages: true,
								level: 3,
							},
							levelIcon:
								require("../assets/games_icons/perceptie/jumatati_nivele/jumatati_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "61a763d3a4d6d024f2f80e74",
					gameDescription: {
						ro: "Unește piesele pentru a realiza imaginea completă.",
						eng: "Connect the pieces to create the complete picture.",
					},
					gameDescriptionMore: {
						ro: "Jocul de puzzle dezvoltă coordonarea și orientarea în spațiu, a atenției, a răbdării. De asemenea ajută la antrenarea capacității de a vedea o sarcina din mai multe unghiuri. Sarcina copilului este de a uni piesele puse la dispoziție pentru a forma un întreg.",
						eng: "The puzzle game develops coordination and spatial orientation, attention, and patience. It also helps to train the ability to see a task from different angles. The child's task is to connect the pieces provided to form a complete picture.",
					},
					gameIcon: require("../assets/games_icons/perceptie/puzzle.svg")
						.default,
					gameTitle: {
						ro: "Puzzle",
						eng: "Puzzle",
					},
					route: "/games/perceptie/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 10,
							},
							rows: 2,
							cols: 2,
							fixed: [],
							levelIcon:
								require("../assets/games_icons/perceptie/puzzle_nivele/puzzle_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 10,
							},
							rows: 4,
							cols: 2,
							fixed: [
								{ i: 0, j: 0 },
								{ i: 1, j: 0 },
								{ i: 2, j: 0 },
								{ i: 3, j: 0 },
							],
							levelIcon:
								require("../assets/games_icons/perceptie/puzzle_nivele/puzzle_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: false,
								uniqueImages: true,
								numberOfImages: 10,
							},
							rows: 3,
							cols: 3,
							fixed: [],
							levelIcon:
								require("../assets/games_icons/perceptie/puzzle_nivele/puzzle_3.svg")
									.default,
						},
						// 4: {
						// 	body: {
						// 		needSeparateAnswer: false,
						// 		uniqueImages: true,
						// 		numberOfImages: 10,
						// 	},
						// 	rows: 5,
						// 	cols: 5,
						// 	fixed: [],
						// },
					},
				},
				{
					gameId: "61a7e535a4d6d024f2f80e75",
					gameDescription: {
						ro: "Numără câte obiecte sunt și alege cifra corectă.",
						eng: "Count how many objects are there and choose the correct number.",
					},
					gameDescriptionMore: {
						ro: "Percepția vizuală include distingerea vizuală dintre fundal și imagine, urmărirea unui model vizual, perceperea formei și a constantei formei. Acest tip de joc ajută la dezvoltarea acestor abilități. Copilului i se arată o imagine cu mai multe obiecte (identice) care sunt amestecate ori suprapuse. Sarcina lui este să numere câte obiecte sunt prezentate și să aleagă cifra care indică numărul lor.",
						eng: "Visual perception includes visual discrimination between background and image, following a visual pattern, perceiving shape and constant form. This type of game helps to develop these skills. The child is shown an image with several (identical) objects that are mixed or overlapped. Their task is to count how many objects are presented and choose the number that indicates their amount.",
					},
					gameIcon:
						require("../assets/games_icons/perceptie/obiecte_amestecate.svg")
							.default,
					gameTitle: {
						ro: "Obiecte Amestecate",
						eng: "Mixed Objects",
					},
					route: "/games/perceptie/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: true,
								numberOfImages: 1,
								minShuffled: 1,
								maxShuffled: 5,
								nrOfTypes: 1,
								level: 1,
							},
							nrOfOptions: 4,
							minNrOfImages: 1,
							maxNrOfImages: 5,
							nrOfTypes: 1,
							levelIcon:
								require("../assets/games_icons/perceptie/obiecte_nivele/obiecte_amestecate_1.svg")
									.default,
						},
						2: {
							body: {
								needSeparateAnswer: true,
								numberOfImages: 1,
								minShuffled: 5,
								maxShuffled: 10,
								nrOfTypes: 1,
								level: 2,
							},
							nrOfOptions: 4,
							minNrOfImages: 5,
							maxNrOfImages: 10,
							nrOfTypes: 1,
							levelIcon:
								require("../assets/games_icons/perceptie/obiecte_nivele/obiecte_amestecate_2.svg")
									.default,
						},
						3: {
							body: {
								needSeparateAnswer: true,
								numberOfImages: 1,
								minShuffled: 1,
								maxShuffled: 10,
								nrOfTypes: 2,
								level: 3,
							},
							nrOfOptions: 4,
							minNrOfImages: 2,
							maxNrOfImages: 7,
							nrOfTypes: 2,
							levelIcon:
								require("../assets/games_icons/perceptie/obiecte_nivele/obiecte_amestecate_3.svg")
									.default,
						},
					},
				},
			],
		};
	} else if (moduleName === "Abilitati vizuo-spatiale") {
		return {
			active: true,
			moduleName: {
				ro: "Abilități vizuo-spațiale",
				eng: "Visual-Spatial Abilities",
			},
			moduleIcon: require("../assets/icons_modules/cuburi.svg").default,
			description: {
				ro: "Abilitatea de a sintetiza informații dintr-un întreg cu sens și de a repeta mintal, abilitatea de a înțelege relațiile existente între obiecte, de a reproduce sau copia un model, de a rezolva probleme nonverbale.",
				eng: "The ability to synthesize information from a meaningful whole and mentally repeat it, the ability to understand the relationships between objects, to reproduce or copy a model, to solve nonverbal problems.",
			},
			backgroundImage:
				require("../assets/games_icons/abilitati_vizuo_spatiale/abilitati_vizuo_spatiale_games_view.svg")
					.default,
			backgroundColor: "#FEE238",
			borderColor: "#EF9105",
			color: "#132459",
			gamesRoute: "games/abilitati_vizuo_spatiale",
			gamesInfo: [
				{
					gameId: "6212be2fddeba573d9072610",
					gameDescription: {
						ro: "Privește modelul și realizează unul la fel.",
						eng: "Look at the model and make one just like it.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea atenției, concentrării, a orientării spațiale, a abilității de a copia un model și de a-l reproduce. Copilul privește imaginea în stânga, iar în partea dreapta va aranja cuburile așa cum le vede în imagine.",
						eng: "This game helps develop attention, concentration, spatial orientation, and the ability to copy and reproduce a model. The child looks at the image on the left, and on the right, they arrange the cubes as they see them in the image.",
					},
					gameIcon:
						require("../assets/games_icons/abilitati_vizuo_spatiale/imitatie.svg")
							.default,
					gameTitle: {
						ro: "Imitație",
						eng: "Imitation",
					},
					route: "/games/abilitati_vizuo_spatiale/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							minNrOfCubes: 2,
							maxNrOfCubes: 3,
							maxHeight: 1,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/imitatie_nivele/imitatie_nivel_1.svg")
									.default,
							gameLevelQuestion: {
								ro: "Poți așeza cuburile astfel?",
								eng: "Can you arrange the cubes like this?",
							},
						},
						2: {
							minNrOfCubes: 4,
							maxNrOfCubes: 6,
							maxHeight: 4,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/imitatie_nivele/imitatie_nivel_2.svg")
									.default,
							gameLevelQuestion: {
								ro: "Poți așeza cuburile astfel?",
								eng: "Can you arrange the cubes like this?",
							},
						},
						3: {
							minNrOfCubes: 2,
							maxNrOfCubes: 6,
							maxHeight: 4,
							time: 30,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/imitatie_nivele/imitatie_nivel_3.svg")
									.default,
							gameLevelQuestion: {
								ro: "Poți așeza cuburile astfel?",
								eng: "Can you arrange the cubes like this?",
							},
						},
					},
				},
				{
					gameId: "6212be56ddeba573d9072611",
					gameDescription: {
						ro: "Privește modelul din cuburi, memorează-l apoi construiește modelul așa cum ți-l amintești.",
						eng: "Look at the model made of cubes, memorize it, and then build it the way you remember it.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea abilităților copiilor de a reproduce o construcție din cuburi, la dezvoltarea atenției și concentrării. Inițial copilul va privi pentru câteva secunde o construcție din cuburi. După aceea imaginea va dispărea și copilul va construi imaginea din ce își amintește.",
						eng: "This game helps develop children's ability to reproduce a construction made of cubes, as well as attention and concentration skills. Initially, the child will look at a construction made of cubes for a few seconds. After that, the image will disappear and the child will build the image from memory.",
					},
					gameIcon:
						require("../assets/games_icons/abilitati_vizuo_spatiale/construieste.svg")
							.default,
					gameTitle: {
						ro: "Construiește",
						eng: "Build",
					},
					route: "/games/abilitati_vizuo_spatiale/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							minNrOfCubes: 2,
							maxNrOfCubes: 2,
							maxHeight: 1,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/construieste_nivele/construieste_1.svg")
									.default,
						},
						2: {
							minNrOfCubes: 3,
							maxNrOfCubes: 3,
							maxHeight: 1,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/construieste_nivele/construieste_2.svg")
									.default,
						},
						3: {
							minNrOfCubes: 2,
							maxNrOfCubes: 3,
							maxHeight: 2,
							time: 20,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/construieste_nivele/construieste_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "5fba1607350d50181cac68df",
					gameDescription: {
						ro: "Numără și alege cifra care arată câte cuburi sunt.",
						eng: "Count and select the number that shows how many cubes there are.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea abilităților de orientare în spațiu, atenție și concentrare. Pe ecran va apărea o construcție formată din mai multe cuburi. Copilul va număra câte cuburi sunt și apoi va alege cifra care reprezintă numărul de cuburi.",
						eng: "This game helps develop spatial orientation, attention, and concentration skills. A construction made up of multiple cubes will appear on the screen. The child will count how many cubes there are and then select the number that represents the amount of cubes.",
					},
					gameIcon:
						require("../assets/games_icons/abilitati_vizuo_spatiale/numara.svg")
							.default,
					gameTitle: {
						ro: "Numără",
						eng: "Count",
					},
					route: "/games/abilitati_vizuo_spatiale/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							nrOfOptions: 5,
							maxNrOfCubes: 5,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/numara_cuburi_nivele/cuburi_1.svg")
									.default,
							gameLevelQuestion: {
								ro: "Numără și selectează varianta care reprezintă numărul de cuburi.",
								eng: "Count and select the option that represents the number of cubes.",
							},
						},
						2: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							nrOfOptions: 5,
							maxNrOfCubes: 10,
							time: null,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/numara_cuburi_nivele/cuburi_2.svg")
									.default,
							gameLevelQuestion: {
								ro: "Numără și selectează varianta care reprezintă numărul de cuburi.",
								eng: "Count and select the option that represents the number of cubes.",
							},
						},
						3: {
							body: {
								needSeparateAnswer: false,
								numberOfImages: 1,
							},
							nrOfOptions: 5,
							maxNrOfCubes: 10,
							time: 10,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/numara_cuburi_nivele/cuburi_3.svg")
									.default,
							gameLevelQuestion: {
								ro: "Numără și selectează varianta care reprezintă numărul de cuburi.",
								eng: "Count and select the option that represents the number of cubes.",
							},
						},
					},
				},
				{
					gameId: "620634a515c717403fe3840f",
					gameDescription: {
						ro: "Selectează obiectele care se îndreaptă către mijlocul țintei, înainte ca acestea să ajungă în centru.",
						eng: "Select the objects that are heading towards the center of the target before they reach it.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea abilităților copiilor de a stabili orientarea unei linii sau punct. Sarcina copilului este aceea de a selecta obiectele care se îndreaptă către mijlocul țintei, înainte ca acestea să ajungă în centru.",
						eng: "This game helps develop children's ability to establish the orientation of a line or point. The task of the child is to select the objects that are heading towards the center of the target before they reach it.",
					},
					gameIcon:
						require("../assets/games_icons/abilitati_vizuo_spatiale/tinta.svg")
							.default,
					gameTitle: {
						ro: "Ținta",
						eng: "Target",
					},
					route: "/games/abilitati_vizuo_spatiale/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							nrOfImages: 2,
							nrOfCorrectImages: 1,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/tinta_nivele/tinta_1.svg")
									.default,
						},
						2: {
							nrOfImages: 3,
							nrOfCorrectImages: 1,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/tinta_nivele/tinta_2.svg")
									.default,
						},
						3: {
							nrOfImages: 3,
							nrOfCorrectImages: 2,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/tinta_nivele/tinta_3.svg")
									.default,
						},
					},
				},
				{
					gameId: "6230a36775338df9bb808581",
					gameDescription: {
						ro: "Imaginează-ți că răstorni cubul. Cum va arăta? Alege culorile după ce cubul este răsturnat",
						eng: "Imagine that you overturn the cube. How will it look? Choose the colors after the cube is overturned.",
					},
					gameDescriptionMore: {
						ro: "Acest joc ajută la dezvoltarea atenției, concentrării, gândirii logice, de a roti mintal obiectele. În fața copilului va apărea un cub cu patru părți, fiecare parte fiind colorată diferit. I se arată copilului că acel cub va fi răsturnat în partea dreaptă a ecranului iar sarcina copilului este de a alege culorile corecte după ce cubul a fost răsturnat.",
						eng: "This game helps develop attention, concentration, logical thinking, and mental rotation of objects. A cube with four differently colored sides appears in front of the child. The child is shown that the cube will be overturned to the right side of the screen, and the task is to choose the correct colors after the cube is overturned.",
					},
					gameIcon:
						require("../assets/games_icons/abilitati_vizuo_spatiale/cubul.svg")
							.default,
					gameTitle: {
						ro: "Cubul răsturnat",
						eng: "The Overturned Cube",
					},
					route: "/games/abilitati_vizuo_spatiale/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							gridTemplate: 2,
							rotationAngle: 90,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/cubul_rasturnat_nivele/cubul_1.svg")
									.default,
						},
						2: {
							gridTemplate: 3,
							rotationAngle: 90,
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/cubul_rasturnat_nivele/cubul_2.svg")
									.default,
						},
						3: {
							gridTemplate: [2, 3][getRndInteger(0, 1)],
							rotationAngle: [90, 180, -90, -180][getRndInteger(0, 3)],
							levelIcon:
								require("../assets/games_icons/abilitati_vizuo_spatiale/cubul_rasturnat_nivele/cubul_3.svg")
									.default,
						},
					},
				},
			],
		};
	} else if (moduleName === "Dezvoltarea gandirii") {
		return {
			active: true,
			moduleName: {
				ro: "Dezvoltarea gândirii",
				eng: "Thinking development",
			},
			moduleIcon: require("../assets/icons_modules/dezvoltareaGandirii.svg")
				.default,
			description: {
				ro: "Gândirea este un proces al psihicului uman definitoriu pentru cunoașterea logica, raționala facand trecerea de la particular la general si de la concret la abstract. Gândirea antrenează celelalte mecanisme psihice în realizarea procesului cunoașterii, de ordin cognitiv și afectiv motivațional.",
				eng: "Thinking is a process of the human psyche that is defining for logical, rational knowledge, making the transition from the particular to the general and from the concrete to the abstract. Thinking engages the other psychological mechanisms in the realization of the process of knowledge, both cognitive and affective-motivational.",
			},
			backgroundImage:
				require("../assets/games_icons/dezvoltarea_gandirii/dezvoltarea_gandirii_games_view.svg")
					.default,
			backgroundColor: "#06bfbf",
			borderColor: "#143373",
			color: "white",
			gamesRoute: "games/dezvoltarea_gandirii",
			gamesInfo: [
				{
					gameId: "639ad503ac408822e8da4f4e",
					gameDescription: {
						ro: "",
						eng: "",
					},
					gameDescriptionMore: {
						ro: "Jocul de Comparatie dezvolta spiritul de observatie si capacitatea de a identifica atributele comune ale unor obiecte, fiinte din mediul incojurator. Prin implicarea în jocuri și activități care încurajează comparația, copiii își pot descoperi detectivii interiori, găsind noi modele și relații pe măsură ce se joacă și învață. Obiectele pot avea trăsături asemănătoare, dar și diferite, comparatia consta tocmai în descoperirea acestora. Copilului i se arată o imagine în chenar. Sarcina lui este să compare variantele de răspuns și să selecteze conform cerinței.",
						eng: "The Comparison Game develops the spirit of observation and the ability to identify common attributes of objects and beings in the surrounding environment. By engaging in games and activities that encourage comparison, children can discover their inner detectives, finding new patterns and relationships as they play and learn. Objects can have both similar and different traits, and the comparison lies precisely in discovering them. The child is shown an image within a frame. Their task is to compare the response options and select according to the requirement.",
					},
					gameIcon:
						require("../assets/games_icons/dezvoltarea_gandirii/comparatie/comparatie_1.svg")
							.default,
					gameTitle: {
						ro: "Comparatie",
						eng: "Comparison",
					},
					route: "/games/dezvoltarea_gandirii/game1",
					availableLevels: 3,
					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/comparatie/comparatie_1.svg")
									.default,
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 6,
								pairFromSubcategory: true,
							},
							gameLevelDescription: {
								ro: "Compară obiectele și selectează-l pe cel care are trăsături comune cu obiectul din chenar.",
								eng: "Compare the objects and select the one that shares common traits with the object in the frame.",
							},
							gameLevelDescriptionOptions: {
								categorie: {
									ro: "Alege elementul care face parte din aceeasi categorie ca elementul prezentat",
									eng: "Choose the element that belongs to the same category as the presented element.",
								},
								forma: {
									ro: "Alege elementul care are aceeasi forma ca elementul prezentat",
									eng: "Choose the element that has the same shape as the presented element.",
								},
								culoare: {
									ro: "Alege elementul care are aceeasi culoare ca elementul prezentat",
									eng: "Choose the element that has the same color as the presented element.",
								},
							},
						},
						2: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/comparatie/comparatie_2.svg")
									.default,
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 8,
								pairFromSubcategory: true,
							},
							gameLevelDescription: {
								ro: "Compară obiectele și selectează-l pe cel care are trăsături comune cu obiectul din chenar.",
								eng: "Compare the objects and select the one that shares common traits with the object in the frame.",
							},
							gameLevelDescriptionOptions: {
								categorie: {
									ro: "Alege elementul care face parte din aceeasi categorie ca elementul prezentat",
									eng: "Choose the element that belongs to the same category as the presented element.",
								},
								forma: {
									ro: "Alege elementul care are aceeasi forma ca elementul prezentat",
									eng: "Choose the element that has the same shape as the presented element.",
								},
								culoare: {
									ro: "Alege elementul care are aceeasi culoare ca elementul prezentat",
									eng: "Choose the element that has the same color as the presented element.",
								},
							},
						},
						3: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/comparatie/comparatie_3.svg")
									.default,
							gameLevelDescription: {
								ro: "Compară obiectele și selectează-l pe cel care nu are trăsături comune cu restul obiectelor.",
								eng: "Compare the objects and select the one that does not have common traits with the rest of the objects.",
							},
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 4,
								pairFromSubcategory: true,
							},
							gameLevelDescriptionOptions: {
								culoare: {
									ro: "Selectează obiectul care diferă prin culoare",
									eng: "Select the object that differs by color",
								},
								forma: {
									ro: "Selectează obiectul care diferă prin formă",
									eng: "Select the object that differs by shape",
								},
								categorie: {
									ro: "Selectează obiectul care diferă prin categorie",
									eng: "Select the object that differs by category",
								},
							},
							numberOfImages: 5,
						},
					},
					imagesQuerySelector: "img",
				},
				{
					gameId: "639ad7a2ac408822e8da4f4f",
					gameDescription: {
						ro: "",
						eng: "",
					},
					gameDescriptionMore: {
						ro: "Sortarea obiectelor pe categorii este esențială pentru copii pentru a învață să facă diferențe. Alegerea și potrivirea obiectelor după atributele acestora - categorie, culoare sau formă ajută la dezvoltarea gândirii celui mic și favorizează reținerea conceptului prezentat. Jocul de categorii contribuie la dezvoltarea rapidității în gandire si la educarea capacității de a selecta obiecte conform unor criterii, de a stabili asocieri si diferențieri între obiecte. Copilului i se prezintă mai multe obiecte. Sarcina lui este să sorteze obiectele în lădițele corespunzatoare.",
						eng: "Sorting objects into categories is essential for children to learn to make distinctions. Choosing and matching objects based on their attributes - category, color, or shape - helps develop the thinking of the child and enhances their understanding of the presented concept. The category game contributes to the development of quick thinking and educates the ability to select objects according to certain criteria, establishing associations and differentiations between objects. The child is presented with multiple objects. Their task is to sort the objects into the corresponding boxes.",
					},
					gameIcon:
						require("../assets/games_icons/dezvoltarea_gandirii/categorii/categorii_1.svg")
							.default,
					gameTitle: {
						ro: "Categorii",
						eng: "Categories",
					},
					route: "/games/dezvoltarea_gandirii/game2",
					availableLevels: 3,
					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/categorii/categorii_1.svg")
									.default,
							gameLevelDescription: {
								ro: "Pune în lădiță obiectele din categoria corespunzătoare acesteia.",
								eng: "Put the objects in the corresponding box according to their category.",
							},
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 6,
								pairFromSubcategory: true,
							},
							nrOfCategories: 1,
						},
						2: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/categorii/categorii_2.svg")
									.default,
							gameLevelDescription: {
								ro: "Pune în lădițe obiectele din categoriile corespunzătoare acestora.",
								eng: "Put the objects in the corresponding boxes according to their category.",
							},
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 6,
								pairFromSubcategory: true,
							},
							nrOfCategories: 2,
						},
						3: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/categorii/categorii_3.svg")
									.default,
							gameLevelDescription: {
								ro: "Rearanjează obiectele din lădițe astfel încât fiecare lădiță sa conțină numai obiecte care corespund acesteia.",
								eng: "Rearrange the objects in the boxes so that each box contains only objects that correspond to it.",
							},
							body: {
								uniqueSubcategories: true,
								uniqueImages: true,
								numberOfImages: 6,
								pairFromSubcategory: true,
							},
							nrOfCategories: 3,
						},
					},
					imagesQuerySelector: "img",
				},
				{
					gameId: "639ad81cac408822e8da4f50",
					gameDescription: {
						ro: "Privește cu atenție șirul de obiecte, deslușește tiparul șirului și apoi alege varianta corectă.",
						eng: "Carefully observe the sequence of objects, decipher the pattern of the sequence, and then choose the correct option.",
					},
					gameDescriptionMore: {
						ro: "Raționamentul reprezintă o formă de gândire deductivă care ajută la organizarea logică a caracteristicilor observate. Jocul de raționament îi poate ajuta pe copii sa își exerseze abilitățile de raționament nonverbal. Copiii trebuie să iși folosească abilitățile de raționament pentru a înțelege regulile șirului și pentru a deduce care dintre opțiunile oferite este elementul care lipsește. Aceste jocuri dezvoltă gândirea logică a copiilor. Copilului i se arată un șir de imagini aranjate conform unui tipar. Sarcina lui este să selecteze din lista de variante imaginea care urmează în șir.",
						eng: "Reasoning represents a form of deductive thinking that helps in the logical organization of observed characteristics. The reasoning game can help children practice their nonverbal reasoning skills. Children must use their reasoning abilities to understand the rules of the sequence and deduce which of the provided options is the missing element. These games develop children's logical thinking. The child is shown a sequence of images arranged according to a pattern. Their task is to select from the list of options the image that follows in the sequence.",
					},
					gameIcon:
						require("../assets/games_icons/dezvoltarea_gandirii/rationament/rationament_1.svg")
							.default,
					gameTitle: {
						ro: "Rationament",
						eng: "Reasoning",
					},
					route: "/games/dezvoltarea_gandirii/game3",
					availableLevels: 3,
					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/rationament/rationament_1.svg")
									.default,
							body: {
								needSeparateAnswer: false,
								numberOfImages: 2,
								uniqueImages: true,
							},
							patterns: [
								{
									pattern: [0, 1, 0, 1, 0, 1, 0],
									answer: 1,
								},
								{
									pattern: [0, 1, 0, 1, 0],
									answer: 1,
								},
								{
									pattern: [0, 1, 0, 1, 0, 1],
									answer: 0,
								},
							],
						},
						2: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/rationament/rationament_2.svg")
									.default,
							body: {
								needSeparateAnswer: false,
								numberOfImages: 3,
								uniqueImages: true,
							},
							patterns: [
								{
									pattern: [0, 1, 2, 0, 1, 2],
									answer: 0,
								},
								{
									pattern: [0, 1, 2, 0, 1, 2, 0],
									answer: 1,
								},
								{
									pattern: [0, 1, 2, 0, 1, 2, 0, 1],
									answer: 2,
								},
								{
									pattern: [0, 0, 1, 1, 0, 0, 1],
									answer: 1,
								},
								{
									pattern: [0, 0, 1, 1, 0, 0, 1, 1],
									answer: 0,
								},
								{
									pattern: [0, 0, 1, 2, 0, 0, 1],
									answer: 2,
								},
							],
						},
						3: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/rationament/rationament_3.svg")
									.default,
							body: {
								needSeparateAnswer: false,
								numberOfImages: 3,
								uniqueImages: true,
							},
							patterns: [
								{
									pattern: [0, 1, 0, 2, 0, 1, 0, 2],
									answer: 0,
								},
								{
									pattern: [0, 1, 0, 2, 0, 1, 0, 2, 0],
									answer: 1,
								},
								{
									pattern: [0, 1, 0, 2, 0, 1, 0],
									answer: 2,
								},
								{
									pattern: [0, 1, 1, 2, 0, 1, 1],
									answer: 2,
								},
								{
									pattern: [0, 1, 1, 2, 0, 1, 1, 2, 0],
									answer: 1,
								},
							],
						},
					},
					imagesQuerySelector: "img",
				},
				{
					gameId: "639ad81cac408822e8da4f51",
					gameDescription: {
						ro: "",
						eng: "",
					},
					gameDescriptionMore: {
						ro: "Jocul Adecvat contribuie la dezvoltarea capacității de selecție, a spiritului de observație, urmărind consolidarea înțelegerii copiilor a contextului prezentat și descoperirea elementului neobișnuit. Jocul Adecvat îi  prezintă copilului  două imagini aproape identice una lângă alta, una nefiind corespunzătoare contextului prezentat. Sarcina copilului este sa selecteze din cele doua situații, pe cea care nu este adecvată.",
						eng: "The Suitable Game contributes to the development of the selection capacity and the spirit of observation, aiming to strengthen children's understanding of the presented context and their ability to identify the unusual element. The Suitable Game presents the child with two nearly identical images side by side, one of which does not correspond to the presented context. The child's task is to select the situation that is not suitable from the two options.",
					},
					gameIcon:
						require("../assets/games_icons/dezvoltarea_gandirii/adecvat/ce_este_gresit_1.svg")
							.default,
					gameTitle: {
						ro: "Adecvat",
						eng: "Adequate",
					},
					route: "/games/dezvoltarea_gandirii/game4",
					availableLevels: 3,
					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/adecvat/ce_este_gresit_1.svg")
									.default,
							questionText: {
								ro: "Care dintre imaginile de mai jos nu este potrivita?",
								eng: "Which of the images below is not suitable?",
							},
							gameLevelDescription: {
								ro: "Privește cele două imagini de mai jos și selectează imaginea necorespunzătoare.",
								eng: "Look at the two images below and select the image that does not correspond.",
							},
							body: {
								needSeparateAnswer: true,
								numberOfImages: 1,
								uniqueImages: true,
							},
						},
						2: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/adecvat/ce_este_gresit_2.svg")
									.default,
							gameLevelDescription: {
								ro: "Privește cu atenție imaginea afișată și selectează ce nu este potrivit. Poți folosi lupa lui Wiki pentru a arunca o privire de mai aproape.",
								eng: "Carefully examine the displayed image and select what is not suitable. You can use Wiki's magnifying glass to take a closer look.",
							},
							body: {
								numberOfImages: 10,
								uniqueImages: true,
							},
						},
						3: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/adecvat/ce_este_gresit_3.svg")
									.default,
							gameLevelDescription: {
								ro: "Privește cu atenție imaginea afișată și selectează ce nu este potrivit. Poți folosi lupa lui Wiki pentru a arunca o privire de mai aproape.",
								eng: "Carefully observe the displayed image and select what is not suitable. You can use Wiki's magnifying glass to take a closer look.",
							},
							body: {
								numberOfImages: 10,
								uniqueImages: true,
							},
						},
					},
					imagesQuerySelector: "img",
				},
				{
					gameId: "639ad860ac408822e8da4f52",
					gameDescription: {
						ro: "",
						eng: "",
					},
					gameDescriptionMore: {
						ro: "Secventialitatea logică reprezinta abilitatea pe care o folosim atunci cand descompunem un eveniment în pași simpli și apoi punem acei pași în ordine. Avem nevoie de abilități de secvențiere pentru a vorbi despre ceva ce s-a întâmplat în trecut într-o maniera logica astfel încât ceilalți să poată urmări și înțelege acțiunea. Jocul de secventialitate logica cuprinde mai multe seturi de imagini cu secvențe din diferite activități și ajuta atat la intelegerea logica a succesiunii acțiunilor în realizarea unei activități. Imaginile prezentate respecta o anumită secventialitate, iar sarcina copilului este sa le aseze în ordinea corecta.",
						eng: "Logical sequencing represents the ability we use when breaking down an event into simple steps and then putting those steps in order. We need sequencing skills to talk about something that happened in the past in a logical manner so that others can follow and understand the action. The logical sequencing game consists of multiple sets of images depicting sequences of different activities, helping to understand the logical progression of actions in carrying out an activity. The presented images adhere to a specific sequence, and the child's task is to arrange them in the correct order.",
					},
					gameIcon:
						require("../assets/games_icons/dezvoltarea_gandirii/secventialitate/secventialitate_1.svg")
							.default,
					gameTitle: {
						ro: "Secventialitate logica",
						eng: "Logical sequence",
					},
					route: "/games/dezvoltarea_gandirii/game5",
					availableLevels: 3,
					levelInfo: {
						1: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/secventialitate/secventialitate_1.svg")
									.default,
							questionText: {
								ro: "Aranjează imaginile in ordine cronologica.",
								eng: "Arrange the images in chronological order.",
							},
							gameLevelDescription: {
								ro: "Privește cele trei imagini și aranjeaza-le în ordinea cronologică conform succesiunii acțiunilor.",
								eng: "Look at the three images and arrange them in chronological order according to the sequence of actions.",
							},
							nrOfAnswers: 3,
						},
						2: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/secventialitate/secventialitate_2.svg")
									.default,
							questionText: {
								ro: "Aranjează imaginile in ordine cronologica.",
								eng: "Arrange the images in chronological order.",
							},
							gameLevelDescription: {
								ro: " Privește cele patru imagini și aranjeaza-le în ordinea cronologică conform succesiunii acțiunilor.",
								eng: "Look at the four images and arrange them in chronological order according to the sequence of actions.",
							},
							nrOfAnswers: 4,
						},
						3: {
							levelIcon:
								require("../assets/games_icons/dezvoltarea_gandirii/secventialitate/secventialitate_3.svg")
									.default,
							questionText: {
								ro: "Aranjează imaginile in ordine cronologica.",
								eng: "Arrange the images in chronological order.",
							},
							gameLevelDescription: {
								ro: " Privește cele patru imagini și aranjeaza-le în ordinea cronologică conform succesiunii acțiunilor.",
								eng: "Look at the four images and arrange them in chronological order according to the sequence of actions.",
							},
							nrOfAnswers: 4,
						},
					},
					imagesQuerySelector: "img",
				},
			],
		};
	} else if (moduleName === "") {
		let modulesInfo = {};
		if (getModuleGameInfo("Atentie").active) {
			modulesInfo.atentie = getModuleGameInfo("Atentie");
		}
		if (getModuleGameInfo("Limbaj").active) {
			modulesInfo.limbaj = getModuleGameInfo("Limbaj");
		}
		if (getModuleGameInfo("Emotii").active) {
			modulesInfo.emotii = getModuleGameInfo("Emotii");
		}
		if (getModuleGameInfo("Matematica").active) {
			modulesInfo.matematica = getModuleGameInfo("Matematica");
		}
		if (getModuleGameInfo("Memorie vizuala").active) {
			modulesInfo.memorie_vizuala = getModuleGameInfo("Memorie vizuala");
		}
		if (getModuleGameInfo("Perceptie").active) {
			modulesInfo.perceptie = getModuleGameInfo("Perceptie");
		}
		if (getModuleGameInfo("Abilitati vizuo-spatiale").active) {
			modulesInfo.abilitati_vizuo_spatiale = getModuleGameInfo(
				"Abilitati vizuo-spatiale"
			);
		}
		if (getModuleGameInfo("Dezvoltarea gandirii").active) {
			modulesInfo.dezvoltarea_gandirii = getModuleGameInfo(
				"Dezvoltarea gandirii"
			);
		}
		return modulesInfo;
	}
}

export const subscriptions = {
	free: {
		backgroundColorStyle: "rgb(121 217 166)",
		borderColorStyle: "rgb(42 177 76)",
		colorStyle: "white",
		currency: "lei",
		price: 0,
		title: "Free",
		subscriptionType: "free",
		image: "wiki_gradi.svg",
		benefits: TEXT.FREE_BENEFITS[lan],
	},
	"one-month": {
		backgroundColorStyle: "rgb(121, 204, 217)",
		borderColorStyle: "rgb(42 159 177)",
		colorStyle: "white",
		currency: "lei",
		price: 29.99,
		title: "Smarts",
		subscriptionType: "one-month",
		image: "wiki_simaimic.svg",
		benefits: TEXT.ONE_MONTH_BENEFITS[lan],
	},
	"twelve-months": {
		backgroundColorStyle: "rgb(135 121 217)",
		borderColorStyle: "rgb(130 83 206)",
		colorStyle: "white",
		currency: "lei",
		price: 249.99,
		title: "Smarts Premium",
		subscriptionType: "twelve-months",
		image: "wiki_mare.svg",
		benefits: TEXT.TWELVE_MONTHS_BENEFITS[lan],
	},
};

export function getGameById(gameId) {
	let modulesInfo = getModuleGameInfo("");
	let gameInfoFinal = "";
	Object.keys(modulesInfo).forEach((moduleKey) => {
		let moduleGames = modulesInfo[moduleKey].gamesInfo;
		moduleGames.forEach((gameInfo, i) => {
			if (gameInfo.gameId === gameId) {
				gameInfoFinal = gameInfo;
				gameInfoFinal.gameNumber = i + 1;
				gameInfoFinal.moduleName = modulesInfo[moduleKey].moduleName;
				gameInfoFinal.moduleIcon = modulesInfo[moduleKey].moduleIcon;
				gameInfoFinal.backgroundColor = modulesInfo[moduleKey].backgroundColor;
				gameInfoFinal.borderColor = modulesInfo[moduleKey].borderColor;
				gameInfoFinal.gamesRoute = modulesInfo[moduleKey].gamesRoute;
				gameInfoFinal.backgroundImage = modulesInfo[moduleKey].backgroundImage;
				gameInfoFinal.color = modulesInfo[moduleKey].color;
			}
		});
	});
	return gameInfoFinal;
}

export function getLevelInfo(gameId, level) {
	let gameInfo = getGameById(gameId);
	let defaultLevelInfo = {
		1: {},
		2: {},
		3: {},
	};

	return gameInfo?.levelInfo?.[level]
		? gameInfo.levelInfo[level]
		: defaultLevelInfo;
}

export function tryHideOverlay(callback) {
	let overlayVisibleVar = overlayVisible();
	if (overlayVisibleVar) {
		let intervalId = setInterval(() => {
			let imagesToLoad = document.querySelectorAll(".game-area img");

			let nrOfImages = imagesToLoad.length;
			let nrOfRenderedImages = 0;

			imagesToLoad.forEach((image) => {
				if (image.complete && image.naturalHeight !== 0 && image.src !== "") {
					nrOfRenderedImages++;
				}
			});
			if (nrOfRenderedImages === nrOfImages && nrOfImages !== 0) {
				setTimeout(() => {
					hideOverlay();
					clearInterval(intervalId);
					if (callback) callback();
				}, 200);
			}
		}, 200);
	}
}
