import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "./styles/pretty-button.css";
import "./styles/normalize.css";
import "./styles/globals.css";
import "./styles/card.css";
import "./styles/navbar.css";
import "./styles/activity-card.css";
import "./styles/drawer.css";
import "./styles/user-info.css";
import "./styles/bootstrap-grid.css";
import "./styles/games.css";
import "./styles/translation-classes.css";

import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Bold.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-ExtraLight.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Light.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Medium.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-Regular.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-SemiBold.ttf";
import "./fonts/YanoneKaffeesatz/YanoneKaffeesatz-VariableFont_wght.ttf";

import "./fonts/Poppins/Poppins-Black.ttf";
import "./fonts/Poppins/Poppins-BlackItalic.ttf";
import "./fonts/Poppins/Poppins-Bold.ttf";
import "./fonts/Poppins/Poppins-BoldItalic.ttf";
import "./fonts/Poppins/Poppins-ExtraBold.ttf";
import "./fonts/Poppins/Poppins-ExtraBoldItalic.ttf";
import "./fonts/Poppins/Poppins-ExtraLight.ttf";
import "./fonts/Poppins/Poppins-ExtraLightItalic.ttf";
import "./fonts/Poppins/Poppins-Italic.ttf";
import "./fonts/Poppins/Poppins-Light.ttf";
import "./fonts/Poppins/Poppins-LightItalic.ttf";
import "./fonts/Poppins/Poppins-Medium.ttf";
import "./fonts/Poppins/Poppins-MediumItalic.ttf";
import "./fonts/Poppins/Poppins-Regular.ttf";
import "./fonts/Poppins/Poppins-SemiBold.ttf";
import "./fonts/Poppins/Poppins-SemiBoldItalic.ttf";
import "./fonts/Poppins/Poppins-Thin.ttf";
import "./fonts/Poppins/Poppins-ThinItalic.ttf";

import "./styles/tabs.css";
import { Logout } from "./views/Logout";
import { Loading } from "./views/Loading";
import axios from "axios";

import { ToastContainer } from "react-toastify";
import { isMobileDevice, logout, releaseDate } from "./scripts/helpers";
import Countdown from "./views/Countdown";
import Container from "./components/Container";
import ResetPassword from "./views/ResetPassword";
import SubscriptionInformations from "./views/SubscriptionInformations";
import Cookies from "js-cookie";
import CancelSubscription from "./views/CancelSubscription";

import TagManager from "react-gtm-module";
import { gtag, install } from "ga-gtag";
import ReactPixel from "react-facebook-pixel";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Tutorial__Mobile from "./views/modules/tutorial/Tutorial__Mobile";
import Tutorial from "./views/modules/tutorial/Tutorial";
import DezvoltareGame5 from "./views/modules/dezvoltare/DezvoltareGame5";
import { COMPONENTS, lan } from "./scripts/text";

const tagManagerArgs = {
	gtmId: "GTM-PL3F3KR",
};

TagManager.initialize(tagManagerArgs);
install("G-7MMG12E9FB");

const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};

ReactPixel.init("867286784539399", {}, options);
ReactPixel.pageView(); // For tracking page view

const NotFound = React.lazy(() => import("./views/NotFound"));

const LandingPage = React.lazy(() => import("./views/LandingPage"));

const Welcome = React.lazy(() => import("./views/Welcome"));

const ModulesList = React.lazy(() => import("./views/ModulesList"));
const LevelExplorer = React.lazy(() =>
	import("./components/modules/LevelExplorer")
);

const Profile = React.lazy(() => import("./views/Profile"));

const Progress = React.lazy(() => import("./views/Progress"));

const DezvoltareExplorer = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareExplorer")
);
const DezvoltareStart = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareStart")
);
const DezvoltareGame1 = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareGame1")
);
const DezvoltareGame2 = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareGame2")
);
const DezvoltareGame3 = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareGame3")
);
const DezvoltareGame4 = React.lazy(() =>
	import("./views/modules/dezvoltare/DezvoltareGame4")
);
const PerceptieExplorer = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieExplorer")
);
const PerceptieExplorer__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieExplorer__Mobile")
);
const PerceptieStart = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieStart")
);
const PerceptieStart__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieStart__Mobile")
);
const PerceptieGame1 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame1")
);
const PerceptieGame1__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame1__Mobile")
);
const PerceptieGame2 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame2")
);
const PerceptieGame2__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame2__Mobile")
);
const PerceptieGame3 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame3")
);
const PerceptieGame3__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame3__Mobile")
);
const PerceptieGame4 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame4")
);
const PerceptieGame4__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame4__Mobile")
);
const PerceptieGame5 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame5")
);
const PerceptieGame5__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame5__Mobile")
);
const PerceptieGame6 = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame6")
);
const PerceptieGame6__Mobile = React.lazy(() =>
	import("./views/modules/perceptie/PerceptieGame5__Mobile")
);

const AtentieExplorer = React.lazy(() =>
	import("./views/modules/atentie/AtentieExplorer")
);
const AtentieExplorer__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieExplorer__Mobile")
);
const AtentieStart = React.lazy(() =>
	import("./views/modules/atentie/AtentieStart")
);
const AtentieStart__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieStart__Mobile")
);
const AtentieGame1 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame1")
);
const AtentieGame1__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame1__Mobile")
);
const AtentieGame2 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame2")
);
const AtentieGame2__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame2__Mobile")
);
const AtentieGame3 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame3")
);
const AtentieGame3__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame3__Mobile")
);
const AtentieGame4 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame4")
);
const AtentieGame4__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame4__Mobile")
);
const AtentieGame5 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame5")
);
const AtentieGame5__Mobile = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame5__Mobile")
);
const AtentieGame6 = React.lazy(() =>
	import("./views/modules/atentie/AtentieGame6")
);

const EmotiiExplorer = React.lazy(() =>
	import("./views/modules/emotii/EmotiiExplorer")
);
const EmotiiExplorer__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiExplorer__Mobile")
);
const EmotiiStart = React.lazy(() =>
	import("./views/modules/emotii/EmotiiStart")
);
const EmotiiStart__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiStart__Mobile")
);
const EmotiiGame1 = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame1")
);
const EmotiiGame1__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame1__Mobile")
);
const EmotiiGame2 = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame2")
);
const EmotiiGame2__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame2__Mobile")
);
const EmotiiGame3 = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame3")
);
const EmotiiGame3__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame3__Mobile")
);
const EmotiiGame4 = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame4")
);
const EmotiiGame4__Mobile = React.lazy(() =>
	import("./views/modules/emotii/EmotiiGame4__Mobile")
);

const MemorieExplorer = React.lazy(() =>
	import("./views/modules/memorie/MemorieExplorer")
);
const MemorieExplorer__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieExplorer__Mobile")
);
const MemorieStart = React.lazy(() =>
	import("./views/modules/memorie/MemorieStart")
);
const MemorieStart__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieStart__Mobile")
);
const MemorieGame1 = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame1")
);
const MemorieGame1__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame1__Mobile")
);
const MemorieGame2 = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame2")
);
const MemorieGame2__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame2__Mobile")
);
const MemorieGame3 = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame3")
);
const MemorieGame3__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame3__Mobile")
);
const MemorieGame4 = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame4")
);
const MemorieGame4__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame4__Mobile")
);
const MemorieGame5 = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame5")
);
const MemorieGame5__Mobile = React.lazy(() =>
	import("./views/modules/memorie/MemorieGame5__Mobile")
);

const LimbajExplorer = React.lazy(() =>
	import("./views/modules/limbaj/LimbajExplorer")
);
const LimbajExplorer__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajExplorer__Mobile")
);
const LimbajStart = React.lazy(() =>
	import("./views/modules/limbaj/LimbajStart")
);
const LimbajStart__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajStart__Mobile")
);
const LimbajGame1 = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame1")
);
const LimbajGame1__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame1__Mobile")
);
const LimbajGame2 = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame2")
);
const LimbajGame2__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame2__Mobile")
);
const LimbajGame3 = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame3")
);
const LimbajGame3__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame3__Mobile")
);
const LimbajGame4 = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame4")
);
const LimbajGame4__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame4__Mobile")
);
const LimbajGame5 = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame5")
);
const LimbajGame5__Mobile = React.lazy(() =>
	import("./views/modules/limbaj/LimbajGame5__Mobile")
);

const VizuoSpatialeExplorer = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeExplorer")
);
const VizuoSpatialeExplorer__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeExplorer__Mobile")
);
const VizioSpatialeStart = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizioSpatialeStart")
);
const VizuoSpatialeStart__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeStart__Mobile")
);
const VizioSpatialeGame1 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizioSpatialeGame1")
);
const VizuoSpatialeGame1__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame1__Mobile")
);
const VizioSpatialeGame2 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame2")
);
const VizuoSpatialeGame2__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame2__Mobile")
);
const VizioSpatialeGame3 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizioSpatialeGame3")
);
const VizuoSpatialeGame3__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame3__Mobile")
);
const VizioSpatialeGame4 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizioSpatialeGame4")
);
const VizuoSpatialeGame4__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame4__Mobile")
);
const VizuoSpatialeGame5 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame5")
);
const VizuoSpatialeGame5__Mobile = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizuoSpatialeGame5__Mobile")
);

const VizioSpatialeGame10 = React.lazy(() =>
	import("./views/modules/vizuoSpatiale/VizioSpatialeGame10")
);

const MatematicaExplorer = React.lazy(() =>
	import("./views/modules/matematica/MatematicaExplorer")
);
const MatematicaExplorer__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaExplorer__Mobile")
);
const MatematicaStart = React.lazy(() =>
	import("./views/modules/matematica/MatematicaStart")
);
const MatematicaStart__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaStart__Mobile")
);
const MatematicaGame1 = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame1")
);
const MatematicaGame1__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame1__Mobile")
);
const MatematicaGame2 = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame2")
);
const MatematicaGame2__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame2__Mobile")
);
const MatematicaGame3 = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame3")
);
const MatematicaGame3__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame3__Mobile")
);
const MatematicaGame4 = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame4")
);
const MatematicaGame4__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame4__Mobile")
);
const MatematicaGame5 = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame5")
);
const MatematicaGame5__Mobile = React.lazy(() =>
	import("./views/modules/matematica/MatematicaGame5__Mobile")
);

const ComingSoon = React.lazy(() => import("./components/ComingSoon"));

const Redirect = React.lazy(() => import("./views/Redirect.js"));

const MobileContainer = React.lazy(() =>
	import("./components/MobileContainer")
);

const DesktopContainer = React.lazy(() =>
	import("./components/DesktopContainer")
);

const ChooseSubscription = React.lazy(() =>
	import("./views/ChooseSubscription")
);

const ThankYouPage = React.lazy(() => import("./views/ThankYouPage"));

const IntermediaryAfterPayment = React.lazy(() =>
	import("./views/IntermediaryAfterPayment")
);

const MailConfirmationPage = React.lazy(() =>
	import("./views/MailConfirmationPage")
);

const Testare = React.lazy(() => import("./views/Testare"));

// const DezvoltareExplorer = React.lazy(() =>
//   import("./views/modules/dezvoltare/DezvoltareExplorer")
// );

axios.interceptors.response.use(
	(res) => {
		return res;
	},
	(err) => {
		// // console.log(err.response);
		if (
			err.response &&
			err.response.status === 401 &&
			!err.request.responseURL.includes("users")
		)
			logout();
		return Promise.reject(err);
	}
);

axios.interceptors.request.use(
	function (config) {
		if (
			config.data &&
			(config.url.includes("randomizedScenes") || config.url.includes("scenes"))
		) {
			config.data.level = parseInt(Cookies.get("selectedLevel"));
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

ReactDOM.render(
	<GoogleOAuthProvider clientId="982131861026-rbtm0i01bbcnndebndd0uoi8devl40uc.apps.googleusercontent.com">
		<React.StrictMode>
			<BrowserRouter>
				<Suspense fallback={<Loading />}>
					<Routes>
						<Route
							path="/tutorial"
							element={
								<Container deviceContainer={false} showBackButton={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<Tutorial__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<Tutorial />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/d87b080c-c089-4a00-8bdd-33639235ebbf"
							element={
								<Container deviceContainer={false}>
									<Redirect />
								</Container>
							}
						/>

						<Route
							path="/subscription"
							element={
								<Container deviceContainer={false}>
									<ChooseSubscription />
								</Container>
							}
						/>
						<Route
							path="/thankyou"
							element={
								<Container deviceContainer={false}>
									<ThankYouPage />
								</Container>
							}
						/>
						<Route
							path="/thankyou-failed"
							element={
								<Container deviceContainer={false}>
									<ThankYouPage />
								</Container>
							}
						/>
						<Route
							path="/afterpay"
							element={
								<Container deviceContainer={false}>
									<IntermediaryAfterPayment />
								</Container>
							}
						/>
						<Route
							path="/confirm"
							element={
								<Container deviceContainer={false}>
									<MailConfirmationPage />
								</Container>
							}
						/>
						<Route
							path="/countdown"
							element={
								<Container deviceContainer={false}>
									<Countdown />
								</Container>
							}
						/>
						<Route
							path="/cancel-subscription"
							element={
								<Container deviceContainer={false}>
									<CancelSubscription />
								</Container>
							}
						/>
						<Route
							path="/"
							element={
								<Container deviceContainer={false} showBackButton={false}>
									<LandingPage />
								</Container>
							}
						/>
						<Route
							path="/termeni_si_conditii"
							element={COMPONENTS._TERMS[lan]}
						/>
						<Route
							path="/termeni_si_conditii_copii"
							element={COMPONENTS._TERMS_KIDS[lan]}
						/>
						<Route
							path="/prelucrarea_platilor"
							element={COMPONENTS._PAYMENT_PROCESSING[lan]}
						/>
						<Route
							path="/prelucrarea_datelor"
							element={COMPONENTS._DATA_PROCESSING[lan]}
						/>
						<Route path="/cookies" element={COMPONENTS._COOKIES[lan]} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route
							path="/welcome"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<Welcome />
										</MobileContainer>
									) : (
										<DesktopContainer noNavbar={true}>
											<Welcome />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/subscription-informations"
							element={
								<Container noNavbarDesktop={true}>
									<SubscriptionInformations />
								</Container>
							}
						/>
						<Route
							path="/modules"
							element={
								<Container>
									<ModulesList />
								</Container>
							}
						/>
						<Route
							path="/profile"
							element={
								<Container>
									<Profile />
								</Container>
							}
						/>
						<Route
							path="/progress"
							element={
								<Container>
									<Progress />
								</Container>
							}
						/>
						<Route
							path="/logout"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<ComingSoon />
									) : (
										<DesktopContainer>
											<Logout />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/atentie/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/emotii/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/memorie_vizuala/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/matematica/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/level"
							element={
								<Container>
									<LevelExplorer />
								</Container>
							}
						/>

						<Route
							path="/games/dezvoltarea_gandirii/explorer"
							element={
								<Container>
									<DezvoltareExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/start"
							element={
								<Container>
									<DezvoltareStart />
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<DezvoltareGame1 />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<DezvoltareGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<DezvoltareGame2 />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<DezvoltareGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<DezvoltareGame3 />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<DezvoltareGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<DezvoltareGame4 />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<DezvoltareGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/dezvoltarea_gandirii/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<DezvoltareGame5 />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<DezvoltareGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/explorer"
							element={
								<Container>
									<PerceptieExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/start"
							element={
								<Container>
									<PerceptieStart />
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/perceptie/game6"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<PerceptieGame6__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<PerceptieGame6 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/atentie/explorer"
							element={
								<Container>
									<AtentieExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/atentie/start"
							element={
								<Container>
									<AtentieStart />
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<AtentieGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<AtentieGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<AtentieGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<AtentieGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<AtentieGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<AtentieGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<AtentieGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<AtentieGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<AtentieGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<AtentieGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/atentie/game6"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<ComingSoon />
									) : (
										<DesktopContainer>
											<AtentieGame6 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/emotii/explorer"
							element={
								<Container>
									<EmotiiExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/emotii/start"
							element={
								<Container>
									<EmotiiStart />
								</Container>
							}
						/>
						<Route
							path="/games/emotii/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<EmotiiGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<EmotiiGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/emotii/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<EmotiiGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<EmotiiGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/emotii/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<EmotiiGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<EmotiiGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/emotii/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<EmotiiGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<EmotiiGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/memorie_vizuala/explorer"
							element={
								<Container>
									<MemorieExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/memorie_vizuala/start"
							element={
								<Container>
									<MemorieStart />
								</Container>
							}
						/>
						<Route
							path="/games/memorie_vizuala/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MemorieGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MemorieGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/memorie_vizuala/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MemorieGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MemorieGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/memorie_vizuala/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MemorieGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MemorieGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/memorie_vizuala/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MemorieGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MemorieGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/memorie_vizuala/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MemorieGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MemorieGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/limbaj/explorer"
							element={
								<Container>
									<LimbajExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/start"
							element={
								<Container>
									<LimbajStart />
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<LimbajGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<LimbajGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<LimbajGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<LimbajGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<LimbajGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<LimbajGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<LimbajGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<LimbajGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/limbaj/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<LimbajGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<LimbajGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/abilitati_vizuo_spatiale/explorer"
							element={
								<Container>
									<VizuoSpatialeExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/start"
							element={
								<Container>
									<VizioSpatialeStart />
								</Container>
							}
						/>

						<Route
							path="/games/abilitati_vizuo_spatiale/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<VizuoSpatialeGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<VizioSpatialeGame10 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<VizuoSpatialeGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<VizioSpatialeGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<VizuoSpatialeGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<VizioSpatialeGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<VizuoSpatialeGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<VizioSpatialeGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/abilitati_vizuo_spatiale/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<VizuoSpatialeGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<VizuoSpatialeGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/games/matematica/explorer"
							element={
								<Container>
									<MatematicaExplorer />
								</Container>
							}
						/>
						<Route
							path="/games/matematica/start"
							element={
								<Container>
									<MatematicaStart />
								</Container>
							}
						/>
						<Route
							path="/games/matematica/game1"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MatematicaGame1__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MatematicaGame1 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/matematica/game2"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MatematicaGame2__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MatematicaGame2 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/matematica/game3"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MatematicaGame3__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MatematicaGame3 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/matematica/game4"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MatematicaGame4__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MatematicaGame4 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>
						<Route
							path="/games/matematica/game5"
							element={
								<Container deviceContainer={false}>
									{isMobileDevice() ? (
										<MobileContainer
											correctOrientation="landscape"
											noNavbar={true}
										>
											<MatematicaGame5__Mobile />
										</MobileContainer>
									) : (
										<DesktopContainer>
											<MatematicaGame5 />
										</DesktopContainer>
									)}
								</Container>
							}
						/>

						<Route
							path="/*"
							element={
								<Container>
									<NotFound />
								</Container>
							}
						/>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</React.StrictMode>
	</GoogleOAuthProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
