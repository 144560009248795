import React from "react";
import { Link } from "react-router-dom";
import Legal from "./Legal";

export default function PrelucrareaPlatilor() {
	return (
		<Legal
			title="POLITICA PRIVIND PRELUCRAREA PLĂȚILOR WIKISMARTS

"
			className="prelucrarea-platilor"
		>
			<p>
				Bun venit la WikiSmarts! Noi (IT HIT S.R.L., o companie stabilită în
				România – denumită în continuare , „noi”, „nostru” sau „noi”) operăm un
				site web, accesibil la{" "}
				<a href="https://www.wikismarts.com">www.wikismarts.com</a>. În acest
				document explicăm modalitatea de achiziționare a abonamentelor și
				condițiile de procesare a plăților a site-ului nostru WikiSmarts. Toți
				utilizatorii WikiSmarts („Vizitatorii site-ului”) care decid să
				achiziționeze un abonament trebuie să ia la cunoștință următoarele
				informații privind gestionarea plăților.
			</p>
			<p>
				Vă rugăm să citiți cu atenție Politica privind prelucrarea plăților
				înainte de a iniția orice plată prin intermediul WikiSmarts. Prin
				accesarea sau utilizarea WikiSmarts, sunteți de acord că ați citit,
				înțeles și sunteți de acord cu cele enumerate în prezenta și cu stocarea
				datelor cu caracter personal și de card.
			</p>
			<ul>
				<li>
					primul joc/nivel din fiecare modul al WikiSmarts poate fi jucat de
					fiecare utilizator în mod gratuit, începând cu data activării
					contului, pe o perioadă nelimitată. Pentru avansarea la următoarele
					niveluri este necesară achiziționarea unui abonament.
				</li>
				<li>
					abonamentele pe care Vizitatorii site-ului le pot achiziționa pot fi
					lunare sau anuale.
				</li>
				<li>
					plata serviciilor de pe site-ul WikiSmarts se va realiza online, cu
					cardul bancar. Nu sunt aplicabile taxe suplimentare pentru plata cu
					cardul.
				</li>
				<li>
					Cardurile acceptate sunt Visa, Mastercard, plata fiind realizată în
					condiții de securitate maximă, prin intermediul BT Pay.
				</li>
				<li>
					tranzacția va apărea pe extrasul de cont al Vizitatorilor site-ului ca
					„WikiSmarts”. Indiferent de valuta pe care o aveți în cont,
					tranzacțiile se vor efectua în RON.
				</li>
				<li>
					pentru serviciile comercializate pe site-ul WikiSmarts, prețul va fi
					indicat exclusiv în RON. În anumite situații, acest preț poate include
					sau nu T.V.A., însă vom face mențiunile necesare acolo unde este
					nevoie pentru a vă informa în mod corect.
				</li>
				<li>
					stocarea datelor de card se face în condițiile respectării
					standardelor de securitate PCI DSS, prin intermediul unei terțe părți
					autorizată să desfășoare această activitate și agreată de bancă.
				</li>
				<li>
					stocarea datelor de card se face numai cu acordul dvs., în vederea
					utilizării lor ulterioare, la solicitarea dvs., cu scopul efectuării
					de plăți pe site-ul WikiSmarts.
				</li>
				<li>
					vom emite factura fiscală aferentă serviciilor achiziționate pe baza
					datelor de identificare pe care ni le veți comunica. Facturile fiscale
					vor fi livrate odată cu serviciile comandate, pe e-mail-ul
					dumneavoastră, acestea fiind valabile în acest format și chiar fără o
					ștampilă sau semnătură din partea noastră.
				</li>
				<li>
					datele dvs. de card nu vor fi stocate de către noi, ci doar de o
					entitate certificată să stocheze datele în vederea procesării
					tranzacțiilor, entitate cu care atât noi cât și banca prin intermediul
					căreia se realizează plățile ne aflăm într-o relație contractuală în
					vederea procesării tranzacțiilor recurente/de tip Card on File.
				</li>

				<li>
					nu vom debita niciun card al Vizitatorilor site-ului fără acordul
					expres al acestora, exprimat prin activarea metodei de plată
					recurente/Card on File.
				</li>
				<li>
					în cazul în care v-ați exprimat acordul, veți fi automat debitat
					periodic cu contravaloarea serviciilor, în cazul în care nu anulați
					conform celor de mai jos.
				</li>
				<li>
					debitările automate de pe card se vor face doar în momentul emiterii
					unei noi facturi și nu vom debita Vizitatorii site-ului decât în
					momentul în care o nouă factură va fi emisă și transmisă către dvs.
				</li>
				<li>
					veți primi o înștiințare de debitare cu cel puțin 2 (două) zile
					înainte de momentul tranzacției recurente efective. În cazul în care
					tranzacția recurentă a eșuat, veți fi înștiințat în legătură cu
					următorul termen când se va efectua tranzacția. În cazul în care plata
					nu va fi finalizată cu succes, abonamentul se va suspenda pana la
					efectuarea platii.
				</li>
				<li>
					anularea unei plăți recurente se poate realiza prin următoarele
					modalități:
					<ul>
						<li>
							prin optiunea de anulare abonament aflata in contul clientului
						</li>
						<li>ștergerea cardului salvat</li>
					</ul>
				</li>
				<li>
					în baza legislației Uniunii Europene și a Ordonanţei de urgenţă nr.
					34/2014 din 04/06/2014 privind drepturile consumatorilor în cadrul
					contractelor încheiate cu profesioniştii, precum şi pentru modificarea
					şi completarea unor acte normative, aveți dreptul de a anula și
					returna comanda unui abonament în termen de 14 (paisprezece) zile
					calendaristice, indiferent de motiv și fără nicio justificare,
					completând formularul de <a href="/cancel-subscription">aici</a>.
				</li>
			</ul>
			<p>
				Pentru orice întrebări suplimentare privind procesarea și gestionarea
				plăților ne puteți contacta oricând la adresa de email{" "}
				<a>wiki@wikismarts.com</a> sau la telefon (0371) 395 028.
			</p>
		</Legal>
	);
}
