import React from "react";
import { Link } from "react-router-dom";
import Legal from "./Legal";

export default function PaymentProcessing() {
	return (
		<Legal
			title="PRIVACY POLICY FOR WIKISMARTS PAYMENT PROCESSING

"
			className="prelucrarea-platilor"
		>
			<p>
				Welcome to WikiSmarts! We (IT HIT S.R.L., a company based in Romania,
				hereinafter referred to as "we", "our", or "us") operate a website
				accessible at{" "}
				<a href="https://www.wikismarts.com">www.wikismarts.com</a>. In this
				document, we explain the process of purchasing subscriptions and the
				terms of payment processing on our website, WikiSmarts. All WikiSmarts
				users ("Website Visitors") who decide to purchase a subscription must be
				aware of the following information regarding payment management.
			</p>
			<p>
				Please read the Payment Processing Policy carefully before initiating
				any payment through WikiSmarts. By accessing or using WikiSmarts, you
				agree that you have read, understood, and agreed to the provisions
				outlined herein, including the storage of personal and card data.
			</p>
			<ul>
				<li>
					the first game/level of each module in WikiSmarts can be played by
					every user for free, starting from the activation date of the account,
					for an unlimited period. To progress to the next levels, purchasing a
					subscription is required.
				</li>
				<li>
					the subscriptions that site visitors can purchase can be either
					monthly or yearly.
				</li>
				<li>
					the payment for services on the WikiSmarts website will be made online
					using a bank card. No additional fees apply for card payments.
				</li>
				<li>
					The accepted cards are Visa and Mastercard, and the payment is made
					under maximum security conditions through BT Pay.
				</li>
				<li>
					the transaction will appear on the Visitors' website statement as
					"WikiSmarts". Regardless of the currency you have in your account,
					transactions will be processed in RON.
				</li>
				<li>
					for the services sold on the WikiSmarts website, the price will be
					indicated exclusively in RON. In certain situations, this price may or
					may not include VAT (Value Added Tax), but we will make the necessary
					mentions where needed to provide you with accurate information.
				</li>
				<li>
					the storage of card data is done in compliance with the PCI DSS
					security standards, through a third-party authorized to perform this
					activity and approved by the bank.
				</li>
				<li>
					the storage of card data is done only with your consent, for the
					purpose of their subsequent use, upon your request, in order to
					process payments on the WikiSmarts website.
				</li>
				<li>
					we will issue the fiscal invoice related to the purchased services
					based on the identification data you provide us. The fiscal invoices
					will be delivered along with the ordered services to your email
					address. They are valid in this electronic format even without a stamp
					or signature from our side.
				</li>
				<li>
					your card data will not be stored by us, but only by a certified
					entity authorized to store the data for transaction processing
					purposes. Both we and the bank, through which the payments are
					processed, have a contractual relationship with this entity for the
					purpose of processing recurring/Card on File transactions.
				</li>

				<li>
					we will not charge any card of the site Visitors without their
					explicit consent, expressed by activating the recurring/Card on File
					payment method.
				</li>
				<li>
					if you have given your consent, you will be automatically charged
					periodically with the corresponding amount for the services, unless
					you cancel as described below.
				</li>
				<li>
					automatic debit transactions from your card will only occur at the
					time of issuing a new invoice, and we will only debit the Visitors of
					the website when a new invoice has been issued and sent to you.
				</li>
				<li>
					you will receive a debit notification at least 2 (two) days prior to
					the actual recurring transaction. If the recurring transaction fails,
					you will be notified of the next scheduled transaction date. In case
					the payment is not successfully completed, the subscription will be
					suspended until the payment is made.
				</li>
				<li>
					cancellation of a recurring payment can be done through the following
					methods:
					<ul>
						<li>
							through the option to cancel the subscription found in the
							customer's account.
						</li>
						<li>deleting the saved card.</li>
					</ul>
				</li>
				<li>
					Based on the legislation of the European Union and Emergency Ordinance
					No. 34/2014 of 06/04/2014 regarding consumer rights in contracts
					concluded with professionals, as well as amending and supplementing
					certain normative acts, you have the right to cancel and return a
					subscription order within 14 (fourteen) calendar days, regardless of
					the reason and without any justification, by completing the form.{" "}
					<a href="/cancel-subscription">here</a>.
				</li>
			</ul>
			<p>
				For any additional questions regarding payment processing and
				management, you can contact us anytime at the email address.{" "}
				<a>wiki@wikismarts.com</a> or by phone (0371) 395 028.
			</p>
		</Legal>
	);
}
