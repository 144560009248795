import React, {useEffect, useState} from "react";
import axios from "axios";
import {getApiConfig, isIOS, isMobileApp} from "../../scripts/helpers";
import Cookies from "js-cookie";
import config from "../../config";
import PopoverForm from "../PopoverForm";
import { toast } from "react-toastify";
import {isMobile} from "react-device-detect";

export default function NewPassword(props) {
	const query = new URLSearchParams(window.location.search);
	const [password, setPassword] = useState("");
	const [resetCode, setResetCode] = useState(query.get("code") ?? "");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [error, setError] = useState("");
	const [info, setInfo] = useState("");

	async function handleResetPass(e) {
		e.preventDefault();
		let body = {
			code: resetCode,
			newPassword1: password,
			newPassword2: passwordConfirm,
		};

		let response = await axios.post(
			`${getApiConfig().baseUrl}/users/resetPassword`,
			body
		);
		if (response.status === 201 && response.data?.success) {
			setInfo("Parola a fost resetată cu succes!");
			setTimeout(() => (window.location.href = "/"), 1000);
		}
	}

	useEffect(() => {
		if (isMobile && !isMobileApp()) {
			const query = new URLSearchParams(window.location.search);
			let url = '';

			if (isIOS()) {
				url = `WikiSmarts://dev.wikismarts.com?link=https://dev.wikismarts.com/reset-password?code=${query.get("code")}`;
				window.location.replace(url);
			}
		}
	}, []);

	return (
		<>
			<h3>Crează o nouă parolă.</h3>
			<form className="login-form" onSubmit={handleResetPass}>
				<div id="form-input-container" className="form-input-container">
					<label htmlFor="reset-password-code">Code:</label>
					<input
						type="text"
						id="reset-password-code"
						name="reset-password-code"
						defaultValue={query.get("code") ?? ""}
						onChange={(e) => setResetCode(e.target.value)}
					/>
				</div>
				<div id="form-input-container" className="form-input-container">
					<label htmlFor="forgot-password">Noua Parolă:</label>
					<input
						type="password"
						id="forgot-password"
						name="forgot-password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div id="form-input-container" className="form-input-container">
					<label htmlFor="forgot-password-confirm">Repetă noua parolă:</label>
					<input
						type="password"
						id="forgot-password-confirm"
						name="forgot-password-confirm"
						onChange={(e) => setPasswordConfirm(e.target.value)}
					/>
				</div>
				<button
					type="submit"
					className="form-submit pretty-button"
					id="login-submit-button"
				>
					SCHIMBĂ PAROLA
				</button>

				<div
					className=""
					style={{ color: "red", width: "100%", display: "block" }}
				>
					{error}
				</div>

				<div
					className=""
					style={{ color: "darkgreen", width: "100%", display: "block" }}
				>
					{info}
				</div>
			</form>
		</>
	);
}
