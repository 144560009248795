import { Alert } from "antd";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { getApiConfig } from "../../scripts/helpers";
import Legal from "./Legal";
import { toast, ToastContainer } from "react-toastify";

export default function DataProcessing() {
	const section1 = useRef();
	const section2 = useRef();
	const section3 = useRef();
	const section4 = useRef();
	const section5 = useRef();
	const section6 = useRef();
	const section7 = useRef();
	const section8 = useRef();
	const section9 = useRef();
	const [marketing, setMarketing] = useState(-1);
	const [initialMarketing, setInitialMarketing] = useState(-1);
	useEffect(() => {
		axios
			.get(`${getApiConfig().baseUrl}/users/getUser`, {
				headers: getApiConfig().headers,
			})
			.then((response) => {
				if (response.data.marketing) {
					setInitialMarketing(1);
					setMarketing(1);
				} else {
					setInitialMarketing(0);
					setMarketing(0);
				}
			})
			.catch((errResponse) => {
				setInitialMarketing(-1);
			});
	}, []);

	function setMarketingConsent() {
		axios
			.post(
				`${getApiConfig().baseUrl}/users/setConsent`,
				{ marketing: marketing },
				{ headers: getApiConfig().headers }
			)
			.then((response) => {
				toast.success("Preferintele tale au fost actualizate!");

				setInitialMarketing(marketing);
			})
			.catch((error) => {
				toast.error("A aparut o eroare");
			});
	}

	return (
		<Legal
			title="POLICY REGARDING THE PROCESSING OF PERSONAL DATA"
			className="prelucrarea-datelor"
		>
			<ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
				theme="light"
			/>
			{initialMarketing !== -1 ? (
				<>
					<div className="form-checkbox-container">
						<input
							type="checkbox"
							id="marketing"
							name="marketing"
							style={{ marginRight: "10px" }}
							checked={marketing}
							onChange={(e) => {
								setMarketing(e.target.checked);
							}}
						/>
						<label style={{ fontSize: "1.1rem" }}>
							I agree to the processing of my personal data in order to receive
							marketing materials, in accordance with{" "}
							<span
								style={{ color: "#177dda", cursor: "pointer" }}
								onClick={() => {
									section9.current.scrollIntoView();
								}}
							>
								Data protection policy
							</span>
							.
						</label>
					</div>
					{initialMarketing != marketing ? (
						<div
							onClick={() => {
								setMarketingConsent();
							}}
							className="pretty-button"
							style={{ margin: "10px 0 40px 0" }}
						>
							Save preferences
						</div>
					) : (
						""
					)}
				</>
			) : (
				""
			)}
			<p>
				<em>
					This policy regarding the processing of personal data is effective as
					of September 28, 2022, and will be regularly updated to reflect any
					changes in the way we handle your personal data or any changes in
					applicable laws.
				</em>
			</p>
			<h5
				onClick={() => {
					section1.current.scrollIntoView();
				}}
			>
				When is this policy regarding the processing of personal data
				applicable?
			</h5>
			<h5
				onClick={() => {
					section2.current.scrollIntoView();
				}}
			>
				What personal data do we process about you and where do we obtain it
				from?
			</h5>
			<h5
				onClick={() => {
					section3.current.scrollIntoView();
				}}
			>
				For what purposes do we process your personal data and based on what
				legal grounds?
			</h5>
			<h5
				onClick={() => {
					section9.current.scrollIntoView();
				}}
			>
				How do we use your personal data for marketing purposes?
			</h5>
			<h5
				onClick={() => {
					section4.current.scrollIntoView();
				}}
			>
				Additional functionality enabled
			</h5>
			<h5
				onClick={() => {
					section5.current.scrollIntoView();
				}}
			>
				How long will we store your personal data?
			</h5>
			<h5
				onClick={() => {
					section6.current.scrollIntoView();
				}}
			>
				How do we ensure data security?
			</h5>
			<h5
				onClick={() => {
					section7.current.scrollIntoView();
				}}
			>
				Who has access to your personal data?
			</h5>
			<h5
				onClick={() => {
					section8.current.scrollIntoView();
				}}
			>
				What rights do you have regarding the processing of your data?
			</h5>
			<p>
				Your privacy is important to us, and we strive to provide a high level
				of protection regarding the processing of personal data, in accordance
				with the Regulation (EU) 2016/679 of the European Parliament and of the
				Council of 27 April 2016 on the protection of natural persons with
				regard to the processing of personal data and on the free movement of
				such data, and repealing Directive 95/46/EC (General Data Protection
				Regulation).
			</p>
			<p>
				In accordance with applicable data protection legislation, we are
				responsible for the processing of your personal data as described below.
				If you have any questions regarding this information or if you wish to
				exercise any of your rights, you can contact us using the contact
				details below.
			</p>
			<h2 ref={section1}>
				When is this policy regarding the processing of personal data
				applicable?
			</h2>
			<p>
				This policy regarding the processing of personal data provides details
				regarding the processing of personal data within WikiSmarts.
			</p>
			<p>
				This policy does not cover the processing of personal data undertaken by
				other companies, individuals, authorities, or institutions, nor does it
				cover companies and individuals advertising our services and products
				through cookies or other technologies.
			</p>
			<h2 ref={section2}>
				What personal data do we process about you and where do we obtain it
				from?
			</h2>
			<p>
				We collect personal data of users of our website and mobile application,
				WikiSmarts. The term "personal data" refers to information that,
				directly or indirectly, can be related to you as an individual. The
				personal data we may process regarding Site Visitors includes:
			</p>
			<table className="icons-grid legal-table">
				<tbody>
					<tr>
						<td>Category of personal data</td>
						<td>Detailed categories of personal data</td>
					</tr>
					<tr>
						<td>
							Personal details, contact details, and identification details
						</td>
						<td>
							Name, first name, email address, photograph (optional),
							address/residence
						</td>
					</tr>
					<tr>
						<td>Cookies</td>
						<td>
							You can refer to the Cookie Policy for more details regarding the
							processing of cookies.
						</td>
					</tr>
					<tr>
						<td>Financial data</td>
						<td>Bank account</td>
					</tr>
				</tbody>
			</table>
			<p>
				The mentioned categories of personal data have been obtained directly
				from you when you create an account in the WikiSmarts application or
				when you submit a withdrawal request as a consumer.{" "}
			</p>
			<h2 ref={section3}>
				For what purposes do we process your personal data and based on what
				legal grounds?
			</h2>
			<p>
				The processing of personal data refers to any action that we or a third
				party undertake regarding personal data, including but not limited to
				collection, recording, correction, storage, and deletion. Personal data
				will be processed only for specified purposes and will not be further
				processed for any other purposes that exceed these purposes, except
				based on the provisions of the General Data Protection
				Regulation:Creating an account within the WikiSmarts application -
				justified based on our legitimate interest in providing services and
				ensuring application functionality.Billing for services - justified
				based on the necessity of performing a contract to which you are a
				party.Refunding the amounts paid for purchasing a subscription within
				WikiSmarts - justified based on compliance with a legal obligation,
				specifically the Emergency Ordinance No. 34/2014 of 04/06/2014 regarding
				consumer rights in contracts concluded with professionals, as well as
				the amendment and supplementation of certain normative acts.
			</p>
			<p>
				If we were to process your personal data for any purpose that, according
				to current legislation, requires your consent, we will obtain your
				consent before commencing such processing.
			</p>
			<h2 ref={section9}>
				How do we use your personal data for marketing purposes?
			</h2>
			<p>
				In addition to the information presented above, this section describes
				how we use your personal data for marketing purposes. The personal data
				we collect and use for marketing purposes refers to WikiSmarts users and
				is obtained directly from them. We send commercial emails to users who
				have given their consent and with whom we wish to develop or maintain a
				relationship in accordance with applicable marketing laws. If you use
				our mobile application, you may receive notifications, such as
				notifications about topics you have chosen to follow in the mobile
				application or information about events. With your consent provided
				through the permissions/settings of the application, we may send push
				notifications or alerts to your mobile device even when you are not
				connected. At any time, you can manage your push notification
				preferences or disable these notifications by turning off notifications
				in the mobile application or in the device settings/application
				permissions of your mobile device. You can exercise your right to stop
				marketing communications by sending an email.{" "}
				<a href="#">gdpr@ithit.ro</a>, By checking certain boxes in the forms we
				use to collect your personal data or by using opt-out mechanisms in the
				emails we send you. In such cases, we will retain minimal personal data
				to remember that you have opted out, to avoid contacting you again.
			</p>
			<h2 ref={section4}>Additional functionality enabled</h2>
			<p>
				For those users who have given their consent to the installation of any
				applications and updates released through the app store, you understand
				and agree that the application (including any updates) may (i) cause
				your device to automatically communicate with us, (ii) access, affect,
				or modify preferences or data stored on your device, and (iii) collect
				personal data to provide the features described above and as outlined in
				this personal data processing policy.
			</p>
			<h2 ref={section5}>For how long will we store your personal data?</h2>
			<p>
				Your personal data that we process in accordance with the above will be
				kept as long as you have an active account to ensure access to the
				application, after which they will be deleted, or as long as required by
				applicable national legislation.{" "}
			</p>
			<h2 ref={section6}>How do we ensure data security?</h2>
			<p>
				We strive to ensure the highest level of protection for your personal
				data. To achieve this, we implement appropriate technical and
				organizational security measures to prevent unauthorized access,
				alteration, dissemination, or destruction. We continuously work on
				improving our security measures:
			</p>
			<ul>
				<li>
					the adoption and implementation of dedicated internal procedures
				</li>
				<li>limiting processing activities</li>
				<li>restricting access to personal data</li>
				<li>testing and auditing the security of our systems</li>
				<li>backing up the information to ensure its integrity</li>
				<li>training our employees who work with your personal data</li>
			</ul>
			<h2 ref={section7}>Who has access to your personal data?</h2>
			<p>
				Personal data will only be shared with the cloud service provider
				necessary for hosting the application (Exim Host S.R.L.) and with banks
				that handle payment processing. Access to third parties is limited and
				granted only for specific situations, regulated by the agreement
				governing the contractual relationship between us and them.
			</p>
			<p>
				Apart from this incidental access by a third party, we are the only
				party that will have access to your personal data for the purpose of
				providing the services and ensuring the functionality of the
				application. A limited number of our employees will have access to your
				personal data to provide technical support and ensure the proper
				functioning of the application, and they are obligated to maintain the
				confidentiality of the data.
			</p>
			<h2 ref={section8}>
				What rights do you have regarding the processing of your data?
			</h2>
			<p>
				Under the circumstances, conditions, and subject to the exceptions
				provided by applicable law, you can exercise your rights regarding the
				processing of your personal data:
			</p>
			<ul>
				<li>
					You have the right to know whether we hold personal data about you,
					and if we do, to obtain information and a copy of this personal data
				</li>
				<li>
					You have the right to request that your personal data be corrected if
					it is inaccurate or incomplete
				</li>
				<li>
					You have the right to object to the processing of your personal data
					that is necessary for the performance of a task carried out in the
					public interest or in the exercise of official authority vested in us,
					or that is necessary for our legitimate interests or the interests of
					a third party. You also have the right to request the erasure of your
					personal data, including when the personal data is no longer necessary
					for the purposes for which it was collected
				</li>
				<li>
					You have the right to request the processing of your personal data
					only under limited circumstances, including with your consent
				</li>
				<li>
					You have the right to receive a copy (in a structured, commonly used,
					and machine-readable format) of the personal data you have provided to
					IT HIT S.R.L. or to request that we transmit this personal data to
					another data controller
				</li>
				<li>
					To the extent that you have given consent for a processing activity,
					you can withdraw it at any time (such withdrawal will not affect the
					lawfulness of processing based on consent before its withdrawal)
				</li>
				<li>
					You will not be subjected to a solely automated decision-making
					process, including profiling, that produces legal effects concerning
					you or similarly significantly affects you
				</li>
				<li>
					At any time, you have the right to lodge a complaint on{" "}
					<a href="https://www.dataprotection.ro/">
						Autoritatea Națională de Supraveghere a Prelucrării Datelor cu
						Caracter Personal
					</a>
					, in case you believe that your personal data has been processed in
					violation of the applicable data protection laws. You can file a
					complaint with the competent supervisory authority in the country
					where you have your habitual residence, your place of work, or where
					the alleged violation of your personal data processing took place
				</li>
			</ul>
			<p>
				To exercise any of the aforementioned rights, please contact us using
				the contact details provided below. You can use the same contact details
				to ask us questions regarding your rights or any other information
				included in this notice.{" "}
			</p>
			<h5>IT HIT S.R.L.</h5>
			<h5>Bulevardul Tudor Vladimirescu 22, sector 5, București</h5>
			<h5>gdpr@ithit.ro / (0371) 395 028.</h5>
		</Legal>
	);
}
