import React from "react";
import GameBox from "../GameBox";
import ModuleIcon from "../../../assets/icons_modules/matematica.svg";

export default function MatematicaTemplate(props) {
	return (
		<GameBox
			gameBoxTopRowBkg="#F2E2CE"
			gameBoxDivider="#3D2C40"
			moduleName="Matematica"
			moduleIcon={ModuleIcon}
			gameNumber={props.gameNumber}
			gameId={props.children.props.gameId}
			color="#3D2C40"
		>
			{props.children}
		</GameBox>
	);
}
