import React from "react";
import { languages } from "../components/Container";
import "../styles/language-switch.css";
import { useState } from "react";
import { useEffect } from "react";
import { lan } from "../scripts/text";

export default function LanguageSwitch({ type, position, style }) {
	const [selectedLanguage, setSelectedLanguage] = useState({
		key: "",
		text: "",
		image: "",
	});

	function handleLanguageChange(key) {
		localStorage.setItem("lan", key);
		setSelectedLanguage(languages[key]);
		window.location.reload();
	}

	function initLanguage() {
		setSelectedLanguage(languages[lan]);
	}

	useEffect(() => {
		initLanguage();
	}, []);

	return (
		<div
			className={`language-switch language-switch-${type} ${
				position === "left" ? "language-switch-left" : ""
			}`}
			style={style}
		>
			<div
				className="selected-language"
				onClick={() => {
					document
						.querySelector(".language-switch")
						.classList.toggle("language-switch-open");
				}}
			>
				<>
					<img src={selectedLanguage.image} />
					{type == "button" ? <h3>{selectedLanguage.text}</h3> : ""}
				</>
			</div>
			<div className="languages-options">
				{Object.keys(languages).map((key) => {
					let { text, image } = languages[key];
					if (key !== selectedLanguage.key)
						return (
							<div
								className="language"
								key={key}
								onClick={() => {
									handleLanguageChange(key);
								}}
							>
								<img src={image} />
								{type == "button" ? <h3>{text}</h3> : ""}
							</div>
						);
				})}
			</div>
		</div>
	);
}
