import { Alert } from "antd";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { getApiConfig } from "../../scripts/helpers";
import Legal from "./Legal";
import { toast, ToastContainer } from "react-toastify";

export default function PrelucrareaPlatilor() {
	const section1 = useRef();
	const section2 = useRef();
	const section3 = useRef();
	const section4 = useRef();
	const section5 = useRef();
	const section6 = useRef();
	const section7 = useRef();
	const section8 = useRef();
	const section9 = useRef();
	const [marketing, setMarketing] = useState(-1);
	const [initialMarketing, setInitialMarketing] = useState(-1);
	useEffect(() => {
		axios
			.get(`${getApiConfig().baseUrl}/users/getUser`, {
				headers: getApiConfig().headers,
			})
			.then((response) => {
				if (response.data.marketing) {
					setInitialMarketing(1);
					setMarketing(1);
				} else {
					setInitialMarketing(0);
					setMarketing(0);
				}
			})
			.catch((errResponse) => {
				setInitialMarketing(-1);
			});
	}, []);

	function setMarketingConsent() {
		axios
			.post(
				`${getApiConfig().baseUrl}/users/setConsent`,
				{ marketing: marketing },
				{ headers: getApiConfig().headers }
			)
			.then((response) => {
				toast.success("Preferintele tale au fost actualizate!");

				setInitialMarketing(marketing);
			})
			.catch((error) => {
				toast.error("A aparut o eroare");
			});
	}

	return (
		<Legal
			title="POLITICA PRIVIND PRELUCRAREA DATELOR CU CARACTER PERSONAL"
			className="prelucrarea-datelor"
		>
			<ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				draggable
				pauseOnHover
				theme="light"
			/>
			{initialMarketing !== -1 ? (
				<>
					<div className="form-checkbox-container">
						<input
							type="checkbox"
							id="marketing"
							name="marketing"
							style={{ marginRight: "10px" }}
							checked={marketing}
							onChange={(e) => {
								setMarketing(e.target.checked);
							}}
						/>
						<label style={{ fontSize: "1.1rem" }}>
							Sunt de acord cu prelucrarea datelor mele personale pentru a primi
							materiale de marketing, conform{" "}
							<span
								style={{ color: "#177dda", cursor: "pointer" }}
								onClick={() => {
									section9.current.scrollIntoView();
								}}
							>
								Politicii privind protecția datelor
							</span>
							.
						</label>
					</div>
					{initialMarketing != marketing ? (
						<div
							onClick={() => {
								setMarketingConsent();
							}}
							className="pretty-button"
							style={{ margin: "10px 0 40px 0" }}
						>
							Salveaza preferintele
						</div>
					) : (
						""
					)}
				</>
			) : (
				""
			)}
			<p>
				<em>
					Această politică privind prelucrarea datelor cu caracter personal este
					în vigoare începând cu 28.09.2022 și va fi actualizată în mod regulat
					pentru a reflecta orice modificări ale modului în care gestionăm
					datele dumneavoastră cu caracter personal sau orice modificări ale
					legilor aplicabile.
				</em>
			</p>
			<h5
				onClick={() => {
					section1.current.scrollIntoView();
				}}
			>
				Când este aplicabilă această politică privind prelucrarea datelor cu
				caracter personal?
			</h5>
			<h5
				onClick={() => {
					section2.current.scrollIntoView();
				}}
			>
				Ce date personale prelucrăm în legătură cu dvs. și de unde le obținem?
			</h5>
			<h5
				onClick={() => {
					section3.current.scrollIntoView();
				}}
			>
				În ce scopuri prelucrăm datele dvs. cu caracter personal și în baza
				căror temeiuri juridice?
			</h5>
			<h5
				onClick={() => {
					section9.current.scrollIntoView();
				}}
			>
				Cum folosim datele dvs. cu caracter personal în scopuri de marketing?
			</h5>
			<h5
				onClick={() => {
					section4.current.scrollIntoView();
				}}
			>
				Funcționalitate suplimentară activată
			</h5>
			<h5
				onClick={() => {
					section5.current.scrollIntoView();
				}}
			>
				Pentru cât timp vom stoca datele dvs. cu caracter personal?
			</h5>
			<h5
				onClick={() => {
					section6.current.scrollIntoView();
				}}
			>
				Cum asigurăm securitatea datelor?
			</h5>
			<h5
				onClick={() => {
					section7.current.scrollIntoView();
				}}
			>
				Cine are acces la datele dvs. personale?
			</h5>
			<h5
				onClick={() => {
					section8.current.scrollIntoView();
				}}
			>
				Ce drepturi aveți în legătură cu prelucrarea datelor dvs.?
			</h5>
			<p>
				Confidențialitatea dvs. este importantă pentru noi și ne străduim să
				oferim un nivel ridicat de protecție în ceea ce privește prelucrarea
				datelor cu caracter personal, conform Regulamentului (UE) 2016/679 al
				Parlamentului European și al Consiliului din 27 aprilie 2016 privind
				protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu
				caracter personal și privind libera circulație a acestor date și de
				abrogare a Directivei 95/46/CE (Regulamentul general privind protecția
				datelor).
			</p>
			<p>
				În conformitate cu legislația aplicabilă în materie de protecție a
				datelor, suntem responsabili cu prelucrarea datelor dvs. cu caracter
				personal, astfel cum este descris mai jos. Dacă aveți întrebări cu
				privire la aceste informații sau dacă doriți să vă exercitați oricare
				dintre drepturile dvs., ne puteți contacta utilizând detaliile de
				contact de mai jos.
			</p>
			<h2 ref={section1}>
				Când este aplicabilă această politică privind prelucrarea datelor cu
				caracter personal?
			</h2>
			<p>
				Această politică privind prelucrarea datelor cu caracter personal oferă
				detalii privind prelucrarea datelor cu caracter personal în cadrul
				WikiSmarts.
			</p>
			<p>
				Această politică nu acoperă prelucrarea datelor cu caracter personal
				întreprinsă de alte companii, persoane fizice, autorități sau instituții
				sau companiilor și persoane fizice care fac publicitate serviciilor și
				produselor noastre prin intermediul cookie-urilor sau altor tehnologii.
			</p>
			<h2 ref={section2}>
				Ce date personale prelucrăm în legătură cu dvs. și de unde le obținem?
			</h2>
			<p>
				Colectăm date personale ale utilizatorilor site-ului și aplicației
				noastre mobile WikiSmarts. Termenul „date cu caracter personal” se
				referă la informații care, în mod direct sau indirect, se pot referi la
				dvs. în calitate de persoană fizică. Datele cu caracter personal pe care
				le putem prelucra în legătură cu Vizitatorii Site-ului:
			</p>
			<table className="icons-grid legal-table">
				<tbody>
					<tr>
						<td>Categorie de date personale</td>
						<td>Detalierea categoriile de date personale</td>
					</tr>
					<tr>
						<td>Detalii personale, detalii de contact și de identificare</td>
						<td>
							Nume, prenume, adresă de email, fotografie (opțional), adresă /
							domiciliu
						</td>
					</tr>
					<tr>
						<td>Cookie-uri</td>
						<td>
							Vă puteți adresa Politicii de prelucrare a cookie-urilor pentru
							mai multe detalii în legătură cu prelucrarea cookie-urilor
						</td>
					</tr>
					<tr>
						<td>Date financiare</td>
						<td>Contul bancar</td>
					</tr>
				</tbody>
			</table>
			<p>
				Categoriile de date cu caracter personal menționate mai sus au fost
				obținute direct de la dumneavoastră atunci când vă creați un cont în
				aplicația WikiSmarts sau când formulați o cerere de retragere, în
				calitate de consumator.{" "}
			</p>
			<h2 ref={section3}>
				În ce scopuri prelucrăm datele dvs. cu caracter personal și în baza
				căror temeiuri juridice?
			</h2>
			<p>
				Prelucrarea datelor cu caracter personal se referă la orice acțiune la
				care noi sau o terță parte ne-am angajat privind datele cu caracter
				personal, cum ar fi, fără să se limiteze la colectarea, înregistrarea,
				corectarea, stocarea și ștergerea acestora. Datele cu caracter personal
				vor fi prelucrate numai în scopuri specificate și nu vor fi prelucrate
				ulterior în niciun alt scop care depășește aceste scopuri, decât în baza
				prevederilor Regulamentului general privind protecția datelor: crearea
				unui cont în cadrul aplicației WikiSmarts - justificat în baza
				interesului nostru juridic de a ne furniza serviciile și de a asigura
				funcționalitatea aplicației; facturarea serviciilor - justificat în baza
				necesității executării unui contract la care dvs. sunteți parte;
				returnarea sumelor achitate pentru achiziționarea unui abonament în
				cadrul Wikismarts - justificat în baza îndeplinirii unei obligațiile
				legale, anume a Ordonanţei de urgenţă nr. 34/2014 din 04/06/2014 privind
				drepturile consumatorilor în cadrul contractelor încheiate cu
				profesioniştii, precum şi pentru modificarea şi completarea unor acte
				normative.
			</p>
			<p>
				Dacă ar fi să prelucrăm datele dvs. cu caracter personal pentru orice
				scop care, conform legislației în vigoare, necesită consimțământul dvs.,
				vom obține consimțământul dvs. înainte de a începe o astfel de
				prelucrare.
			</p>
			<h2 ref={section9}>
				Cum folosim datele dvs. cu caracter personal în scopuri de marketing?
			</h2>
			<p>
				În plus față de informațiile prezentate mai sus, această secțiune
				descrie modul în care folosim datele dvs. cu caracter personal în
				scopuri de marketing. Datele cu caracter personal pe care le colectăm și
				le folosim în scopuri de marketing se referă la utilizatorii WikiSmarts
				și sunt obținute direct de la aceștia. Trimitem e-mailuri comerciale
				utilizatorilor care și-au dat consimțământul și cu care dorim să
				dezvoltăm sau să menținem o relație în conformitate cu legile de
				marketing aplicabile. Dacă utilizați aplicația noastră mobilă este
				posibil să primiți notificări, cum ar fi notificări despre subiectele pe
				care ați ales să le urmăriți în aplicația mobilă sau informații despre
				evenimente. Cu acordul dvs. furnizat prin permisiunile/setările
				aplicației, este posibil să trimitem notificări push sau alerte către
				dispozitivul dvs. mobil chiar și atunci când nu sunteți conectat. În
				orice moment, puteți gestiona preferințele de notificare push sau puteți
				dezactiva aceste notificări dezactivând notificările în aplicația mobilă
				sau în setările dispozitivului/permisiunile aplicației ale
				dispozitivului dvs. mobil. Vă puteți exercita dreptul de a opri
				comunicările de marketing prin transmiterea unui email{" "}
				<a href="#">gdpr@ithit.ro</a>, bifarea anumitor casete din formularele
				pe care le folosim pentru a vă colecta datele personale sau prin
				utilizarea mecanismelor de renunțare în e-mailurile pe care vi le
				trimitem. În astfel de cazuri, vom păstra datele personale minime pentru
				a reține că ați renunțat, pentru a evita să vă contactăm din nou.
			</p>
			<h2 ref={section4}>Funcționalitate suplimentară activată</h2>
			<p>
				Pentru acei utilizatori care și-au dat acordul cu instalarea oricăror
				aplicații și a oricăror actualizări care sunt lansate prin intermediul
				magazinului de aplicații, înțelegeți și sunteți de acord că aplicația
				(inclusiv orice actualizări) poate (i) determina ca dispozitivul dvs. să
				comunice automat cu noi, (ii) să vadă, să afecteze sau să modifice
				preferințele sau datele stocate pe dispozitivul dvs., (iii) să colecteze
				date personale pentru a oferi caracteristicile descrise mai sus și așa
				cum sunt stabilite din această politică de prelucrare a datelor cu
				caracter personal.
			</p>
			<h2 ref={section5}>
				Pentru cât timp vom stoca datele dvs. cu caracter personal?
			</h2>
			<p>
				Datele dvs. personale pe care le prelucrăm în conformitate cu cele de
				mai sus vor fi păstrate atâta timp cât aveți un cont activ, pentru a
				asigura accesul în aplicație, după care vor și șterse, sau atâta timp
				cât este cerut de legislația națională în vigoare.{" "}
			</p>
			<h2 ref={section6}>Cum asigurăm securitatea datelor?</h2>
			<p>
				Încearcăm să asigurăm cel mai ridicat nivel pentru protecția datelor
				dvs. personale. În acest scop luăm măsuri de securitate tehnice și
				organizaționale adecvate pentru prevenirea accesului, modificării,
				diseminării sau distrugerii neautorizate, lucrând constant la
				îmbunătățirea măsurilor noastre de securitate:
			</p>
			<ul>
				<li>adoptarea și implementarea unor proceduri interne dedicate</li>
				<li>limitarea activităților de prelucrare</li>
				<li>restricționarea accesului la datele cu caracter personal</li>
				<li>testarea și auditarea securității sistemelor noastre</li>
				<li>back-up-ul informațiilor, pentru a asigura integritatea lor</li>
				<li>
					pregătirea angajaților noștri care lucrează cu datele dvs. personale
				</li>
			</ul>
			<h2 ref={section7}>Cine are acces la datele dvs. personale?</h2>
			<p>
				Datele cu caracter personal vor fi împărtășite doar cu furnizorul
				serviciilor de cloud necesare găzduirii aplicației (Exim Host S.R.L.),
				și cu băncile ce asigură procesarea plăților, accesul terțelor părți la
				date fiind limitat, acordat doar pentru situații specifice și
				reglementat de acordul ce guvernează relația contractuală dintre noi și
				acesta.
			</p>
			<p>
				În afara acestui acces incidental al unei terțe părți, suntem singura
				parte care va avea acces la datele dvs. personale, pentru furnizarea
				serviciilor și asigurarea funcționalităților aplicației. Un număr
				limitat de angajați ai noștri vor avea acces la datele dvs. personale,
				pentru a asigura suport tehnic, precum și funcționarea corectă a
				aplicației, iar aceștia sunt obligați să respecte confidențialitatea
				datelor.
			</p>
			<h2 ref={section8}>
				Ce drepturi aveți în legătură cu prelucrarea datelor dvs.?
			</h2>
			<p>
				În circumstanțele, condițiilele și sub rezerva excepțiilor prevăzute în
				legea aplicabilă, vă puteți exercita drepturile dvs. în legătură cu
				prelucrarea datelor dvs. personale:
			</p>
			<ul>
				<li>
					Aveți dreptul să știți dacă deținem date personale despre dvs. și,
					dacă o facem, să obțineți informații și o copie a acestor date
					personale
				</li>
				<li>
					Aveți dreptul de a solicita ca datele dumneavoastră cu caracter
					personal să fie corectate dacă sunt inexacte sau incomplete
				</li>
				<li>
					Aveți dreptul de a obiecta față de prelucrarea datelor dvs. cu
					caracter personal care sunt necesare pentru îndeplinirea unei sarcini
					de interes public, rezultă din exercitarea unei autorități publice cu
					care am fost investiți sau este necesară pentru interesele noastre
					legitime sau ale unei terțe părți. Aveți dreptul să solicitați
					ștergerea datelor dvs. cu caracter personal, inclusiv în cazul în care
					aceste date cu caracter personal nu ar mai fi necesare pentru
					atingerea scopurilor
				</li>
				<li>
					Aveți dreptul să ne solicitați prelucrarea datelor dvs. cu caracter
					personal numai în circumstanțe limitate, inclusiv cu acordul
					dumneavoastră
				</li>
				<li>
					Aveți dreptul să primiți o copie (într-un format structurat, utilizat
					în mod obișnuit și care poate fi citit automat) a datelor cu caracter
					personal pe care le-ați furnizat către IT HIT S.R.L. sau să ne
					solicitați să transmitem aceste date cu caracter personal altui
					operator de date
				</li>
				<li>
					În măsura în care ați acordat consimțământul pentru o activitate de
					prelucrare, în orice moment îl puteți retrage (acțiune care nu va
					afecta legalitatea prelucrării de dinaintea unei asemenea retrageri)
				</li>
				<li>
					Nu veți fi supus unei decizii unice bazată pe prelucrarea datelor dvs.
					(inclusiv crearea de profile) care să aibă efecte juridice asupra dvs.
					sau care să vă afecteze într-un mod similar și semnificativ
				</li>
				<li>
					Aveți în orice moment dreptul să depuneți o plângere la{" "}
					<a href="https://www.dataprotection.ro/">
						Autoritatea Națională de Supraveghere a Prelucrării Datelor cu
						Caracter Personal
					</a>
					, în cazul în care considerați că datele dvs. cu caracter personal au
					fost prelucrate încălcând legislația aplicabilă privind protecția
					datelor. Puteți depune o plângere la autoritatea de supraveghere
					competentă în țara în care aveți reședința obișnuită sau în care locul
					dvs. de muncă se află, sau în care a avut loc presupusa încălcare a
					prelucrării datelor dvs. cu caracter personal.
				</li>
			</ul>
			<p>
				Pentru a exercita oricare dintre drepturile de mai sus, vă rugăm să ne
				contactați utilizând detaliile de contact de mai jos. Puteți folosi
				aceleași detalii de contact pentru a ne pune întrebări în legătură cu
				drepturile dvs. sau alte informații cuprinse în această notă.{" "}
			</p>
			<h5>IT HIT S.R.L.</h5>
			<h5>Bulevardul Tudor Vladimirescu 22, sector 5, București</h5>
			<h5>gdpr@ithit.ro / (0371) 395 028.</h5>
		</Legal>
	);
}
