import React from "react";
import Legal from "./Legal";

export default function Terms() {
	return (
		<Legal
			title="TERMS AND CONDITIONS OF USING WIKISMARTS"
			className="termeni-si-conditii"
		>
			<p>
				Welcome to WikiSmarts! We (IT HIT S.R.L., a company established in Romania, hereinafter referred to as "we," "our," or "us") operate a website accessible at{" "}
				<a href="https://www.wikismarts.com">www.wikismarts.com</a>. In this document, we explain the terms of use for our website, WikiSmarts. The WikiSmarts website may include access to virtual environments, games, and other content, as well as downloadable software or applications for use on personal computers, tablets, mobile devices, or phones. All WikiSmarts users ("Website Visitors") are subject to the following terms and conditions of use (these "Terms of Use").
			</p>
			<p>
				Please read these Terms of Use carefully before accessing or using any part of WikiSmarts. By accessing or using WikiSmarts, you agree that you have read, understood, and agree to comply with these Terms of Use, as they may be modified by us from time to time.
			</p>
			<p>
				These terms were last modified on 28.09.2022. We may revise and update these Terms of Use at any time, and if we do so, we will notify you by posting the revised Terms of Use. Your continued use of WikiSmarts means that you accept and agree to the revised Terms of Use. If you do not agree with the Terms of Use or are dissatisfied with the terms and conditions of using WikiSmarts, your sole and exclusive remedy is to discontinue the use of the application.
			</p>
			<h2>Age and Parental Guide</h2>
			<p>
				If you are under 13 years old, access these{" "}
				<a href="/termeni_si_conditii_copii">
					Terms of use for children.
				</a>
				.
			</p>
			<p>
				We take the online safety of children as seriously as we do their fun and learning process. That's why we created WikiSmarts specifically for them. We sometimes receive questions about our service, and we have compiled the most frequently asked ones below. If you have any other questions, please contact us using the contact details below.
			</p>
			<ul>
				<li>
					Do I have to pay for any of the games? The first game/level in each module of WikiSmarts can be played by every user for free, starting from the activation date of their account and for an unlimited period. To progress to the next levels, a subscription purchase is required. If you choose to purchase one of our subscriptions, please refer to{" "}
					<a href="/prelucrarea_platilor">
						the Payment Processing Policy
					</a>
					.
				</li>
				<li>
					Can my child chat with other people? Not at all. WikiSmarts does not have a user connection or chat system.
				</li>
				<li>
					Do you process personal data? How do you protect children's online privacy? Yes, for account activation. However, we do not use tracking cookies or any other types of cookies, except for those necessary to keep this site functioning properly and to allow players to save their game progress and continue playing when they return. WikiSmarts complies with data protection and privacy laws. We also strictly process only the personal data necessary for providing the services and do not use intrusive processing measures (profiling, automated measures, etc.). Additionally, access to the data is strictly limited. Please refer to{" "}
					<a href="/prelucrarea_datelor">
						Privacy Policy
					</a>{" "}
					and <a href="/cookies">Politica privind prelucrarea cookie-urilor</a>{" "}
					for more information on how we process personal data, please refer to our privacy policy. If you are a parent or legal guardian and have questions about your child's data privacy in WikiSmarts, you can email us at <a>gdpr@ithit.ro</a>.
				</li>
			</ul>
			<h2>Accessing and managing your WikiSmarts account information</h2>
			<p>
				Visitors who have registered on our website or through one of our applications are responsible for keeping their registration data secure. You are directly responsible for the confidentiality of your username and password, as well as for all actions performed in your account. We recommend that you periodically change your password and avoid using the same password for your email or other online services. We cannot verify the identity of individuals using our services, and we are not responsible if your username and password are used by another person. Do not share your access password with anyone and do not enter it on websites or applications other than WikiSmarts.
			</p>
			<p>
				
If you become aware that another person is using your account, you should immediately notify us through the methods indicated on wikismarts.com/contact or by email at <a>gdpr@ithit.ro</a>.
			</p>
			<p>
				If you want to close your WikiSmarts account, you can delete it directly from the application by using the Deactivate Account option.
			</p>
			<h2>Using the WikiSmarts website and applications</h2>
			<p>
				You agree to use WikiSmarts solely for lawful purposes. Specifically, you agree not to engage in any of the following activities:
			</p>
			<p>
				(1) Upload or transmit on WikiSmarts any defamatory, indecent, obscene, harassing, violent, or otherwise unacceptable material, or any material that is or may be protected by copyright without the permission of the copyright owner;
(2) Use WikiSmarts to violate the legal rights (including the rights of publicity and privacy) of others or to violate the laws of any jurisdiction;
(3) Misrepresent any person or organization;
(4) Upload or transmit through WikiSmarts any advertisements or business solicitations;
(5) Restrict or inhibit the use of WikiSmarts by others;
(6) Upload or transmit files that contain viruses or corrupted data;
(7) Collect information about others (including email addresses) without their consent;
(8) Solicit, provide, or exchange any personal information, including but not limited to usernames or passwords;
(9) Engage in any other conduct that restricts or inhibits the use of WikiSmarts by any person or that, as determined by us, may harm WikiSmarts or the site's visitors in any way. Without limiting any of the above, you also agree to comply with any applicable code of conduct and policies of WikiSmarts or any services available within WikiSmarts.
			</p>
			<p>
				You may not reproduce, sublicense, distribute, sell, or exploit for any commercial purpose (i) any portion of WikiSmarts or its content, (ii) access to WikiSmarts, or (iii) the use of WikiSmarts or any services or materials available through WikiSmarts.
			</p>
			<p>
				We reserve the discretionary right, but do not assume any responsibility, to (1) remove any material posted on WikiSmarts that we deem inconsistent with the above commitments, and (2) terminate any user's access to all or part of our services. However, we do not assume any responsibility for any action or inaction regarding transmissions, communications, or content provided by third parties. We reserve the right to take any action we deem necessary to protect the safety of our visitors and the public; however, we have no liability or responsibility to any person for the performance or non-performance of the activities described in this paragraph.
			</p>
			<p>
				Some of the services we offer allow you to personalize your username and avatar photo. By posting any content through our services or sending any material to us, you assume responsibility for having the necessary rights and approvals to use that material, and you grant us the right to use the material without any claim for compensation, payment, or obligation to you or anyone else. You also agree to be legally liable if the use of that material violates the rights of any third party or the law. Additionally, you agree to indemnify us for any loss incurred as a direct result of the violation of the terms in this paragraph.
			</p>
			<p>
				Failure to comply with the above provisions may result in the suspension of your access to WikiSmarts services and may expose you to civil and/or criminal liability.
			</p>
			<h2>Providing services</h2>
			<p>
				We cannot guarantee that the services provided by us will always be available, uninterrupted, free from issues, errors, or omissions. For example, there may be periods when the services are unavailable due to problems or maintenance work aimed at ensuring the functionality or improvement of the services. Additionally, we may change, suspend, or discontinue certain services without prior notice. We cannot be held responsible for any loss you may suffer as a result of the temporary or permanent interruption of services provided by us.
			</p>
			<p>
				Additionally, we cannot be held responsible for the following losses or issues that may arise as a result of using our services: computer or device failure used to access our services; data loss; loss suffered as a consequence of actions by another user of our services; loss that may be caused by us due to non-compliance with the Terms of Use; any direct or indirect loss that neither we nor you can anticipate when you start using our services; any loss incurred during the interruption or suspension of our services or if we are unable to fulfill our terms due to unforeseen circumstances or force majeure events.
			</p>
			<h2>Uploads to your devices</h2>
			<p>
				Please note that if you use downloadable applications from WikiSmarts, system updates or firmware updates on your device may render the use of the applications incompatible. We do not guarantee that the WikiSmarts website or any WikiSmarts application will be compatible with the updates or previous versions of your devices.
			</p>
			<p>
				If you download WikiSmarts from the App Store or Google Play, you will be asked to accept the terms of the respective store. We recommend that you carefully read those terms.
			</p>
			<p>
				In the case of downloadable WikiSmarts applications, you may be offered the option to receive push notifications. You can choose to accept or decline these notifications, and you can later disable them by accessing the Settings menu on your device.
			</p>
			<h2>Data taxation</h2>
			<p>
				To the extent that your use of the WikiSmarts website or any WikiSmarts application requires or involves the use of wireless, cellular, or internet data, you are solely responsible for securing the necessary data access service. For example, regarding your mobile devices, your data plan provider may charge you fees for data access related to your use of WikiSmarts services. You are solely responsible for all such fees payable to third parties.
			</p>
			<h2>Copyright</h2>
			<p>
				The entire content of this website and WikiSmarts applications (including all information, text, displays, images, sound, and any software provided through or in connection with them, as well as their design, selection, and arrangement) is our property and is protected by Romanian and international laws regarding copyright, trademarks, trade secrets, and other proprietary rights. You are authorized to use the content of our services solely for your personal use related to your actual or potential role as a WikiSmarts user. You may not copy, modify, create derivative works, publicly display or perform, republish, store, transmit, or distribute any material from the WikiSmarts website and applications without our prior written consent, except in the following situations: (a) to temporarily store copies in RAM, (b) to store files that are automatically cached by your web browser for the purpose of improving display, and (c) to print a reasonable number of pages from the website or WikiSmarts applications, provided that in each case you do not modify or remove any copyright notices or other proprietary information included in such materials. Neither the title nor the intellectual property rights to any information or materials are transferred to you, but remain our property.
			</p>
			<p>
				We may, under appropriate circumstances and without any prior notice, terminate your access to the WikiSmarts services if we believe that you are a repeated infringer of the intellectual property rights described above.
			</p>
			<h2>Compensation</h2>
			<p>
				You agree to indemnify us, and hold us harmless, against any and all liabilities, expenses, damages, and costs, including reasonable attorney's fees, arising from or in connection with any breach by you of these Terms of Use or your use of the WikiSmarts website or applications, or any products, services, or information obtained from them.
			</p>
			<h2>Applicable law</h2>
			<p>
				These Terms of Use shall be governed in all respects and interpreted in accordance with the laws of Romania. If compliance with or our rights under these Terms of Use are in any way affected by consumer protection laws of your country of residence, you should not use the WikiSmarts website or applications.
			</p>
			<p>
				Any dispute arising out of or in connection with these Terms of Use or your use of the WikiSmarts website or applications shall be submitted to the competent court in Bucharest, Romania. If you are a resident of an EU member state or a country where this clause is prohibited by local law, this clause does not apply to you and does not deprive you of the protection provided by mandatory provisions of consumer protection laws in your country.
			</p>
			<h2>Miscellaneous</h2>
			<p>
				These Terms of Use, as may be modified from time to time, constitute the entire and exclusive agreement between you and us regarding the WikiSmarts website and applications, and any other terms that may have been communicated to you orally or in any other way have no force or effect.
			</p>
			<p>
				You agree and acknowledge that your violation of any restrictions in these Terms of Use regarding the use of the WikiSmarts website or applications, the content of the WikiSmarts website or applications, or the software or services available on or through the WikiSmarts website or applications, or the use or disclosure of confidential information in a manner inconsistent with the provisions of these Terms of Use, may cause us irreparable harm for which legal remedies may be inadequate.
			</p>
			<p>
				If any part of these Terms of Use is deemed unenforceable, the unenforceable part shall be interpreted to reflect, as closely as possible, the original intentions of the parties. The remaining provisions of these Terms of Use shall remain in full force and effect.
			</p>
			<p>
				You can contact us anytime at the email address <a>gdpr@ithit.ro</a> or by phone (0371) 395 028.
			</p>
		</Legal>
	);
}
