import React from "react";
import Legal from "./Legal";

export default function TermsKids() {
	return (
		<Legal
			title="TERMS AND CONDITIONS OF USE (FOR CHILDREN) WIKISMARTS"
			className="termeni-si-conditii"
		>
			<p>
				Welcome to WikiSmarts! We are a company from Romania that operates this website and its corresponding mobile applications. We have created some rules (these "Rules") for all children under 13 years old who use WikiSmarts. If you are under 13 years old and use WikiSmarts, these Rules apply to you. If you do not agree with the Rules, please do not use our website and WikiSmarts mobile applications. If you would like more information about our Rules, please contact us at{" "}
				<a>gdpr@ithit.ro</a> . We are happy to explain everything to you. We want our website and applications to be a fun place for everyone, and if you follow these Rules, you will help us achieve that!
			</p>
			<h2>RULES:</h2>
			<p>
				Do not use the WikiSmarts website and applications for anything illegal or harmful to other users. What is considered illegal or harmful? Well, anything that goes against the law in any country or goes against common decency. For example:
			</p>
			<ul>
				<li>
					Uploading materials or comments that are defamatory, indecent, obscene, harassing, or violent.
				</li>
				<li>
					Sharing information about someone without their consent or any other appropriate reason.
				</li>
				<li>Pretending to be someone else.</li>
				<li>
					Using our website and applications to infringe upon the legal rights of others, such as violating the copyrights of others.
				</li>
				<li>
					The use of our website for hacking, phishing, spamming, spreading viruses, or other cyber attacks, or for collecting information about other users of our website and applications.
				</li>
				<li>
					Harassment, stalking, or otherwise behaving badly towards other users.
				</li>
			</ul>
			<p>
				The things we put on WikiSmarts are essentially 'our things'. You are not allowed to copy, reuse, or redistribute WikiSmarts content without our permission. Of course, in the event that content is automatically copied by your device for technical reasons, so that you can use our website or mobile applications normally, this is not a violation of our rules.
			</p>
			<p>
				The use of our website and mobile applications is free for the first game or level in each module of WikiSmarts, until you decide. To progress to the next levels, purchasing a subscription is required. Please read{" "}
				<a href="/prelucrarea_platilor">
					Payment processing policy
				</a>{" "}
				Before making any payment on our website and obtaining the consent of your parties (or guardians). Also, you (or your parents) may be charged by an internet service provider for the time spent on our website or applications. Make sure to check if your parents (or other guardians) are aware of this and agree to it. We are not responsible for paying that bill.
			</p>
			<p>
				We are not responsible to you for anything that is on the content of WikiSmarts, except if we have actively put something on it that is illegal and has caused you harm. Not being responsible means that if you suffer any damages as a result of the content of WikiSmarts, we will not pay for those damages. Therefore: using WikiSmarts or any of its content is your decision.
			</p>
			<p>
				If you do something on or with our website or applications that goes against our rules, you (or your parents or other guardians) may be held responsible to us or other individuals or parties involved. Violating the rules may require you to provide a sum of money.
			</p>
			<p>
				If other individuals or parties file a complaint against us because you have violated these rules, you (or your parents or other guardians) will not hold us accountable. This means that you will have to pay for any damages in this case. It's probably best not to violate these rules.
			</p>
			<p>
				Romanian laws apply to these Rules. So, if you violate these Rules, we will use Romanian law to determine the exact consequences of your actions.
			</p>
		</Legal>
	);
}
