import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "../../styles/congratulations.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../Container";
import { useContext } from "react";
import { handleGameLevelStatus } from "../../scripts/helpers";
import { IMAGES, TEXT, lan } from "../../scripts/text";

export default function Congratulations(props) {
	const { gamesSubInfo, role } = useContext(Context);
	const [nextLevelButton, setNextLevelButton] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let status = handleGameLevelStatus(
			gamesSubInfo,
			props.gameInfo.gameId,
			parseInt(props.level) + 1,
			parseInt(props.level) + 1,
			role,
			props.gameInfo.availableLevels
		);
		setNextLevelButton(status);
		// console.log('status: ', status);
	}, []);
	return (
		<div
			className="game-box game-box-level-complete"
			style={{
				width: "100%",
				zIndex: "100",
				background: "white",
				position: "absolute",
				bottom: "0px",
				left: 0,
				overflow: "hidden",
				animation: "game-box-animation 0.3s ease",
				display: "flex",
				justifyContent: "space-evenly",
				alignItems: "center",
			}}
			onAnimationEnd={() => {
				props.setGameAreaVizible(false);
			}}
		>
			<div
				style={{
					backgroundColor: props.gameBoxTopRowBkg,
					border: `3.5px solid ${props.gameBoxDivider}`,
					borderRadius: "15px",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					width: "100%",
					height: "100%",
					animation: "container-animation 5s 0.3s both",
				}}
				className="level-complete"
			>
				<img
					src={
						require("../../assets/level_completion/background_level_completion2.svg")
							.default
					}
					className="background-image"
				/>
				<div style={{ height: "80%", width: "100%", textAlign: "center" }}>
					<img src={IMAGES._CONGRATS[lan]} />
				</div>
				<div
					style={{
						height: "20%",
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<button
						onClick={() => {
							let currentPath = location.pathname;
							let game = currentPath.split("/");
							game = game[game.length - 1];
							navigate(-1, {
								replace: true,
							});
						}}
						style={{
							maxHeight: "50%",
							padding: "0.2rem 1rem",
							borderRadius: "10px",
							cursor: "pointer",
							margin: "0 1rem",
							backgroundColor: props.gameBoxTopRowBkg,
							border: `3.5px solid ${props.gameBoxDivider}`,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-evenly",
							position: "relative",
							width: "200px",
						}}
					>
						<h2
							style={{
								margin: "0 0.2rem 0 0",
								textAlign: "center",
								color: props.color,
								fontWeight: "normal",
							}}
						>
							{TEXT._BACK[lan]}
						</h2>
						<img
							src={require("../../assets/nav_bar/modules.svg").default}
							style={{ width: "25%" }}
						/>
					</button>
					<button
						onClick={() => {
							Cookies.set("selectedLevel", parseInt(props.level));
							navigate(0);
						}}
						style={{
							maxHeight: "50%",
							padding: "0.2rem 1rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-evenly",
							borderRadius: "10px",
							cursor: "pointer",
							margin: "0 1rem",
							backgroundColor: props.gameBoxTopRowBkg,
							border: `3.5px solid ${props.gameBoxDivider}`,
							width: "200px",
						}}
					>
						<h2
							style={{
								color: props.color,
								textAlign: "center",
								margin: "0 0.2rem 0 0",
								fontWeight: "normal",
								whiteSpace: "nowrap",
							}}
						>
							{TEXT._TRY_AGAIN[lan]}
						</h2>
						<img src={props.moduleIcon} style={{ width: "25%" }} />
					</button>
					{nextLevelButton === "ready" && (
						<Link
							to={location.pathname.replace(`game${props.gameNumber}`, "start")}
							state={{
								gameNumber: props.gameNumber,
								gameTitle: props.gameTitle,
								gameDescription: props.gameDescription,
								gameId: props.gameId,
							}}
							style={{
								maxHeight: "50%",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-evenly",
								margin: "0 1rem",
								position: "relative",
							}}
						>
							<button
								onClick={() => {
									Cookies.set(
										"selectedLevel",
										parseInt(props.level) < 3 ? parseInt(props.level) + 1 : 3
									);
									// navigate(0);
								}}
								style={{
									height: "100%",
									padding: "0.2rem 1rem",
									display: "flex",
									alignItems: "center",
									justifyContent: "space-evenly",
									borderRadius: "10px",
									cursor: "pointer",
									backgroundColor: props.gameBoxTopRowBkg,
									border: `3.5px solid ${props.gameBoxDivider}`,
									width: "200px",
								}}
							>
								<h2
									style={{
										color: props.color,
										textAlign: "center",
										margin: "0 0.2rem 0 0",
										fontWeight: "normal",
										whiteSpace: "nowrap",
									}}
								>
									{TEXT._NEXT_LEVEL[lan]}
								</h2>
								<img src={props.moduleIcon} style={{ width: "25%" }} />
							</button>
						</Link>
					)}
					{nextLevelButton === "needs-subscription" && (
						<button
							onClick={() => {
								navigate("/subscription");
							}}
							style={{
								maxHeight: "50%",
								padding: "0.2rem 1rem",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-evenly",
								borderRadius: "10px",
								margin: "0 1rem",
								cursor: "pointer",
								backgroundColor: "#F3B653",
								border: `3.5px solid #EF720B`,
								width: "250px",
							}}
						>
							<h2
								style={{
									color: props.color,
									textAlign: "center",
									margin: "0 0.2rem 0 0",
									fontWeight: "normal",
									whiteSpace: "nowrap",
								}}
							>
								NIVELUL URMĂTOR
							</h2>
							<img
								src={require("../../assets/ticket_subs.svg").default}
								style={{ width: "25%" }}
							/>
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
