import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import "../../../styles/tutorial.css";
import {
	arrayEquals,
	showCorrectAnswerBanner,
	showBadAnswerBanner,
	createArrayOfIncrementingNumbers,
	getApiConfig,
	setGameInstructions,
	getAllScenesWithCreate_post,
} from "../../../scripts/helpers";

import "../../../styles/modules/matematica/matematicaGame1.css";
import { CheckButton } from "../CheckButton";
import { getLevelInfo, tryHideOverlay } from "../../../scripts/dbTest";
import { CustomBubble } from "../CustomBubble";

export function MatematicaGame1Area__Tutorial(props) {
	//!! De luat din dbtest
	const gameId = props.gameId;

	const [allScenes, setAllScenes] = useState([]);
	const [activeOverlay, setActiveOverlay] = useState(false);
	let scenesInfo = props.scenesInfo;
	const [cards, setCards] = useState([]);

	const [step, setStep] = useState(0);
	const [element, setElement] = useState({});
	const tutorialSteps = [
		{
			activeObjectClass: ".gamebox-total-score",
			text: "Aici este scorul tau. Acumuleaza cat mai multe puncte intr-un joc pentru a trece la un nou nivel.",
			arrow: "active-arrow-bottom",
		},
		{
			activeObjectClass: ".instructions-button",
			text: "Apasa pe semnul intrebarii pentru a revedea cerinta jocului sau pentru a inchide fereastra cerintei.",
			arrow: "active-arrow-bottom",
		},
		{
			activeObjectClass: "#check-answer-button",
			text: "Dupa ce consideri ca ai rezolvat corect plansa, apasa pe buton pentru a te verifica.",
			arrow: "active-arrow-top",
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setActiveOverlay(true);
		}, 100);
		document.querySelector(".instructions-button").style.animation = "none";
	}, []);

	useEffect(() => {
		let intevalId = setInterval(() => {
			console.log("test");
			if (document.querySelector("#total-score")) {
				if (document.querySelector(".hide-active")) {
					document
						.querySelector(".hide-active")
						.classList.remove("hide-active");
				}
				let newActiveObject = document.querySelector(
					tutorialSteps[step].activeObjectClass
				);
				if (newActiveObject) {
					setInterval(() => {}, 200);
					let pos = newActiveObject.getBoundingClientRect();
					console.log(pos);
					setElement({
						top: pos.top,
						left: pos.left,
						width: pos.width,
						height: pos.height,
						arrow: tutorialSteps[step].arrow,
					});
					document.querySelector(".active-element").innerHTML =
						newActiveObject.outerHTML;

					newActiveObject.classList.add("hide-active");
				}
				clearInterval(intevalId);
			}
		}, 200);
	}, [step]);

	function setLevelProps() {
		setGameInstructions(`${
			scenesInfo[props.nrOfScenes - 1].order
				? "Aranjează imaginile de la mic la mare."
				: "Aranjează imaginile de la mare la mic."
		}
			`);
	}

	const updateScene = (data) => {
		setLevelProps();
		let imagesUrl = `${getApiConfig().baseUrl}/games_images/${
			data.selectedCategory
		}`;
		let cardsTemp = [];
		cardsTemp.push({
			id: `image-${1}`,
			image: `${imagesUrl}/${data.images[0]}`,
			index: 1,
		});
		cardsTemp.push({
			id: `image-${0}`,
			image: `${imagesUrl}/${data.images[0]}`,
			index: 0,
		});
		cardsTemp.push({
			id: `image-${2}`,
			image: `${imagesUrl}/${data.images[0]}`,
			index: 2,
		});
		setCards(cardsTemp);
	};

	function checkAnswer() {
		setTimeout(() => {
			const indexes = Array.from(cards).map((card) => {
				return card.index;
			});
			let correctIndexes = createArrayOfIncrementingNumbers(
				0,
				scenesInfo[props.nrOfScenes - 1].columns - 1,
				!scenesInfo[props.nrOfScenes - 1].order
			);
			console.log(indexes, correctIndexes);
			// console.log(correctIndexes);
			if (arrayEquals(indexes, correctIndexes)) {
				showCorrectAnswerBanner(props.setNrOfScenes, props.nrOfScenes, gameId);
			} else {
				showBadAnswerBanner(props.setNrOfScenes, props.nrOfScenes, gameId);
			}
		}, 300);
	}

	function onDragEnd(result) {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const items = Array.from(cards);
		const [reorderedItem] = items.splice(source.index, 1);
		items.splice(destination.index, 0, reorderedItem);

		setCards(items);
	}

	function getAllScenes() {
		let { body } = getLevelInfo(gameId, props.level);
		getAllScenesWithCreate_post(
			gameId,
			setAllScenes,
			body,
			props.maxNrOfScenes,
			getNextScene
		);
	}

	function getNextScene(data) {
		tryHideOverlay();
		updateScene(data);
	}

	useEffect(() => {
		if (props.nrOfScenes <= props.maxNrOfScenes && scenesInfo.length) {
			if (props.nrOfScenes == 1) {
				getAllScenes();
			} else {
				getNextScene(allScenes[props.nrOfScenes - 1]);
			}
		}
	}, [props.nrOfScenes, scenesInfo]);

	return (
		<div className="game-area">
			<div style={{ height: "25%", display: "flex", justifyContent: "center" }}>
				<h1 className="top-instruction" style={{ margin: "auto 0" }}>
					{`${
						scenesInfo[props.nrOfScenes - 1] &&
						scenesInfo[props.nrOfScenes - 1].order
							? "Aranjează imaginile de la mic la mare."
							: "Aranjează imaginile de la mare la mic."
					}
			`}
				</h1>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="cards" direction="horizontal">
					{(provided) => (
						<div
							className="image-card-container"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{cards.map(({ id, image, index }, i) => {
								return (
									<Draggable key={id} draggableId={id} index={i}>
										{(provided) => (
											<div
												className="image-card"
												id={`image-${i + 1}`}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<img
													src={image}
													alt="img"
													style={{
														width: `${100 - 19 * index}%`,
														maxHeight: `${100 - 19 * index}%`,
													}}
												/>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<CheckButton
				onClick={checkAnswer}
				style={{ height: "20%" }}
				imageStyle={{ height: "100%" }}
			/>
			<div
				className={`tutorial-overlay " ${
					activeOverlay ? "tutorial-overlay-active" : ""
				}`}
			>
				<div className="tutorial-content">
					<img
						className="wiki-tutorial"
						src={require("../../../assets/mascot/wiki_simplu_png.png").default}
					/>
					<div className="tutorial-text">
						<p style={{ marginRight: 10 }}>{tutorialSteps[step].text}</p>
						{/* <div className="back-button mobile-form-back-button">
							<NextButton />
						</div> */}

						<div
							className="user-tab  pretty-button"
							onClick={() => {
								if (step < tutorialSteps.length - 1) setStep(step + 1);
								else {
									setActiveOverlay(false);
									setElement({});
									if (document.querySelector(".hide-active")) {
										document
											.querySelector(".hide-active")
											.classList.remove("hide-active");
									}
								}
							}}
							style={{
								background: "#13AEF2",
							}}
						>
							Inainte
							{/* <img src={require("../../../assets/arrowBack.svg").default} /> */}
						</div>
					</div>
					{activeOverlay ? (
						<>
							<div
								className="active-background"
								style={{
									top: element.top,
									left: element.left,
									height: element.height,
									width: element.width,
								}}
							>
								{/* <div className={"arrow " + element.arrow}>
									<img src={require("../../../assets/sageata.svg").default} />
								</div> */}
							</div>

							<div
								className="active-element"
								style={{
									top: element.top,
									left: element.left,
								}}
							></div>
						</>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
}
