import React, { useEffect } from "react";
import "../../styles/check-button.css";

export function CheckButton(props) {
	let animateButton = function (e) {
		// console.log("animateButton");
		e.preventDefault();
		//reset animation
		e.currentTarget.classList.add("animate");
	};

	function setAnimation() {
		let checkAnswerButton = document.querySelector(
			".check-answer-button.bubbly-button"
		);

		checkAnswerButton.addEventListener("click", animateButton, false);
	}

	function removeAnimatedClass(event) {
		event.currentTarget.classList.remove("animate");
	}

	return (
		<div
			id="check-answer-button"
			className="check-answer-button bubbly-button"
			onClick={props.onClick}
			style={props.style}
			onLoad={setAnimation}
			onAnimationEnd={removeAnimatedClass}
		>
			<img
				src={require("../../assets/Check.svg").default}
				style={props.imageStyle}
			/>
		</div>
	);
}
