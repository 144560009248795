import React, { useState } from "react";
import { DezvoltareGame5Area } from "../../../components/modules/dezvoltare/DezvoltareGame5Area";
import { DezvoltareTemplate } from "../../../components/modules/dezvoltare/DezvoltareTemplate";
import Cookies from "js-cookie";

export default function DezvoltareGame5(props) {
	const maxNrOfScenes = 10;
	//!! De luat din dbtest
	const gameId = "639ad860ac408822e8da4f52";
	return (
		<DezvoltareTemplate gameNumber={5}>
			<DezvoltareGame5Area
				gameId={gameId}
				maxNrOfScenes={maxNrOfScenes}
				possibleAnswers={3}
				level={Cookies.get("selectedLevel")}
			/>
		</DezvoltareTemplate>
	);
}
