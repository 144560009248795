import React, { useEffect } from "react";
import "../../styles/congratulations.css";
import { TEXT, lan } from "../../scripts/text";

export default function BackToTheSameLevel(props) {
	function changePageTo(page) {
		window.location.href = page;
	}
	return (
		<div
			className="game-box game-box-level-complete"
			style={{
				width: "100%",
				zIndex: "100",
				background: "white",
				position: "absolute",
				bottom: "0px",
				left: 0,
				overflow: "hidden",
				animation: "game-box-animation 0.3s ease",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			onAnimationEnd={() => {
				props.setGameAreaVizible(false);
			}}
		>
			<div
				style={{
					backgroundColor: props.gameBoxTopRowBkg,
					border: `3.5px solid ${props.gameBoxDivider}`,
					borderRadius: "15px",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					width: "100%",
					height: "100%",
					animation: " container-animation 5s 0.3s both",
				}}
				className="level-complete"
			>
				<img
					src={
						require("../../assets/level_completion/background_level_completion2.svg")
							.default
					}
					className="background-image"
				/>
				<div style={{ height: "80%", width: "100%", textAlign: "center" }}>
					<img src={require(`../../assets/mascot/wiki_2.png`).default} />
				</div>
				<div
					style={{
						height: "20%",
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<button
						style={{
							// minWidth: "15%",
							maxHeight: "50%",
							padding: "0.2rem 1rem",
							borderRadius: "10px",
							cursor: "pointer",
							marginRight: "2rem",
							backgroundColor: props.gameBoxTopRowBkg,
							border: `3.5px solid ${props.gameBoxDivider}`,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => changePageTo("explorer")}
					>
						<h2
							style={{
								margin: "0 0.2rem 0 0",
								textAlign: "center",
								color: props.color,
								fontWeight: "normal",
							}}
						>
							{TEXT._BACK[lan]}
						</h2>
						<img
							src={require("../../assets/nav_bar/modules.svg").default}
							style={{ marginLeft: "auto", width: "25%" }}
						/>
					</button>
					<button
						style={{
							// minWidth: "15%",
							maxHeight: "50%",
							padding: "0.2rem 1rem",
							borderRadius: "10px",
							cursor: "pointer",
							marginLeft: "2rem",
							backgroundColor: props.gameBoxTopRowBkg,
							border: `3.5px solid ${props.gameBoxDivider}`,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => changePageTo("")}
					>
						<h2
							style={{
								color: props.color,
								textAlign: "center",
								margin: "0 0.2rem 0 0",
								fontWeight: "normal",
								whiteSpace: "nowrap",
							}}
						>
							{TEXT._TRY_AGAIN[lan]}
						</h2>
						<img
							src={props.moduleIcon}
							style={{ marginLeft: "auto", width: "25%" }}
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
