import React from "react";
import { useEffect } from "react";
import { hideOverlay } from "../../../scripts/helpers";
import { MatematicaGame1Area__Tutorial } from "./MatematicaGame1Area__Tutorial";
import Cookies from "js-cookie";
export default function TutorialComponent(props) {
	useEffect(() => {
		Cookies.set("selectedLevel", 1);
		hideOverlay();
	}, []);
	return (
		<MatematicaGame1Area__Tutorial
			maxNrOfScenes={props.maxNrOfScenes}
			gameId={props.gameId}
			nrOfScenes={props.nrOfScenes}
			setNrOfScenes={props.setNrOfScenes}
			scenesInfo={props.scenesInfo}
		/>
	);
}
