import React, { useRef } from "react";
import Legal from "./Legal";

export default function Cookies() {
	const section1 = useRef();
	const section2 = useRef();
	const section3 = useRef();
	const section4 = useRef();
	return (
		<Legal
			title="POLITICA PRIVIND PRELUCAREA COOKIE-URILOR  ÎN CADRUL APLICAȚIEI WIKISMARTS"
			className="cookies"
		>
			<p>
				<em>
					Această politică privind prelucrarea cookie-urilor este în vigoare
					începând cu 28.09.2022 și va fi actualizată în mod regulat pentru a
					reflecta orice modificări ale modului în care gestionăm cookie-urile
					sau orice modificări ale legilor aplicabile.
				</em>
			</p>
			<h5
				onClick={() => {
					section1.current.scrollIntoView();
				}}
			>
				Ce sunt cookie-urile?
			</h5>

			<h5
				onClick={() => {
					section2.current.scrollIntoView();
				}}
			>
				Cum utilizăm cookie-urile?
			</h5>

			<h5
				onClick={() => {
					section3.current.scrollIntoView();
				}}
			>
				Cookie-uri de la terțe părți
			</h5>

			<h5
				onClick={() => {
					section4.current.scrollIntoView();
				}}
			>
				Cum puteți evita cookie-urile?
			</h5>
			<h2 ref={section1}>Ce sunt cookie-urile?</h2>
			<p>
				Cookie-urile sunt fișiere text care conțin cantități mici de informații
				care sunt descărcate pe computerul sau dispozitivul dvs. mobil atunci
				când vizitați un site și permit unui site să vă recunoască dispozitivul.
				Cookie-urile sunt stocate pe dispozitivul dvs. când navigați și
				utilizați site-uri sau alte servicii online, de exemplu pentru a
				facilita anumite funcții, cum ar fi navigarea pe site.
			</p>
			<p>
				Cookie-urile gestionate de noi sunt numite numai „cookie-uri primare”,
				în timp ce cookie-urile de la terți sunt numite „cookie-uri terțe”, după
				cum se explică mai jos.
			</p>
			<h2 ref={section2}>Cum utilizăm cookie-urile?</h2>
			<p>
				Cookie-uri sunt folosite pentru a vă îmbunătăți experiența, agrega
				statistici despre numărul de vizitatori pe site și pentru a obține date
				despre modul în care este utilizat site-ul. Aceste date ne permit să
				dezvoltăm site-ul și să îl optimizăm. Cookie-urile sunt uneori utilizate
				pentru a colecta date considerate a constitui date cu caracter personal
				conform legislației aplicabile – acestea ar putea fi adresele IP și
				datele legate de adresa IP, dar, ca regulă, nu și date cu caracter
				personal care vă pot fi atribuite în mod direct în calitate de persoană
				fizică (însemnând că nu vom putea crea o legătură între
				numele/identitatea dvs. și adresa IP). Totuși, în anumite cazuri, este
				posibil să putem determina identitatea persoanei fizice căreia adresa IP
				îi este atribuită. Scopul este de a crea un conținut personalizat și
				relevant pentru dvs. ca vizitator.
			</p>
			<p>
				Ștergerea cookie-urilor se poate face automat atunci când vă închideți
				navigatorul web (cookie-uri de sesiune). De asemenea, cookie-urile pot
				fi stocate pe computerul utilizatorului pentru a facilita viitoarele
				vizite pe site (cookie-uri permanente) - acestea vor fi, de asemenea,
				șterse automat după o anumită perioadă.
			</p>
			<p>
				Acest tabel prezintă tipurile de cookie-uri plasate pe site-ul
				WikiSmarts și detalii în legătură cu acestea:
			</p>
			<table className="icons-grid legal-table">
				<tbody>
					<tr>
						<td>Denumire</td>
						<td>Tipul de cookie și funcție </td>
						<td>Date stocate </td>
						<td>Perioada de stocare</td>
					</tr>
					<tr>
						<td>wikismarts_session_id</td>
						<td>
							Cookie strict necesar pentru funcționarea website-ului;sesiune
						</td>
						<td>
							Nu conține date cu caracter personal și nu este folosit pentru a
							colecta informații. Este folosită o valoare pentru a păstra
							sesiunea userului logat
						</td>
						<td>Pana la inchiderea sesiunii</td>
					</tr>
					<tr>
						<td>selectedLevel</td>
						<td>
							Cookie strict necesar pentru funcționarea website-ului;sesiune
						</td>
						<td>
							Nu conține date cu caracter personal și nu este folosit pentru a
							colecta informații. Este folosită o valoare pentru a păstra
							nivelul unui joc și pentru a permite avansarea corespunzătoare
						</td>
						<td>Pana la inchiderea sesiunii</td>
					</tr>
				</tbody>
			</table>
			<h2 ref={section3}>Cookie-uri de la terțe părți</h2>
			<p>
				Anumite cookie-uri de la terțe părți pot fi de asemenea utilizate pe
				site-ul nostru. Aceste cookie-uri de la terțe părți sunt folosite în
				primul rând pentru a îmbunătăți experiența utilizatorului, agrega
				statistici despre numărul de vizitatori ai site-ului și pentru a obține
				date despre modul în care este utilizat site-ul, deși în anumite cazuri
				poate fi utilizat de o terță parte pentru propriile sale scopuri.
			</p>
			<p>
				Aceste companii folosesc cod de programare pentru a colecta informații
				despre interacțiunea dumneavoastră cu site-ul nostru, cum ar fi paginile
				pe care le vizitați, link-urile pe care faceți clic și cât timp sunteți
				pe site-ul nostru. Acest cod este activ numai când vă aflați pe un
				site-ul nostru.
			</p>
			<h2 ref={section4}>Cum puteți evita cookie-urile?</h2>
			<p>
				Vă puteți ajusta setările cookie-urilor care nu sunt strict necesare
				prin intermediul managerului de consimțământ pentru cookie-uri.
			</p>
			<p>
				Dacă nu acceptați utilizarea cookie-urilor care nu sunt strict necesare,
				navigatorul dvs. poate fi setat astfel încât să respingă în mod automat
				stocarea fișierelor cookie sau să vă informeze de fiecare dată când un
				site solicită să stocheze un cookie. Cookie-urile stocate anterior pot
				fi șterse prin navigator.
			</p>
			<p>
				Vă rugăm să rețineți că ștergerea și blocarea cookie-urilor poate avea
				un impact asupra experienței dvs. de utilizator.
			</p>
			<p>
				Dacă aveți întrebări acum sau în timpul vizitei dvs. pe site-ul nostru,
				vă rugăm să trimiteți cererea la adresa noastră din Bulevardul Tudor
				Vladimirescu 22, sector 5, București, trimițându-ne un mesaj la{" "}
				<a>gdpr@ithit.ro</a> sau apelând (0371) 395 028.
			</p>
		</Legal>
	);
}
