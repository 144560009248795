import React from "react";
import { useEffect, useState } from "react";
import { logout } from "../../scripts/helpers";
import "../../styles/burgermenu.css";

export default function BurgerMenu(props) {
	function toggleNavbarPopup() {
		let navbarPopup = document.querySelector(".navbar-popup");
		let burgerMenuDivs = document.querySelectorAll(".vegan-burger-menu div");
		let burgermenu = document.querySelector(".vegan-burger-menu");
		if ([...navbarPopup.classList].includes("navbar-popup-active")) {
			navbarPopup.classList.remove("navbar-popup-active");
			burgerMenuDivs[0].style.transform = "";
			burgerMenuDivs[2].style.transform = "";
			burgerMenuDivs[0].style.position = "relative";
			burgerMenuDivs[2].style.position = "relative";
			burgerMenuDivs[1].style.opacity = 1;
			burgermenu.style.position = "";
			document.querySelector(".vegan-burger-menu").style.justifyContent =
				"space-between";
		} else {
			navbarPopup.classList.add("navbar-popup-active");
			burgerMenuDivs[0].style.transform = "rotate(45deg)";
			burgerMenuDivs[2].style.transform = "rotate(-45deg)";
			burgerMenuDivs[0].style.position = "absolute";
			burgerMenuDivs[2].style.position = "absolute";
			burgerMenuDivs[1].style.opacity = 0;
			burgermenu.style.position = "fixed";
			document.querySelector(".vegan-burger-menu").style.justifyContent =
				"center";
		}
	}
	return (
		<>
			<div className="vegan-burger-menu" onClick={toggleNavbarPopup}>
				<div
					style={{ backgroundColor: props.color ? props.color : "white" }}
				></div>
				<div
					style={{ backgroundColor: props.color ? props.color : "white" }}
				></div>
				<div
					style={{ backgroundColor: props.color ? props.color : "white" }}
				></div>
			</div>
			<div className="navbar-popup">
				<div className="navbar-popup-wrap">
					<img
						src={
							require("../../assets/logo_WS/logo_wiki_big_white.svg").default
						}
					/>
					<div className="navbar-popup-item">
						<a href="/modules">
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/modules.svg").default}
								alt=""
							/>
							<h2>module</h2>
						</a>
					</div>
					<div className="navbar-popup-item">
						<a href="/progress">
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/score.svg").default}
								alt=""
							/>
							<h2>rezultate</h2>
						</a>
					</div>
					<div className="navbar-popup-item">
						<a href="/profile">
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/profile.svg").default}
								alt=""
							/>
							<h2>{props.name}</h2>
						</a>
					</div>
					<div className="navbar-popup-item">
						<a href="#" onClick={logout}>
							<img
								className="navbar__item-icon"
								src={require("../../assets/nav_bar/logout.svg").default}
								alt=""
							/>
							<h2>Deconectare</h2>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
